import { Reducer } from "redux";
import { AuthActionTypes } from "./constants";
import produce from "immer";
import { IAuth } from "../interfaces";

const initialState: IAuth = {
  isAuthentificate: false,
  user: null,
  error: null,
  quotes: [],
};

const reducer: Reducer<IAuth> = (state: IAuth = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.CHECK_USER_EMAIL_REQUEST:
    case AuthActionTypes.CHECK_USER_QUOTE_REQUEST:
      return state;
    case AuthActionTypes.CHECK_USER_EMAIL_FAILURE:
    case AuthActionTypes.CHECK_USER_QUOTE_FAILURE:
      return produce(state, (nextState: IAuth) => {
        nextState.error = { ...action.payload };
        nextState.user = null;
        nextState.quotes = [];
      });
    case AuthActionTypes.CHECK_USER_QUOTE_SUCCESS:
      return produce(state, (nextState: IAuth) => {
        nextState.user = action.payload.user;
        nextState.quotes = action.payload.quotes;
      });
    case AuthActionTypes.RESET_USER_STATE:
      return initialState;
    default:
      return state;
  }
};

export { reducer as AuthReducer };
