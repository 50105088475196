import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { generatePathWithQuery } from "../../../../shared/utils";
import { ROUTES } from "../../../../shared/constants";
import { IProduct } from "../../interfaces";
import ProductCard from "./ProductCard/ProductCard";

import "./styles.scss";
import { setProductSelectProduct } from "../../store/actions";

interface IProductCardsProps {
  products: IProduct[];
  isTesting?: boolean;
  consumerLink?: string;
}

const ProductCards: React.FunctionComponent<IProductCardsProps> = ({ products, isTesting, consumerLink }) => {
  const dispatch = useDispatch();

  const handleRedirectToPossibleRate = useCallback(
    (product: IProduct) => {
      const query = isTesting ? { testing: true } : undefined;
      const redirectPath = consumerLink
        ? generatePathWithQuery(ROUTES.CONSUMER_LOAN_POSSIBLE_RATE, { params: { consumerLink }, query })
        : generatePathWithQuery(`${ROUTES.POSSIBLE_RATE}/${product.id}`, { query });
      dispatch(push(redirectPath));
      dispatch(setProductSelectProduct(product));
    },
    [consumerLink, isTesting, dispatch],
  );

  const isSingleProduct = useMemo(() => products?.length === 1, [products]);

  return (
    <div className="product-cards">
      {products.map((product) => (
        <ProductCard
          product={product}
          isSingleProduct={isSingleProduct}
          productClickHandler={handleRedirectToPossibleRate}
          key={product.id}
        />
      ))}
    </div>
  );
};

export default ProductCards;
