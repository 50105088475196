import { Reducer } from "redux";
import produce from "immer";

import { AppActionTypes } from "./constants";
import { IAppStateType } from "../interfaces";

const initialState: IAppStateType = {
  error: null,
};

const reducer: Reducer<IAppStateType> = (state: IAppStateType = initialState, action) => {
  switch (action.type) {
    case AppActionTypes.SET_GA_INIT: {
      return produce(state, (nextState) => {
        nextState.gaInitialized = action.payload;
      });
    }
    default:
      return state;
  }
};
export { reducer as AppReducer };
