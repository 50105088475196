function getFileFormat(fileName?: string) {
  return fileName ? fileName.split(".").pop() : undefined;
}

const fileView = function ({ file_name, file_url }: Record<string, string>) {
  const newTab = window.open("", file_name);
  const fileFormat: string | undefined = getFileFormat(file_name);
  if (newTab) {
    if (fileFormat && ["jpg", "jpeg", "png"].includes(fileFormat)) {
      return (newTab.document.body.innerHTML = `<img src="${file_url}">`);
    }
    if (fileFormat === "pdf") {
      return (newTab.document.body.innerHTML = `<h1>Document preview</h1> <object data="${file_url}" typemustmatch="" type="application/pdf" width="100%" height="1200px">
          <p>You don't have a PDF plugin, but you can
          <a href="${file_url}">download the PDF file.
          </a>
        </p>
          </object>`);
    }
    return (newTab.document.body.innerHTML = `<span>Unsupported file format</span>`);
  }
};

export default fileView;
