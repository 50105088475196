import React, { useEffect, useState, useCallback } from "react";
import "./styles.scss";
import { QUESTION_TYPES, FIELD_SIZES } from "../../../constants";
import { TextQuestion, NumberQuestion, DateQuestion, DropdownQuestion } from "../../../components";
import { IQtnQuestion, ITableQuestionProps } from "../../../interfaces";
import { getFieldValue } from "../../../../Qtn/components/QtnSection/effects";
import { getTableQuestionsState } from "../../../../Qtn/store/selectors";
import { getUniqIntegrationName } from "../../../../Qtn/components/QtnSection/utils";
import { useSelector } from "react-redux";
import { deviceDetect } from "../../../../../utils/resizeListener/store/selectors";
import Button from "../../../../../shared/components/styled/Button";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { flow, groupBy, orderBy } from "lodash";

export type TableFieldSettings = {
  initialValue: Record<string, string>;
  isRequired: boolean;
};

const TableQuestion: React.FunctionComponent<ITableQuestionProps> = (props) => {
  const {
    updateSectionError,
    integration_name,
    table_questions,
    size,
    form: { setFieldValue, setFieldError, values },
    followupIds,
    questionChildrenIds,
    setFollowupQuestions,
    addQuestionToInit,
    removeTableQuestionFromInit,
  } = props;
  const lastTableFieldState = useSelector(getTableQuestionsState());
  const { isMobile } = useSelector(deviceDetect());
  const [selectedAnswers, setAnswers] = useState<any>([]);

  const getFieldsValues = (prefix: string) => {
    const prefixed = prefix ? `${prefix}~new` : undefined;
    return getFieldValue(table_questions, false, false, prefixed);
  };
  const addTableFieldSettings = (tableQtnsValues: Record<string, string>) => {
    const res = Object.entries(tableQtnsValues).reduce((acc, current) => {
      const [key, value] = current;
      const tableQuestion = table_questions.find((qtn: IQtnQuestion) => qtn.integration_name === key);
      acc.push({
        initialValue: { [key]: value },
        isRequired: tableQuestion ? tableQuestion.is_required : false,
      });
      return acc;
    }, [] as Array<TableFieldSettings>);
    return res;
  };
  const applyTableSettings = (settings: TableFieldSettings) => {
    addQuestionToInit(settings, integration_name, props.form.values);
    updateSectionError(integration_name);
    setFieldError(integration_name, "");
  };

  const getTableFieldsSettings = flow([getFieldsValues, addTableFieldSettings, applyTableSettings]);

  useEffect(() => {
    if (props.field && Object.keys(props.field.value).length && props.field.value[integration_name].length === 0) {
      getTableFieldsSettings();
    }

    const lastState = lastTableFieldState[integration_name];
    if (Object.keys(lastTableFieldState).length && lastState.length && props.field) {
      if (JSON.stringify(lastState) !== JSON.stringify(props.field.value[integration_name])) {
        setFieldValue(integration_name, lastState);
      }
    }
  }, [
    props.field,
    integration_name,
    lastTableFieldState,
    setFieldValue,
    table_questions,
    addQuestionToInit,
    values,
    getTableFieldsSettings,
  ]);

  const removeTableQuestion = (idx: number) => {
    removeTableQuestionFromInit(idx, integration_name, props.form.values);
  };

  const renderQuestionTableField = (question: IQtnQuestion, idx: number) => {
    const { TEXT, NUMBER, DATE, DROPDOWN } = QUESTION_TYPES;
    const { FULL, MEDIUM } = FIELD_SIZES;
    const { is_required, ...rest } = props;
    const commonProps = {
      ...rest,
      ...question,
      internalId: idx,
      parent: props.integration_name,
      showErrorComponent: true,
      integration_name: getUniqIntegrationName(
        props.field.value[props.integration_name],
        idx,
        question.integration_name,
      ),
      size: isMobile ? FULL : question.question_size.size,
    };

    switch (question.question_type.type) {
      case TEXT:
        return <TextQuestion {...commonProps} />;
      case NUMBER:
        return <NumberQuestion {...commonProps} />;
      case DATE:
        return <DateQuestion {...commonProps} size={question.question_size.size || MEDIUM} />;
      case DROPDOWN:
        return (
          <DropdownQuestion
            {...commonProps}
            followupIds={followupIds}
            questionChildrenIds={questionChildrenIds}
            setFollowupQuestions={setFollowupQuestions}
          />
        );
      default:
        return <React.Fragment />;
    }
  };

  const getSelectedTableQuestion = useCallback(() => {
    const fieldsWithAnswer: any = {};

    const tableQuestions = values[integration_name] || [];
    tableQuestions.forEach((tQuestion: any, idx: number) => {
      Object.keys(tQuestion).forEach((q) => {
        if (values[q]) {
          if (!fieldsWithAnswer[idx]) {
            fieldsWithAnswer[idx] = [values[q]];
          } else {
            fieldsWithAnswer[idx].push(values[q]);
          }
        }
      });
    });

    return fieldsWithAnswer;
  }, [values, integration_name]);

  useEffect(() => {
    setAnswers(getSelectedTableQuestion());
  }, [getSelectedTableQuestion]);

  const renderTableRow = (i: number) => {
    const grouppedQuestions = groupBy(table_questions, "group");
    return (
      <div className="table-field-row">
        {Object.keys(grouppedQuestions).map((row) => {
          return (
            <div key={`group-${row}`} className={size}>
              {orderBy(grouppedQuestions[row], ["order"], ["asc"])
                .filter((q) => {
                  const selectedFollowupOption =
                    Object.keys(selectedAnswers).length &&
                    selectedAnswers[i] &&
                    (selectedAnswers[i].includes(q.followup_option_id) ||
                      selectedAnswers[i].includes(String(q.followup_option_id)));

                  return !q.followup_option_id || selectedFollowupOption;
                })
                .map((question: any, idx: number) => {
                  return (
                    <div key={question.id} className="table-field-wrapper">
                      <div className={"table-title"}>
                        {question?.is_required ? (
                          <div className="required">*&nbsp;</div>
                        ) : (
                          <div className="required">{""}</div>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: question.title as string }} />
                      </div>
                      <div className="table-form-filed">{renderQuestionTableField(question, i)}</div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTableRows = () => {
    const count =
      props.field.value && Object.keys(props.field.value).length
        ? Object.keys(props.field.value[integration_name]).length
        : 0;
    const rows = [];
    for (let i = 0; i < count; i++) {
      rows.push(
        <div key={`table-${i}`}>
          <div className={classnames("table-field", { row: true })}>
            <div className="table-field-container">{renderTableRow(i)}</div>
            <div className={classnames("table-actions", { row: true })}>
              {count !== 1 && (
                <img
                  onClick={() => removeTableQuestion(i)}
                  className="trash"
                  src="/icons/remove-quote.svg"
                  alt="Delete"
                />
              )}
            </div>
          </div>

          {i === count - 1 && (
            <Button
              height="44"
              variant="primary-new-green"
              aria-label="Add"
              type="button"
              width="140"
              onClick={() => getTableFieldsSettings(uuidv4())}
            >
              Add More
            </Button>
          )}
        </div>,
      );
    }

    return rows;
  };

  return <div id={integration_name}>{renderTableRows()}</div>;
};

export default TableQuestion;
