import Button from "../../../../../shared/components/styled/Button";
import React, { FC } from "react";
import "./index.scss";
import ModalComponent from "../../../../../shared/components/common/ModalComponent/ModalComponent";

interface IProceedWithoutSavingModalProps {
  showModal: boolean;
  handleProceed: () => void;
  handleRemain: () => void;
}

const ProceedWithoutSavingModal: FC<IProceedWithoutSavingModalProps> = (props) => {
  const { handleProceed, handleRemain, showModal } = props;
  return (
    <ModalComponent
      show={showModal}
      imgSrc="/icons/info_icon.svg"
      title="Proceed Without Saving?"
      msg="Changing the product will not save your data."
    >
      <div className="modal-buttons-proceed">
        <Button aria-label="Remain" width={150} onClick={handleRemain} variant="secondary-new-green">
          Cancel
        </Button>
        <Button
          aria-label="Continue"
          width={150}
          variant="primary-new-green"
          onClick={handleProceed}
          className="modal-buttons-proceed_submit"
        >
          Proceed Anyway
        </Button>
      </div>
    </ModalComponent>
  );
};
export default ProceedWithoutSavingModal;
