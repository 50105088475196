import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import configElement from "../../../../config";
import Logo from "../Logo/Logo";
import ProductSelector from "../ProductSelector/ProductSelector";
import { IProduct } from "../../../../containers/Products/interfaces";

import "./styles.scss";
import { MobileSidebar } from "../MobileSidebar";
import { useDispatch, useSelector } from "react-redux";
import { deviceDetect } from "../../../../utils/resizeListener/store/selectors";
import { getAllProducts, getProductSelectProduct } from "../../../../containers/Products/store/selectors";
import { getProducts, setProductSelectProduct } from "../../../../containers/Products/store/actions";
import { clearQuote } from "../../../../containers/Qtn/store/actions";
import { push } from "connected-react-router";
import { cookie, generatePathWithQuery } from "../../../utils";
import { ROUTES } from "../../../constants";
import { useHaveQuery } from "../../../hooks";
import ProceedWithoutSavingModal from "../../../../containers/Qtn/components/QtnModals/ProceedWithoutSavingModal/ProceedWithoutSavingModal";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router";

interface IHeaderProps {
  title?: string | ReactNode;
  children?: ReactNode;
  logo?: boolean;
  login?: boolean;
  showProductSelector?: boolean;
  estimatedTime?: number;
  productId?: number | string;
  showProceedPopup?: boolean;
  hideProductSelectorInDesktopHeader?: boolean;
  hideSidebar?: boolean;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  logo,
  showProductSelector,
  estimatedTime,
  productId,
  showProceedPopup,
  hideProductSelectorInDesktopHeader,
  hideSidebar,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showProceedWithoutSavingModal, setShowProceedWithoutSavingModal] = useState(false);
  const { isMobile } = useSelector(deviceDetect());
  const isTesting = !!useHaveQuery("testing");
  const dispatch = useDispatch();
  const selectedProductState = useSelector(getProductSelectProduct());
  const products = useSelector(getAllProducts());
  const { consumerLink } = useParams<{ consumerLink: string }>();

  const onOpenMobileSidebar = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  const handleLogin = useCallback(() => {
    window.open(configElement.maritimeUrl, "_self");
  }, []);

  const handleRedirectToCalculationPage = useCallback(
    (productToRedirect: IProduct) => {
      const query = isTesting ? { testing: true } : undefined;
      if (productToRedirect?.id) {
        dispatch(clearQuote.request({ data: null }));
        cookie.writeCookie("sessionId", uuidv4(), 3);
        dispatch(push(generatePathWithQuery(`${ROUTES.POSSIBLE_RATE}/${productToRedirect.id}`, { query })));
      }
    },
    [dispatch, isTesting],
  );
  const handleSetSelectedProduct = useCallback(
    (product: IProduct) => {
      if (!product || product.id === selectedProductState?.id) {
        return;
      }
      dispatch(setProductSelectProduct(product));
      setIsSidebarOpen(false);
      if (showProceedPopup) {
        setShowProceedWithoutSavingModal(true);
      } else {
        handleRedirectToCalculationPage(product);
      }
    },
    [dispatch, handleRedirectToCalculationPage, selectedProductState, showProceedPopup],
  );

  useEffect(() => {
    if (products.length <= 0) {
      if (consumerLink) {
        dispatch(getProducts.request({ isTesting, uniqLink: consumerLink }));
      } else {
        dispatch(getProducts.request({ isTesting }));
      }
    }
    return () => {};
  }, [consumerLink, dispatch, isTesting, products.length]);

  useEffect(() => {
    if (!selectedProductState && products.length > 0) {
      const product = products.find((product) => product.id === Number(productId)) || products[0];
      dispatch(setProductSelectProduct(product));
    }
  }, [selectedProductState, products, dispatch, productId]);

  const handleCloseProceedWithoutSavingModal = useCallback(() => {
    const product =
      products.find((product) => product.id === Number(productId || selectedProductState?.id)) || products[0];
    dispatch(setProductSelectProduct(product));
    setShowProceedWithoutSavingModal(false);
  }, [dispatch, productId, products, selectedProductState]);

  const handleProceedWithoutSaving = useCallback(() => {
    setShowProceedWithoutSavingModal(false);
    selectedProductState?.id && handleRedirectToCalculationPage(selectedProductState);
  }, [handleRedirectToCalculationPage, selectedProductState]);

  useEffect(() => {
    if (consumerLink) {
      sessionStorage.setItem("consumerLoan", consumerLink);
    } else {
      sessionStorage.clear();
    }
    return () => {};
  }, [consumerLink]);

  const getSelectedProduct = useMemo(() => {
    return selectedProductState || products[0];
  }, [selectedProductState, products]);

  return (
    <>
      <header>
        <div className="header-wrapper">
          <div className="header-left">
            {isMobile && !hideSidebar ? (
              <img
                src={"/icons/burger_menu_green.svg"}
                alt="sidebar"
                className="header-left_burger-menu"
                onClick={onOpenMobileSidebar}
              />
            ) : null}
            <div className="logo-wrapper">
              {logo ? <Logo isTesting={isTesting} /> : null}
              {showProductSelector && !isMobile && !hideProductSelectorInDesktopHeader ? (
                <ProductSelector
                  products={products}
                  selectedProduct={getSelectedProduct}
                  setSelectedProduct={handleSetSelectedProduct}
                />
              ) : null}
            </div>
          </div>
          <div className="header-right">
            {estimatedTime ? (
              <div className="estimated-time">
                <img src="/icons/sand_clock_green.svg" alt="Estimated Time" />
                <div className="estimated-time_left">
                  <div className="estimated-time_left_time">
                    <span>
                      {estimatedTime} {estimatedTime === 1 ? "minute" : "minutes"}
                    </span>
                  </div>
                  <div className="estimated-time_left_comment">Estimated length</div>
                </div>
              </div>
            ) : null}
            {isMobile ? null : (
              <div className="login-wrapper" onClick={handleLogin}>
                <img src={"/icons/login_green.svg"} alt="Log In" />
                <span>Log In</span>
              </div>
            )}
          </div>
        </div>
      </header>
      <MobileSidebar
        isOpen={isSidebarOpen}
        onClose={onOpenMobileSidebar}
        onLogin={handleLogin}
        showProductSelector={showProductSelector}
        products={products}
        selectedProduct={getSelectedProduct}
        setSelectedProduct={handleSetSelectedProduct}
      />
      <ProceedWithoutSavingModal
        showModal={showProceedWithoutSavingModal}
        handleProceed={handleProceedWithoutSaving}
        handleRemain={handleCloseProceedWithoutSavingModal}
      />
    </>
  );
};

export default Header;
