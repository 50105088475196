import React from "react";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { getTrackBackground, Range } from "react-range";
import { FormikErrors } from "formik";

import { removeSeparator } from "../../../utils/validators";
import RangeNotes from "./RangeNotes/RangeNotes";

import "./styles.scss";

interface IRangeFieldProps {
  title: string;
  subtitle?: string;
  note?: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
  prefix?: string;
  suffix?: string;
  isSticky?: boolean;
  rangeLeftNotes?: string;
  rangeRightNotes?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  needsAutocorrection?: boolean;
  showRange?: boolean;
  placeholder?: string;
}

const RangeField: React.FunctionComponent<IRangeFieldProps> = (props) => {
  const {
    title,
    subtitle,
    note,
    onChange,
    value,
    min = 0,
    max,
    prefix,
    suffix,
    rangeLeftNotes,
    rangeRightNotes,
    error,
    step = 1,
    isSticky = false,
    needsAutocorrection = true,
    showRange = true,
    placeholder,
  } = props;

  return (
    <>
      <div className={classnames("field-container range-field", { sticky: isSticky }, { error })}>
        <div className="title-wrapper">
          <div className="title">{title}</div>
          <div className="field-value-wrapper">
            <div className={classnames("field-input", { hasError: !!error })}>
              {prefix && <div className={classnames("input-prefix", { error: !!error })}>$</div>}
              <NumberFormat
                allowNegative={false}
                allowEmptyFormatting={true}
                thousandSeparator={true}
                placeholder={placeholder}
                value={value}
                decimalScale={0}
                onBlur={(e) => {
                  const value = Number(removeSeparator(e.target.value).replace(prefix, "").replace(suffix, ""));
                  onChange(needsAutocorrection ? (value > max ? max : value < min ? min : value) : value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    const target = e.target as HTMLInputElement;
                    const value = Number(removeSeparator(target.value).replace(prefix, "").replace(suffix, ""));
                    onChange(needsAutocorrection ? (value > max ? max : value < min ? min : value) : value);
                  }
                }}
                // prefix={prefix}
                suffix={suffix}
                className={"input-without-range"}
              />
            </div>
            <div className="subtitle-wrapper">{subtitle}</div>
          </div>
        </div>
        {showRange ? (
          <div className={isSticky ? "field-input-sticky" : "field-input"}>
            <Range
              step={step}
              min={min}
              max={max}
              values={[value <= min ? min : value]}
              onChange={(value) => {
                onChange(value[0]);
              }}
              renderTrack={({ props, children }) => (
                <div
                  className="track"
                  {...props}
                  style={{
                    ...props.style,
                    height: isSticky ? "1.5px" : "6px",
                    margin: isSticky ? "0 12px" : "0 10px",
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: "#EDEDED",
                    background: getTrackBackground({
                      values: [value],
                      colors: [isSticky ? (error ? "#E25969" : "#1C4DA1") : "#6F92FF", "#EDEDED"],
                      min: min,
                      max: max,
                    }),
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  className="thumb"
                  {...props}
                  style={{
                    ...props.style,
                    height: isSticky ? "8px" : "20px",
                    width: isSticky ? "8px" : "20px",
                    borderRadius: "16px",
                    backgroundColor: isSticky ? "#FFFFFF" : "#2B48A3",
                    outline: "none",
                  }}
                />
              )}
            />
          </div>
        ) : null}
        <div className="note">{note}</div>
      </div>
      {(rangeLeftNotes || rangeRightNotes) && (
        <RangeNotes rangeLeftNotes={rangeLeftNotes} rangeRightNotes={rangeRightNotes} />
      )}
      <div className="input-error">{error ? error : null}</div>
    </>
  );
};

export default RangeField;
