import appSchemas from "../../containers/App/api/schemas";

import Ajv from "ajv";
import * as _ from "lodash";

const ajv = new Ajv({ removeAdditional: true });

const allSchemas = {
  ...appSchemas,
};

const compiledSchemas = _.mapValues(allSchemas, (value: object) => ajv.compile(value));

export default compiledSchemas;
