import { put, call } from "redux-saga/effects";
import { Action } from "typesafe-actions";
import { startLoader, stopLoader } from "../shared/components/common/Loader/store/actions";

export default function* (
  successAction: Function,
  errorAction: (errorMessage: string) => Action<any>,
  callback?: () => void,
) {
  try {
    yield put(startLoader());
    yield call(successAction());
  } catch ({ message }) {
    yield put(errorAction(message as string));
  } finally {
    if (callback) callback();
    yield put(stopLoader());
  }
}
