import React, { useEffect, useCallback } from "react";
import { Switch } from "react-router";
import { routesConfig, REDIRECT } from "../../routes";
import routeAssessor from "../../utils/routeAssessor";
// import ChatIcon from "../../shared/components/common/Chat/ChatIcon";
import { useSelector, useDispatch } from "react-redux";
import { getRoutes } from "../../store/selectors";
import { deviceDetect } from "../../utils/resizeListener/store/selectors";
import configElement from "../../config";
import { updateResizeListener } from "../../utils/resizeListener/store/actions";
import { SnackbarProvider } from "notistack";
import NotistackContainer from "../Notifications/components/NotistackContainer/NotistackContainer";
import NotistackActionButton from "../Notifications/components/NotistackActionButton/NotistackActionButton";
import { makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { actions, selectors } from "./store";
import ReactGA from "react-ga";
import config from "../../config";
import history from "../../shared/history";
import { hotjar } from "react-hotjar";
// import { setInitialQtnValues } from "../Qtn/store/actions";

/**
 * Move these styles to a more convenient place
 */
const defaultSnackStyle = {
  borderRadius: "10px",
  minWidth: "324px",
  minHeight: "50px",
  color: "rgba(255, 255, 255, 1)",
};

const snackStyles = makeStyles({
  success: {
    background: "rgba(8, 143, 143, 1)",
    boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.09)",
    ...defaultSnackStyle,
  },
  error: {
    background: "rgba(225, 77, 113, 1)",
    boxShadow: "0px 2px 8px rgba(225, 77, 112, 0.7)",
    ...defaultSnackStyle,
  },
  info: {
    background: "rgba(9, 173, 226, 1)",
    boxShadow: "0px 2px 8px rgba(9, 173, 226, 0.7)",
    ...defaultSnackStyle,
  },
});

const maritimeTheme = require("../../styles/index.scss");

export default () => {
  const GA_TRACKER_NAMES = ["banking"];
  const gaInitialized = useSelector(selectors.gaState());
  const styles = snackStyles();
  const { isMobile } = useSelector(deviceDetect());
  const dispatch = useDispatch();
  const {
    location: { pathname },
  } = useSelector(getRoutes());

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const { hjid, hjsv } = configElement;
      hotjar.initialize(hjid as number, hjsv as number);
    }
  });

  useEffect(() => {
    if (pathname && REDIRECT.find((poute) => pathname.includes(poute))) {
      window.open(`${configElement.maritimeUrl}${pathname}`, "_self");
    }
  }, [pathname, dispatch]);

  const resizeListener = useCallback(() => {
    const width = getWidth();
    const detectStatus = width <= 1024;

    if (isMobile !== detectStatus) {
      dispatch(updateResizeListener({ isMobile: detectStatus }));
    }
  }, [isMobile, dispatch]);

  // useEffect(() => {
  //   const savedUser = localStorage.getItem("user");
  //   if (savedUser) {
  //     dispatch(setInitialQtnValues(JSON.parse(savedUser)));
  //   }
  // }, [pathname, dispatch]);

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    resizeListener();
  }, [resizeListener]);

  useEffect(() => {
    if (!gaInitialized && config.googleAnalyticsId) {
      const debug = process.env.NODE_ENV === "production" ? false : true;

      ReactGA.initialize(config.googleAnalyticsId, {
        debug,
        gaOptions: {
          cookieDomain: "none",
          allowLinker: true,
          name: GA_TRACKER_NAMES[0],
        },
      });

      ReactGA.set({ page: history.location.pathname }, GA_TRACKER_NAMES);
      ReactGA.pageview(history.location.pathname, GA_TRACKER_NAMES);

      dispatch(actions.setGaInit(true));
    }
  }, [GA_TRACKER_NAMES, dispatch, gaInitialized]);

  useEffect(() => {
    if (gaInitialized) {
      ReactGA.plugin.require("linker");
      ReactGA.ga("linker:autoLink", [
        "http://www.maritimefinancial.com/",
        "https://www.app.maritimefinancial.com/",
        "https://www.app.banking.maritimefinancial.com/",
      ]);
      history.listen((location) => {
        ReactGA.set({ page: location.pathname }, GA_TRACKER_NAMES);
        ReactGA.pageview(location.pathname, GA_TRACKER_NAMES);
      });
    }
  }, [GA_TRACKER_NAMES, gaInitialized]);

  // use

  useCallback(() => {}, []);

  const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  return (
    <ThemeProvider theme={createMuiTheme({ custom: maritimeTheme })}>
      <SnackbarProvider
        classes={{
          variantSuccess: styles.success,
          variantError: styles.error,
          variantInfo: styles.info,
        }}
        hideIconVariant
        maxSnack={8}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        preventDuplicate={true}
        autoHideDuration={3000}
        action={(key) => <NotistackActionButton snackKey={key} />}
      >
        <NotistackContainer />
        <Switch>
          <>
            {routesConfig.map((route) => routeAssessor(null, route))}
            {/* <ChatIcon /> */}
          </>
        </Switch>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
