import React, { useState, useEffect, useCallback } from "react";
import NumberFormat from "react-number-format";
import classnames from "classnames";

import { IProductTerm } from "../../../../Products/interfaces";
import Button from "../../../../../shared/components/styled/Button";

import "./styles.scss";

interface IRateOptionsProps {
  amount: number;
  downpayment: number;
  term: number;
  setFieldValue: (field: string, value: any) => void;
  rateOpts: { [key: string]: number };
  termTable: IProductTerm[];
  hasErrors: boolean;
  validateFormFields: () => void;
  title: string;
  isMainRate?: boolean;
}

const RateOptions: React.FunctionComponent<IRateOptionsProps> = (props) => {
  const {
    amount,
    downpayment,
    term,
    setFieldValue,
    rateOpts,
    termTable,
    hasErrors,
    validateFormFields,
    title,
    isMainRate = false,
  } = props;
  const [rate, setRate] = useState<{ [key: string]: number }>({ min: 0, max: 0 });

  const updatedRateAndPayment = useCallback(() => {
    const termPercent = termTable.find((item) => item.term === Number(term));

    if (termPercent) {
      const calculatedMinRate = rateOpts.min + termPercent.percent;
      const calculatedMaxRate = rateOpts.max + termPercent.percent;
      setRate({
        min: calculatedMinRate < rateOpts.min ? rateOpts.min : calculatedMinRate,
        max: calculatedMaxRate > rateOpts.max ? rateOpts.max : calculatedMaxRate,
      });
    }
  }, [term, termTable, rateOpts]);

  useEffect(() => {
    if (term) updatedRateAndPayment();
  }, [term, updatedRateAndPayment]);

  const loanCalc = (principal: number, rate: number, term: number) => {
    // Can use a whole number percentage or decimal
    let updatedRate = 0;
    const updatedTerm = term * 12;

    if (rate > 1) {
      updatedRate = rate * 0.01;
    } else {
      updatedRate = rate;
    }
    // Can accept term in years or months
    const monthlyRate = updatedRate / 12;
    const factor = Math.pow(monthlyRate + 1, updatedTerm);
    const numerator = monthlyRate * factor;
    const denominator = factor - 1;
    const quotient = numerator / denominator;
    const payment = principal * quotient;

    return payment.toFixed(2);
  };

  const handleAltRateSubmit = () => {
    validateFormFields();
    !hasErrors && setFieldValue("term", term);
  };

  const getMonthlyPayment = () => {
    const payment = {
      min: loanCalc(amount, rate.min, term),
      max: loanCalc(amount, rate.max, term),
    };

    return (
      <>
        <NumberFormat
          value={payment.min}
          displayType={"text"}
          thousandSeparator={true}
          prefix={`$${String.fromCharCode(160)}`}
        />
        &nbsp; - &nbsp;
        <NumberFormat
          value={payment.max}
          displayType={"text"}
          thousandSeparator={true}
          prefix={`$${String.fromCharCode(160)}`}
        />
      </>
    );
  };

  const getFixedApr = () => {
    return (
      <>
        {rate.min} % - {rate.max} %
      </>
    );
  };

  return (
    <div className={classnames("rate-wrapper", { main: isMainRate })}>
      <div className="rate-wrapper__title-box">
        <div className="rate-wrapper__title">{title}</div>
      </div>
      <div className="rate-option-wrapper">
        <div className="rate-option first">
          <div className="rate-option__name">Monthly Payment</div>
          <div className="rate-option__value">{getMonthlyPayment()}</div>
        </div>
        <div className="rate-option">
          <div className="rate-option__name">Rate</div>
          <div className="rate-option__value">{getFixedApr()}</div>
        </div>
        <div className="rate-option">
          <div className="rate-option__name">Downpayment</div>
          <div className="rate-option__value">
            <NumberFormat
              value={downpayment}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`$${String.fromCharCode(160)}`}
            />
          </div>
        </div>
        <div className="rate-option">
          <div className="rate-option__name">Term</div>
          <div className="rate-option__value">{`${term} year${term !== 1 ? "s" : ""}`}</div>
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          aria-label="Get Approved"
          width="100%"
          variant={isMainRate ? "outlined-primary-new-green" : "ghost"}
          onClick={handleAltRateSubmit}
        >
          Get Approved
        </Button>
      </div>
    </div>
  );
};

export default RateOptions;
