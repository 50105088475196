import { Reducer } from "redux";
import { ProductActionTypes } from "./constants";
import produce from "immer";
import { IProductState } from "../interfaces";

const initialState: IProductState = {
  selectedProduct: null,
  products: [],
  organizations: [],
  error: null,
  advisor_id: null,
  userOrganization: null,
  productSelectProduct: null,
};

const reducer: Reducer<IProductState> = (state: IProductState = initialState, action) => {
  switch (action.type) {
    case ProductActionTypes.FETCH_PRODUCTS_SUCCESS:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.products = action.payload;
        nextState.selectedProduct = action.payload.length ? action.payload[0] : null;
      });
    case ProductActionTypes.FETCH_ADVISOR_FAILURE:
    case ProductActionTypes.FETCH_PRODUCTS_FAILURE:
    case ProductActionTypes.FETCH_ORGANIZATION_FAILURE:
      return produce(state, (nextState) => {
        nextState.error = action.payload;
      });
    case ProductActionTypes.SET_PRODUCT_SUCCESS:
      return produce(state, (nextState) => {
        nextState.selectedProduct = action.payload;
      });

    case ProductActionTypes.SET_PRODUCT_SELECT_PRODUCT:
      return produce(state, (nextState) => {
        nextState.productSelectProduct = action.payload;
      });

    case ProductActionTypes.FETCH_ADVISOR_SUCCESS:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.advisor_id = action.payload ? action.payload : nextState.advisor_id;
      });

    case ProductActionTypes.FETCH_ORGANIZATION_SUCCESS:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.organizations = action.payload ? action.payload : [];
      });

    case ProductActionTypes.FETCH_ORGANIZATION_BY_USER_EMAIL_SUCCESS:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.userOrganization = action.payload;
      });

    default:
      return state;
  }
};

export { reducer as ProductReducer };
