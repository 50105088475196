import React, { FC, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles.scss";
import config from "../../../../config";

interface RecapthcaPropsTypes {
  onSuccess: (...args: any[]) => any | Promise<any>;
  onErrored?: (...args: any[]) => any | Promise<any>;
}

const Recaptcha: FC<RecapthcaPropsTypes> = ({ onSuccess, onErrored }) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  return (
    <div className="recaptcha">
      <ReCAPTCHA
        sitekey={config.recaptchaAPIKey ? config.recaptchaAPIKey : ""}
        ref={(elem) => (recaptchaRef.current = elem)}
        onChange={(token) => {
          onSuccess(token);
        }}
        onExpired={() => {
          if (recaptchaRef.current)
            //@ts-ignore
            recaptchaRef.current.reset();
        }}
        onErrored={onErrored}
      />
    </div>
  );
};

export default Recaptcha;
