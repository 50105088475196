import { IProduct } from "../../containers/Products/interfaces";
import { PRODUCT_TYPES_ENUM } from "../constants";

export enum PRODUCT_TYPES {
  CONSUMER_LOAN = 1,
  GENERAL_LOAN,
  MORTGAGE,
}

export const DEFAULT_PRODUCTS_TYPE = "possible-rate";
export const CAR_PRODUCTS_TYPE = "possible-rate-car";
export const MORTGAGE_PRODUCTS_TYPE = "possible-rate-mortgage";

export const PRODUCT_TYPES_STR: Record<number, string> = {
  [PRODUCT_TYPES.GENERAL_LOAN]: CAR_PRODUCTS_TYPE,
  [PRODUCT_TYPES.MORTGAGE]: MORTGAGE_PRODUCTS_TYPE,
};

export const getProductPercent = (product: IProduct | null): any => {
  if (product) {
    const total = product.amount_min;
    const value = product.downpayment_min;
    const res: any = total === 0 || value === 0 ? 0 : (100 * value) / total;
    return res;
  }
};

export const getMinDownpayment = (price: number, percent: number): number => {
  return parseFloat(((price * percent) / 100).toFixed(2));
};

export const isMorgageType = (product: IProduct | null) => {
  return product?.product_type?.id === PRODUCT_TYPES_ENUM.MORTGAGE;
};
