import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { ProductReducer } from "../containers/Products/store/reducer";
import { QtnReducer } from "../containers/Qtn/store/reducer";
import { LoaderReducer } from "../shared/components/common/Loader/store/reducer";
import { AuthReducer } from "../containers/Auth/store/reducer";
import { ResizeReducer } from "../utils/resizeListener/store/reducer";
import { NotificationReducer } from "../containers/Notifications/store/reducer";
import { FilesReducer } from "../containers/MobileUpload/components/store/reducer";
import { AppReducer } from "../containers/App/store/reducer";
import { History } from "history";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    products: ProductReducer,
    qtn: QtnReducer,
    loader: LoaderReducer,
    auth: AuthReducer,
    resizeDetector: ResizeReducer,
    notifications: NotificationReducer,
    files: FilesReducer,
    app: AppReducer,
  });

// @ts-ignore
export default rootReducer;
