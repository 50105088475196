import React from "react";
import { useLocation, useParams } from "react-router";
import classNames from "classnames";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

import { generatePathWithQuery } from "../../../utils";
import { ROUTES } from "../../../constants";

import "./styles.scss";

interface ILogoProps {
  isTesting?: boolean;
  isAbsolute?: boolean;
}

const Logo: React.FunctionComponent<ILogoProps> = ({ isTesting = false, isAbsolute = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { consumerLink } = useParams<{ consumerLink: string }>();

  const handleLogoClick = () => {
    const query = isTesting ? { testing: true } : undefined;
    consumerLink
      ? dispatch(push(generatePathWithQuery(ROUTES.CONSUMER_LOAN, { params: { consumerLink }, query })))
      : dispatch(push(generatePathWithQuery(ROUTES.MAIN, { query })));
  };

  return (
    <div className={classNames("logo-container", { absolute: isAbsolute })}>
      {false ? (
        <img
          className={classNames("logo-2", { "home-action": location.pathname !== "/" })}
          src={"/images/logo-2.svg"}
          alt="Caribbean Logo"
          onClick={handleLogoClick}
        />
      ) : null}

      <img
        className={classNames("logo-new", { "home-action": location.pathname !== "/" })}
        src={"/images/logo.svg"}
        alt="Maritime Logo"
        onClick={handleLogoClick}
      />
    </div>
  );
};

export default Logo;
