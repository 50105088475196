import React, { ReactNode, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { RouteChildrenProps, RouteComponentProps, useParams } from "react-router";
import queryString from "query-string";

import Header from "../../../../shared/components/common/Header/Header";
import Loader from "../../../../shared/components/common/Loader/Loader";
import ProductLoanOptions from "../../components/ProductLoanOptions/ProductLoanOptions";
import { getAdvisor } from "../../store/actions";
import { getProductSelectProduct } from "../../store/selectors";
import { getRoutes } from "../../../../store/selectors";
import { ROUTES } from "../../../../shared/constants";
import { setInitialQtnValues } from "../../../Qtn/store/actions";
import { cookie } from "../../../../shared/utils";

import "./styles.scss";

interface IProductsProps {
  children?: ReactNode;
  location: {
    search?: string;
  };
}

const ProductCalculate: React.FunctionComponent<IProductsProps & RouteChildrenProps & RouteComponentProps> = (
  props,
) => {
  const {
    location: { search },
  } = props;
  const dispatch = useDispatch();
  const route = useSelector(getRoutes());
  const { id } = useParams<{ id: string }>();
  const selectedProductState = useSelector(getProductSelectProduct());

  const checkPersonalCode = useCallback(() => {
    if ([ROUTES.ADVISOR_CODE].includes(route.location.pathname)) {
      const advisorCode = route.location.pathname.split("/").pop();
      if (advisorCode) {
        dispatch(getAdvisor.request({ personal_code: advisorCode }));
        cookie.writeCookie("personal_code", advisorCode, 30);
        dispatch(push(ROUTES.MAIN));
      }
    }
  }, [dispatch, route.location.pathname]);

  useEffect(() => {
    checkPersonalCode();
    sessionStorage.clear();
    cookie.deleteCookie("employers_not_company");
  }, [checkPersonalCode]);

  useEffect(() => {
    const params = queryString.parse(decodeURI(search));

    if (params && params.email) {
      dispatch(setInitialQtnValues(params));
      localStorage.setItem("user", JSON.stringify(params));
    }
  }, [search, dispatch]);

  return (
    <>
      <Header logo login productId={id} showProductSelector={true} />
      <Loader />
      <main className="product-calculate-wrapper">
        {selectedProductState && <ProductLoanOptions product={selectedProductState} />}
      </main>
    </>
  );
};

export default ProductCalculate;
