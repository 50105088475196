import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import * as Yup from "yup";

import {
  IQtnSection,
  IProductQtn,
  IQtnResponse,
  ILoanQuoteUserAnswers,
  IQtnOption,
  IQuestionProps,
  IFormInit,
  IQtnSectionProps,
  ISmartQtnOption,
} from "../../interfaces";
import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedSection,
  getProductQtn,
  getLoanQuoteResponse,
  getSectionProgress,
  getQtnInitialValues,
  getLastFormikState,
  getFollowUpWithSection,
} from "../../store/selectors";
import { QUESTION_TYPES, FIELD_SIZES } from "../../constants";
import { Formik, FormikProps, FormikValues, Form } from "formik";
import {
  TextQuestion,
  NumberQuestion,
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
  DateQuestion,
  DropdownQuestion,
  TableQuestion,
  AttachFileQuestion,
  SmartTypingQuestion,
} from "../../components";
import {
  setQtnSection,
  createLoanQuote,
  updateLoanQuote,
  updSectionProgress,
  updFollowup,
  updateTableQuestionsState,
  updateDraftLoanQuote,
  saveLastFormikState,
  updateLoanBeforeUnmountQuote,
  setOrganizationQtnValues,
  updFollowupWithSection,
} from "../../store/actions";
import { initFormik, findFollowupQuestions, updateValidationSchema } from "./effects";
import { getAuthState } from "../../../Auth/store/selectors";
import { deviceDetect } from "../../../../utils/resizeListener/store/selectors";
import {
  getLoanQuoteUserAnswers,
  scrollToElement,
  scrollToTop,
  groupAnswersBySections,
  removeFollowUp,
  bindExistUser,
  selectEffect,
} from "./utils";
import QtnActions from "./QtnActions/QtnActions";
import QtnForm from "./QtnForm/QtnForm";
import { getFollowupNames } from "./utils";
import { attachImageViaSocket } from "../../../MobileUpload/components/store/actions";
import { isEqual } from "lodash";
import { TableFieldSettings } from "../QtnQuestions/TableQuestion/TableQuestion";
import { getOrganizationsOptions, getSelectedProduct } from "../../../Products/store/selectors";
import { getOrganizations, setProduct } from "../../../Products/store/actions";
import { QUESTION_FIELDS_POPULATED } from "../../../../shared/constants";
import {
  calculateMonthlyPayment,
  calculateTotalAmount,
  calculateTotalFees,
  calculateTotalInterest,
} from "../../../Products/components/ProductConsumerTypeLoanOptions/utils/calculateFunctions";
import { PaymentCalculation } from "../../../Products/components/ProductConsumerTypeLoanOptions/utils/Payment";
import QtnFormikValuesWatcher from "../QtnFormikValuesWatcher/QtnFormikValuesWatcher";
import { useOrganization } from "../../../../shared/hooks";
import { cookie } from "../../../../shared/utils";
import { SaveAnswersModal } from "../QtnModals/SaveAnswersModal";

import "./styles.scss";

const QtnSection: React.FunctionComponent<IQtnSectionProps> = ({
  product_id,
  setCalculateStatus,
  setSectionErrors,
  sectionErrors,
  disabled,
}) => {
  const { isMobile } = useSelector(deviceDetect());
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [followupIds, updateFollowupQuestions] = useState<number[]>([]);
  const [isNextStepModalOpened, setIsNextStepModalOpened] = useState<boolean>(false);
  const [questionChildrenIds, setQuestionChildrenIds] = useState<{ [key: string]: number[] }>({});
  const quoteOrganization = useOrganization();
  const product = useSelector(getSelectedProduct());
  const dispatch = useDispatch();
  const qtn: IProductQtn = useSelector(getProductQtn(), isEqual);
  const selectedSection: IQtnSection = useSelector(getSelectedSection(), isEqual);
  const qtnInitialValue = useSelector(getQtnInitialValues(), isEqual);
  const qtnResponse: IQtnResponse = useSelector(getLoanQuoteResponse(), isEqual);
  const lastFormikState = useSelector(getLastFormikState());
  const sectionProgress = useSelector(getSectionProgress());
  const smartTypingOptions: Record<string, any[]> = useSelector(getOrganizationsOptions());
  const [lastValidationState, setValidationState] = useState<any>(null);
  const { error, user } = useSelector(getAuthState());
  const { consumerLink } = useParams<{ consumerLink: string }>();
  const sectionFollowupIdsState = useSelector(getFollowUpWithSection());
  const setFollowupQuestions = (followupQuestionsId: number[]) => {
    const followUp: number[] = [...new Set(followupQuestionsId)];
    updateFollowupQuestions(followUp);
    dispatch(updFollowup(followUp));
    dispatch(updFollowupWithSection({ ...sectionFollowupIdsState, [selectedSection.id]: followUp }));
  };

  useEffect(() => {
    if (!product && qtn?.product) {
      dispatch(setProduct.success(qtn.product));
    }
  }, [product, qtn, dispatch]);

  useEffect(() => {
    if (qtnResponse && lastFormikState) {
      if (consumerLink) {
        const payment: any = PaymentCalculation.from({
          term: Number(lastFormikState.term),
          amount: Number(lastFormikState.amount),
          option_type: Number(lastFormikState.option_type),
          rate_max: quoteOrganization?.interest_rate || qtnResponse.questionnaire.product.rate_max,
          negotiation_max: qtnResponse.questionnaire.product.negotiation_max,
        })
          .map(calculateTotalFees)
          .map(calculateMonthlyPayment)
          .map(calculateTotalAmount)
          .map(calculateTotalInterest)
          .unwrap();
        const data = {
          ...qtnResponse,
          total_amount_repaid: payment.totalBalance,
          monthly_payment: payment.monthlyPayment,
          total_interest: payment.totalInterest,
          total_fees: payment.totalFees,
        };
        dispatch(updateLoanQuote.success(data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastFormikState, quoteOrganization]);

  const addQuestionToInit = (tableQuestion: TableFieldSettings[], name: string, formValues: any) => {
    let newInitialValues = { ...formValues };
    newInitialValues[name] = [...formValues[name]];
    const tableFormFields = [
      tableQuestion.reduce((acc: Record<string, string>, field: TableFieldSettings) => {
        acc = { ...acc, ...field.initialValue };
        return acc;
      }, {}),
    ];
    const newTableList = newInitialValues[name].concat([...tableFormFields]);
    newInitialValues[name] = newTableList;

    const newSchema: any = {};

    tableQuestion.forEach((formField) => {
      const [fieldName, fieldValue] = Object.entries(formField.initialValue).flat();
      newSchema[fieldName] = formField.isRequired ? Yup.string().required("This field is required") : Yup.string();
      newInitialValues[fieldName] = fieldValue;
    });

    setValidationSchema(validationSchema.shape(newSchema));

    dispatch(updateTableQuestionsState({ ...newInitialValues }));
  };

  const removeTableQuestionFromInit = (idx: number, name: string, formValues: any) => {
    let newInitialValues = { ...formValues };
    newInitialValues[name] = newInitialValues[name].filter((fields: any, index: number) => {
      if (index !== idx) {
        return true;
      } else {
        Object.keys(fields).forEach((f) => {
          delete newInitialValues[f];
        });
        return false;
      }
    });

    setInitialValues(newInitialValues);
    dispatch(updateTableQuestionsState({ ...newInitialValues }));
  };

  const setPrePopulateValues = useCallback(
    (values: IFormInit) => {
      const newInitialValues = { ...values };
      const questionFieldsPopulated = Object.values(QUESTION_FIELDS_POPULATED);
      if (qtnResponse) {
        const qtnResponseKeys = Object.keys(qtnResponse);
        Object.keys(newInitialValues).forEach((key) => {
          if (
            questionFieldsPopulated.includes(key as QUESTION_FIELDS_POPULATED) &&
            !newInitialValues[key] &&
            qtnResponseKeys.includes(key)
          ) {
            newInitialValues[key] = qtnResponse[key as QUESTION_FIELDS_POPULATED];
          }
        });
        // Populate Current employer name
        if (qtnResponse && qtnResponse.organization && qtnResponse.organization.name) {
          newInitialValues["employers"] = qtnResponse.organization.name;
          dispatch(setOrganizationQtnValues(qtnResponse.organization.id));
        } else if (cookie.readCookie("employers_not_company")) {
          newInitialValues["employers"] = cookie.readCookie("employers_not_company");
        }
      }

      return newInitialValues;
    },
    [qtnResponse, dispatch],
  );

  useEffect(() => {
    if (!smartTypingOptions.organizations.length) {
      dispatch(getOrganizations.request());
    }

    return () => {};
  }, [consumerLink, dispatch, smartTypingOptions.organizations]);

  useEffect(() => {
    if (selectedSection && qtn) {
      let { initialValues } = initFormik(selectedSection, qtnResponse, qtn);
      const { followupQuestionsId, questionChildren } = findFollowupQuestions(
        selectedSection,
        qtn,
        qtnResponse,
        sectionProgress,
      );

      if (lastFormikState && isMobile) {
        // Iterate over keys in lastFormikState and add them to initialValues if they exist
        Object.keys(lastFormikState).forEach((key) => {
          if (lastFormikState[key] !== null && lastFormikState[key] !== undefined) {
            initialValues[key] = lastFormikState[key];
          }
        });
      }

      initialValues = { ...initialValues, ...(qtnInitialValue ? qtnInitialValue : {}) };
      setFollowupQuestions(followupQuestionsId);
      dispatch(updSectionProgress(initialValues));
      setQuestionChildrenIds(questionChildren);

      let newInitialValues = removeAllTemporaryFields(initialValues);
      if (consumerLink) {
        newInitialValues = setPrePopulateValues(newInitialValues);
      }

      dispatch(updateTableQuestionsState({ ...newInitialValues }));
      setInitialValues(newInitialValues);
    }
    /* eslint-disable */
  }, [selectedSection, dispatch, qtn, consumerLink]);

  useEffect(() => {
    if (isMobile && selectedSection.id) {
      scrollToElement(String(selectedSection.id));
    }
  }, [selectedSection, isMobile]);

  useEffect(() => {
    if (selectedSection) {
      const validationSchema = updateValidationSchema(selectedSection, followupIds, qtnResponse, qtn);

      if (
        consumerLink &&
        Object.keys(validationSchema.fields).includes("amount") &&
        Object.keys(validationSchema.fields).includes("term")
      ) {
        const customValidationSchema = validationSchema.shape({
          amount: Yup.number().required("required").min(qtn.product.amount_min).max(qtn.product.amount_max),
          term: Yup.number().required("required").min(qtn.product.duration_min).max(qtn.product.duration_max),
        });
        setValidationSchema(customValidationSchema);
      } else {
        setValidationSchema(validationSchema);
      }

      if (sectionProgress) {
        let newSectionProgress: { [key: string]: any } = {};
        Object.keys(validationSchema.fields).forEach((field) => {
          newSectionProgress[field] = sectionProgress[field];
          if (consumerLink) {
            newSectionProgress = setPrePopulateValues(newSectionProgress);
          }
        });

        dispatch(updSectionProgress(newSectionProgress));
      }
    }
  }, [followupIds, selectedSection, qtnResponse, dispatch, initialValues]);

  const handleSubmit = (data: IFormInit) => {
    !qtnResponse ? createQuote(data) : updateQuote(data, true, false, false);
  };

  const updateDraftQuote = (data: IFormInit) => {
    const { payload }: { payload: ILoanQuoteUserAnswers } = getLoanQuoteUserAnswers(
      qtnResponse,
      qtn,
      data,
      true,
      followupIds,
    );

    const updatedLoanQuote = groupAnswersBySections(payload, followupIds, qtn);
    dispatch(
      updateDraftLoanQuote.request({
        data: user ? bindExistUser(updatedLoanQuote, user) : updatedLoanQuote,
        id: Number(qtnResponse?.id),
        isWelcomeEmail: true,
      }),
    );
  };

  const updateQuote = (
    data: IFormInit,
    next: boolean,
    isSubmit: boolean,
    isFinish: boolean | undefined,
    cb?: () => void,
  ) => {
    if (qtnResponse) {
      const { amount, term, monthly_payment, total_interest, total_fees, total_amount_repaid } = qtnResponse;
      const rateValues = { amount, term, monthly_payment, total_interest, total_fees, total_amount_repaid };
      const { payload } = getLoanQuoteUserAnswers(qtnResponse, qtn, data, true, followupIds);
      const updatedLoanQuote = groupAnswersBySections(
        { ...payload, rate: rateValues } as ILoanQuoteUserAnswers,
        followupIds,
        qtn,
      );

      updatedLoanQuote.finished_on = isFinish ? new Date() : null;
      const loanQuote = user ? bindExistUser(updatedLoanQuote, user) : updatedLoanQuote;
      dispatch(
        updateLoanQuote.request({
          data: loanQuote,
          id: qtnResponse?.id,
          callback: () => {
            dispatch(attachImageViaSocket.request(null));
            if (!isSubmit) changeSection(next);
            cb?.();
          },
        }),
      );
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", () => {
      qtnResponse &&
        dispatch(updateLoanBeforeUnmountQuote.request({ data: null, id: qtnResponse.id, callback: () => {} }));
    });
  }, []);

  const removeAllTemporaryFields = (values: IFormInit) => {
    const newInitialValues = { ...values };

    Object.keys(newInitialValues).forEach((key) => {
      if (key.includes("~new~")) {
        delete newInitialValues[key];
      }

      if (Array.isArray(newInitialValues[key]) && newInitialValues[key].length > 1) {
        newInitialValues[key].forEach((tableQuestion: any) => {
          Object.keys(tableQuestion).forEach((tableKey) => {
            if (tableKey.includes("~new~")) {
              delete tableQuestion[tableKey];
            }
          });
        });
      }
    });

    return newInitialValues;
  };

  const createQuote = (data: IFormInit) => {
    const { payload }: { payload: ILoanQuoteUserAnswers } = getLoanQuoteUserAnswers(
      qtnResponse,
      qtn,
      data,
      false,
      followupIds,
    );

    dispatch(
      createLoanQuote.request({
        data: groupAnswersBySections(payload, followupIds, qtn),
        id: product_id,
        callback: () => changeSection(true),
      }),
    );
  };

  const getSectionIdx = () => qtn.sections.findIndex((section) => section.id === selectedSection.id);

  const changeSection = (next?: boolean, callback?: () => void) => {
    let idx = getSectionIdx();

    if (!next && idx === 0) {
      return;
    }

    if (next && idx === qtn.sections.length - 1) {
      callback && callback();
      return;
    }

    dispatch(setQtnSection.success(qtn.sections[next ? ++idx : --idx]));
    scrollToTop("section-body");
  };

  const scrollToFollowUpQuestion = (opt: number, options: any) => {
    const followUp = options.find((option: any) => option.id === opt).followup_questions;

    if (followUp.length) {
      let questionId: null | string = null;
      qtn.sections.forEach((section) => {
        section.questions.forEach((q) => {
          if (q.id === followUp[0].followup_id) {
            questionId = String(q.id);
          }
        });
      });

      if (questionId) {
        setTimeout(() => scrollToElement(questionId), 0);
      }
    }
  };

  const renderQuestionByType = (question: IQuestionProps) => {
    const { TEXT, SINGLE_CHOICE, MULTIPLE_CHOISE, NUMBER, DATE, DROPDOWN, TABLE, FILE, SMART_TYPING } = QUESTION_TYPES;
    const { WIDE, FULL } = FIELD_SIZES;

    switch (question.question_type.type) {
      case TEXT:
        return (
          <TextQuestion
            product_id={Number(product_id)}
            disabled={disabled}
            size={question.question_size.size || FULL}
            {...question}
          />
        );
      case SINGLE_CHOICE:
        return (
          <SingleChoiceQuestion
            followupIds={followupIds}
            questionChildrenIds={questionChildrenIds}
            setFollowupQuestions={setFollowupQuestions}
            updateSectionErrors={updateSectionErrors}
            scrollToFollowUpQuestion={scrollToFollowUpQuestion}
            disabled={disabled}
            size={question.question_size.size}
            quote={{
              term: qtnResponse?.term,
              monthly_payment: qtnResponse?.monthly_payment,
              total_amount_repaid: qtnResponse?.total_amount_repaid,
              total_fees: qtnResponse?.total_fees,
              total_interest: qtnResponse?.total_interest,
              amount: qtnResponse?.amount,
            }}
            {...question}
          />
        );
      case MULTIPLE_CHOISE:
        return <MultipleChoiceQuestion disabled={disabled} {...question} />;
      case NUMBER:
        return <NumberQuestion disabled={disabled} size={question.question_size.size || WIDE} {...question} />;
      case DATE:
        return <DateQuestion disabled={disabled} size={question.question_size.size || WIDE} {...question} />;
      case DROPDOWN:
        return (
          <DropdownQuestion
            size={question.question_size.size || FULL}
            followupIds={followupIds}
            questionChildrenIds={questionChildrenIds}
            setFollowupQuestions={setFollowupQuestions}
            disabled={disabled}
            {...question}
          />
        );
      case SMART_TYPING:
        let action = selectEffect[question.integration_name];
        action = action ? action(setOrganizationQtnValues, dispatch) : () => {};
        return (
          <SmartTypingQuestion
            size={question.question_size.size || FULL}
            followupIds={followupIds}
            questionChildrenIds={questionChildrenIds}
            setFollowupQuestions={setFollowupQuestions}
            disabled={disabled}
            typingOptions={smartTypingOptions[question.integration_name]}
            selectEffect={action}
            {...question}
          />
        );
      case TABLE:
        return (
          <TableQuestion
            {...question}
            disabled={disabled}
            setInitialValues={setInitialValues}
            followupIds={followupIds}
            questionChildrenIds={questionChildrenIds}
            addQuestionToInit={addQuestionToInit}
            removeTableQuestionFromInit={removeTableQuestionFromInit}
            size={question.question_size.size}
          />
        );
      case FILE:
        return <AttachFileQuestion disabled={disabled} size={question.question_size.size || FULL} {...question} />;
      default:
        return <React.Fragment />;
    }
  };

  const sectionIsAvailable = (dirty: boolean) => {
    if (dirty) return dirty;

    if (qtnResponse) {
      const section = qtnResponse.quote_sections.find((qSection) =>
        qSection.display_sections.find((dSection) => dSection.display_section_id === selectedSection.id),
      );
      return section && section.section_completed;
    }

    return false;
  };

  const updatedFollowupIds: <T>(value: number, options: T[]) => any = (value: number, options: any) => {
    //@ts-ignore
    const followupQuestion = options.find(
      (opt: IQtnOption) => opt.followup_questions && opt.followup_questions.length && opt.id === value,
    );

    const foundQuestionFollowUp: number[] = [];

    /**
     * Filter followupIds queston to hide if one question has options with the same followup_id
     */
    if (followupQuestion) {
      followupQuestion.followup_questions?.forEach((q: any) => foundQuestionFollowUp.push(q.followup_id));
    }

    const followupToHide = options
      //@ts-ignore
      .filter(
        (opt: IQtnOption | ISmartQtnOption) =>
          opt.followup_questions && opt.followup_questions.length && opt.id !== value,
      )
      .map((opt: IQtnOption) => opt.followup_questions!.map((followupOpt) => followupOpt.followup_id))
      .flat()
      .filter((fQuestionId: number) => !foundQuestionFollowUp.includes(fQuestionId));

    /**
     * Question can has options with follow up question and
     * question from options also can has follow up question
     *
     * If we change the answer to a main question then we need to hide
     * the nearest non-relevant children questions and their follow up questions
     */

    let childrenFolowUp: number[] = [];

    followupToHide.forEach((followUpId: any) => {
      childrenFolowUp = childrenFolowUp.concat(questionChildrenIds[followUpId]);
    });

    let updatedFollowupIds = [...followupIds];

    if (
      followupQuestion &&
      followupQuestion.followup_questions &&
      followupQuestion.followup_questions.length &&
      followupQuestion.followup_questions.some((followupOpt: any) => followupIds.includes(followupOpt.followup_id))
    ) {
      updatedFollowupIds = followupIds.filter(
        (id: number) =>
          //@ts-ignore
          !followupQuestion.followup_questions.map((followupOpt: any) => followupOpt.followup_id).includes(id),
      );

      setFollowupQuestions([...updatedFollowupIds, ...followupToHide, ...childrenFolowUp]);
    }

    /**
     * Current followup_question_id === null or empty array and we should remove
     * question added by follow up id from previouse user answer
     */
    if (!followupQuestion && followupToHide.length) {
      setFollowupQuestions([...updatedFollowupIds, ...followupToHide, ...childrenFolowUp]);
    }
  };

  const updateSectionErrors = (value: number, integration_name: string) => {
    if (!lastValidationState) {
      return;
    }

    const { SINGLE_CHOICE } = QUESTION_TYPES;
    let followUpIntegrationNames: string[] = [];

    qtn.sections.forEach((section) => {
      section.questions.forEach((question) => {
        //@ts-ignore
        const selectedOpt = question.options.find((opt: any) => opt.id === value);
        if (question.question_type.type === SINGLE_CHOICE && selectedOpt) {
          if (selectedOpt) {
            //@ts-ignore
            selectedOpt.followup_questions.forEach((followup) => {
              qtn.sections.forEach((s) =>
                s.questions.forEach((q) => {
                  if (q.id === followup.followup_id) {
                    followUpIntegrationNames.push(q.integration_name);
                  }
                }),
              );
            });
          }

          followUpIntegrationNames = [...new Set(followUpIntegrationNames)];
        }
      });
    });

    if (followUpIntegrationNames.length) {
      followUpIntegrationNames.forEach((questionName) => {
        if (!!lastValidationState && !lastValidationState[questionName]) {
          followUpIntegrationNames = followUpIntegrationNames.filter((name) => name !== questionName);
        }
      });
    }

    const newSectionErrors = [...sectionErrors]
      .concat([...new Set(followUpIntegrationNames)])
      .filter((name) => name !== integration_name);
    setSectionErrors(newSectionErrors);
  };
  return (
    selectedSection && (
      <div className="active-section">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formikBag: FormikProps<FormikValues>) => {
            let activeSectionIdx = getSectionIdx();
            const next = sectionIsAvailable(formikBag.dirty);

            if (lastValidationState && Object.keys(lastValidationState).length) {
              // props.validateForm()
            }

            /**
             * Check user email
             */

            if (
              formikBag.values["email"] &&
              error &&
              (error.checkEmail || error.checkUserQuote) &&
              !formikBag.errors.email
            ) {
              formikBag.setFieldError("email", `User with this email already exists`);
              formikBag.setFieldTouched("email", true, false);
            }

            /**
             * Clear initial values after hide follow up questions
             */
            const clearValue = (questions: number[], integration_name: string) => {
              questions &&
                questions.forEach((questionId) => {
                  const field = selectedSection.questions.find((question) => question.id === questionId);

                  if (field) {
                    formikBag.setFieldValue(field.integration_name, "", false);
                  }
                });
            };

            const saveDraftQuote = async () => {
              await submitForm();
              updateDraftQuote(formikBag.values);
            };

            const submitAndGoBack = async () => {
              await submitForm();
              updateQuote(formikBag.values, false, false, false);
            };

            const submitForm = async () => {
              const sectionData = qtnResponse
                ? qtnResponse.quote_sections.find((qSection) =>
                    qSection.display_sections.find((dSection) => dSection.display_section_id === selectedSection.id),
                  )
                : null;
              let sectionStatus = !sectionData ? false : sectionData.section_completed;

              if (qtn.sections.slice(-1).pop()!.id === selectedSection.id) {
                setCalculateStatus(false);
              }

              const errorList = await formikBag.validateForm();
              sectionStatus = Object.keys(errorList).length === 0 ? true : false;

              return sectionStatus;
            };

            const sendSubmitRequest = (finish?: boolean) => {
              setValidationState(formikBag.errors);
              updateQuote(formikBag.values, true, false, finish);

              setIsNextStepModalOpened(false);
            };

            const validateAndSubmit = async (finish: boolean) => {
              const invalidFields = await formikBag.validateForm();

              //TODO:fix
              if (isMobile) {
                if (
                  invalidFields["amount"] ||
                  invalidFields["term"] ||
                  invalidFields["price"] ||
                  invalidFields["total_fees"] ||
                  invalidFields["total_interest"] ||
                  invalidFields["total_amount"] ||
                  invalidFields["organization"] ||
                  invalidFields["monthly_payment"]
                ) {
                  delete invalidFields["amount"];
                  delete invalidFields["term"];
                  delete invalidFields["price"];
                  delete invalidFields["total_fees"];
                  delete invalidFields["total_interest"];
                  delete invalidFields["total_amount"];
                  delete invalidFields["organization"];
                  delete invalidFields["monthly_payment"];
                }
              }

              if (finish) {
                const errorFields: string[] = [...new Set([...Object.keys(invalidFields)])];
                setValidationState(errorFields);

                const fieldsWithError = removeFollowUp(followupIds, errorFields, qtn);

                if (fieldsWithError.length) {
                  Object.keys(invalidFields).forEach((field) => formikBag.setFieldTouched(field, true, false));
                  setSectionErrors(fieldsWithError);

                  let section = qtn.sections.find((section) =>
                    section.questions.some((question) => fieldsWithError.includes(question.integration_name)),
                  );

                  if (section) {
                    let field = section.questions
                      .filter((q) => !q.is_hidden)
                      .find((question) => fieldsWithError.includes(question.integration_name));
                    if (field) {
                      dispatch(setQtnSection.success(section));
                      qtn.sections.forEach((qtnSection, index: number) => {
                        if (section && qtnSection.id === section.id) {
                          activeSectionIdx = index;
                        }
                      });
                      scrollToElement(fieldsWithError[0]);
                    } else {
                      return;
                    }
                  }

                  return;
                }
              }

              if (qtn.sections.slice(-1).pop()!.id === selectedSection.id) {
                updateQuote(formikBag.values, false, true, finish, () => setCalculateStatus(true));
                setValidationState(formikBag.errors);
                return;
              }

              const followupIntegrationNames = getFollowupNames(followupIds, qtn);

              const requiredFieldsSet = selectedSection.questions
                .filter((q) => !followupIntegrationNames.includes(q.integration_name))
                .reduce((set, question) => {
                  if (question.is_required && !question.is_hidden && !set.has(question.integration_name)) {
                    set.add(question.integration_name);
                  }
                  return set;
                }, new Set<string>());

              const isRequiredFieldEmpty = Object.keys(invalidFields).some((integrationName) =>
                requiredFieldsSet.has(integrationName),
              );

              const isModifiedRequiredFieldsEmpty = Array.from(requiredFieldsSet)
                .filter((item) => !item.includes("amount") && !item.includes("term"))
                .some((item) => Object.keys(invalidFields).includes(item));

              // Hard validation fields block next step

              if (Object.keys(invalidFields).includes("email") && !!formikBag.values["email"]) {
                formikBag.validateForm().then((errors) => {
                  if (errors["email"]) {
                    let emailFieldError = errors["email"];

                    if (Array.isArray(emailFieldError) && emailFieldError.length) {
                      emailFieldError = emailFieldError.join(", ");
                    }

                    formikBag.setFieldError("email", emailFieldError as string);
                    formikBag.setFieldTouched("email", true, false);
                    setSectionErrors(["email"]);
                  }
                });
              }

              if (Object.keys(invalidFields).includes("amount") || Object.keys(invalidFields).includes("term")) {
                setIsNextStepModalOpened(false);
                // return;
              }

              if (isMobile && isModifiedRequiredFieldsEmpty) {
                setIsNextStepModalOpened(true);
                return;
              }

              if (isRequiredFieldEmpty && !isMobile) {
                setIsNextStepModalOpened(true);
                return;
              }

              sendSubmitRequest(finish);
            };

            function updateFormikState() {
              const isEmailHasUpperCase = formikBag?.values["email"] && /[A-Z]/.test(formikBag?.values["email"]);
              if (formikBag?.values["email"] && isEmailHasUpperCase) {
                formikBag.setFieldValue("email", formikBag.values["email"].toLowerCase());
              }
              dispatch(saveLastFormikState(formikBag.values));
            }

            const updateSectionProgress = (values: any) => {
              dispatch(updSectionProgress(values));
            };

            return (
              <Form id={String(selectedSection.id)}>
                <QtnForm
                  clearValue={clearValue}
                  updatedFollowupIds={updatedFollowupIds}
                  renderQuestionByType={renderQuestionByType}
                  followupIds={followupIds}
                  setSectionErrors={setSectionErrors}
                  sectionErrors={sectionErrors}
                  lastValidationState={lastValidationState}
                  updateSectionErrors={updateSectionErrors}
                  {...formikBag}
                />
                <QtnActions
                  activeSectionIdx={activeSectionIdx}
                  submitAndGoBack={submitAndGoBack}
                  validateAndSubmit={validateAndSubmit}
                  next={next}
                  sectionProgress={sectionProgress}
                  saveDraftQuote={saveDraftQuote}
                  handleChangeQuestion={formikBag.handleChange}
                  questionnareError={formikBag.errors}
                  cleareFormError={(field: string) => formikBag.setFieldError(field, "")}
                  updateFormikState={updateFormikState}
                  mainFormikBag={formikBag}
                  updateSectionProgress={updateSectionProgress}
                />
                <SaveAnswersModal
                  isNextStepModalOpened={isNextStepModalOpened}
                  setIsNextStepModalOpened={setIsNextStepModalOpened}
                  sendSubmitRequest={sendSubmitRequest}
                />
                <QtnFormikValuesWatcher formikUpdEffect={updateFormikState} />
              </Form>
            );
          }}
        </Formik>
      </div>
    )
  );
};

export default QtnSection;
