import { call, put, takeLatest } from "redux-saga/effects";
import sagaAssessor from "../../../utils/sagaAssessor";
import { getProducts, getAdvisor, getOrganizations, getOrganizationByUserEmail } from "./actions";
import api from "../api";

const fetchProducts = ({
  payload: { isTesting, uniqLink, integration_name, type },
}: {
  payload: { isTesting?: boolean; uniqLink?: string; integration_name?: string; type?: number };
}) =>
  sagaAssessor(
    () =>
      function* () {
        //@ts-ignore
        const data = yield call(api.fetchProducts, !!isTesting, uniqLink, integration_name, type);
        yield put(getProducts.success(data));
      },
    getProducts.failure,
  );

const fetchAdvisor = ({ payload: { personal_code } }: { payload: { personal_code: string } }) =>
  sagaAssessor(
    () =>
      function* () {
        const { id } = yield call(api.fetchAdvisor, personal_code);
        yield put(getAdvisor.success(id));
      },
    getAdvisor.failure,
  );

const fetchOrganizations = () =>
  sagaAssessor(
    () =>
      function* () {
        //@ts-ignore
        const { data } = yield call(api.fetchOrganizations);
        yield put(getOrganizations.success(data));
      },
    getOrganizations.failure,
  );

const fetchOrganizationByUserEmail = ({ payload }: { payload: { email: string } }) =>
  sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const response = yield call(api.fetchOrganizationByUserEmail, payload.email);
        yield put(getOrganizationByUserEmail.success(response));
      },
    getOrganizationByUserEmail.failure,
  );

export default function* productWatcher() {
  yield takeLatest(getProducts.request, fetchProducts);
  yield takeLatest(getOrganizations.request, fetchOrganizations);
  yield takeLatest(getAdvisor.request, fetchAdvisor);
  yield takeLatest(getOrganizationByUserEmail.request, fetchOrganizationByUserEmail);
}
