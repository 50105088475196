import React, { FC } from "react";
import { FormikErrors } from "formik";

interface ISaveAndContinueModalInputProps {
  id: string;
  handleChangeField: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label: string | React.ReactNode;
  error?: string | false | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  type?: string;
  placeholder?: string;
}

export const SaveAndContinueModalInput: FC<ISaveAndContinueModalInputProps> = (props) => {
  const { id, handleChangeField, value, label, error, type, placeholder } = props;
  return (
    <div className="form-field">
      <label htmlFor={id}>{label}</label>
      <input
        onChange={handleChangeField}
        value={value || ""}
        name={id}
        id={id}
        type={type || "text"}
        placeholder={placeholder || ""}
      />
      <div className="form-field-error">{error}</div>
    </div>
  );
};

export default SaveAndContinueModalInput;
