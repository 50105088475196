export enum PRODUCT_TYPES {
  GENERAL_LOAN = "General loan",
  CONSUMER_LOAN = "Consumer loan",
  MORTGAGE = "Mortgage",
}

export enum PRODUCT_TYPES_ENUM {
  CONSUMER_LOAN = 1,
  GENERAL_LOAN,
  MORTGAGE,
}

export enum CONSUMER_LOAN_TYPES_OPTIONS {
  DISBURSEMENT = 1,
  CREDIT_BALANCE,
}

export enum QUESTION_FIELDS_POPULATED {
  AMOUNT = "amount",
  TERM = "term",
  TOTAL_FEES = "total_fees",
}

export enum DEVICE_WIDTH {
  SMALL_TABLET = 911,
}
