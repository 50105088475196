import { call, put, takeLatest, select } from "redux-saga/effects";
import { AuthActionTypes } from "./constants";
import { checkUserEmail, checkUserQuote, verifyUserEmail } from "./actions";
import { AnyAction } from "redux";
import { notificationActions } from "../../Notifications/store/actions";
import { startLoader, stopLoader } from "../../../shared/components/common/Loader/store/actions";
import { getProductQtn } from "../../Qtn/store/selectors";
import { IProductQtn } from "../../Qtn/interfaces";
import { QUOTE_TYPES } from "../../Qtn/constants";

import api from "../api";

function* checkEmail({ payload }: AnyAction) {
  try {
    yield call(api.checkEmail, payload);
  } catch ({ message }) {
    // @ts-ignore
    yield put(checkUserEmail.failure({ checkEmail: message }));
  }
}

function* verifyEmail({ payload }: AnyAction) {
  try {
    yield put(startLoader());
    yield call(api.verifyEmail, payload);
    yield put(
      notificationActions.success({
        title: "Great! We have sent you an email.",
        message: "Please verify your email and continue",
      }),
    );
  } catch ({ message }) {
    // @ts-ignore
    yield put(verifyUserEmail.failure({ verifyEmail: message }));
    yield put(notificationActions.error({ title: "Something went wrong" }));
  } finally {
    yield put(stopLoader());
  }
}

function* checkUserQuoteStatus({ payload }: AnyAction) {
  try {
    // @ts-ignore
    const data = yield call(api.checkUserQuote, payload);
    const qtn: IProductQtn = yield select(getProductQtn());

    if (data.user_exist) {
      const unfinishedQuote = data.quotes.some(
        (quote: any) => quote.questionnaire_id === qtn.id && quote.status === QUOTE_TYPES.STARTED,
      );

      if (unfinishedQuote) {
        yield put(
          checkUserQuote.failure({ checkUserQuote: "Looks like you already have some answers from your previous try" }),
        );
      } else {
        yield put(checkUserQuote.success(data));
      }
    }
  } catch ({ message }) {
    // @ts-ignore
    yield put(checkUserQuote.failure({ checkUserQuote: message }));
  }
}

export default function* authWatcher() {
  yield takeLatest(AuthActionTypes.CHECK_USER_EMAIL_REQUEST, checkEmail);
  yield takeLatest(AuthActionTypes.CHECK_USER_QUOTE_REQUEST, checkUserQuoteStatus);
  yield takeLatest(AuthActionTypes.VERIFY_USER_EMAIL_REQUEST, verifyEmail);
}
