import React, { useState } from "react";

import "./styles.scss";
import Button from "../../../../shared/components/styled/Button";
import ModalComponent from "../../../../shared/components/common/ModalComponent/ModalComponent";

const HELPFUL_TIPS_ITEMS = [
  "Be as accurate as possible in your responses. Submission of inaccurate information may lead to your request being declined in the verification stage.",
  "Have a good idea of your existing monthly personal expenses (other loans, rent, utilities, groceries, entertainment, etc.",
  "Please provide all information on any other savings and assets you may have.",
  "For other Loans or Mortgages, we will ask you the balance outstanding, the interest rate, and maturity date so it would be helpful to have a good idea of these off-hand. Feel free to estimate but the information should not be materially different.",
];

interface IHelpfulTipsProps {
  classInfo?: string;
}

const HelpfulTips: React.FunctionComponent<IHelpfulTipsProps> = ({ classInfo = "" }) => {
  const [showHelpfulTips, setShowHelpfulTips] = useState(false);

  return (
    <div className="helpful-tips-wrapper">
      <div className={classInfo} onClick={() => setShowHelpfulTips(true)}>
        <img src="/icons/star_green.svg" alt="star" />
        <span>Helpful Tips</span>
      </div>
      <div className="helpful-tips-modal">
        <ModalComponent show={showHelpfulTips} title="Helpful Tips before You start" imgSrc="/icons/information.svg">
          <div className="helpful-tips-content">
            <div className="helpful-tips-content_items">
              {HELPFUL_TIPS_ITEMS.map((item, index) => (
                <div key={index} className="helpful-tip">
                  <div className="tip_number">{`${index + 1}.`}</div>
                  <div className="tip_text">{item}</div>
                </div>
              ))}
            </div>

            <Button
              className="modal-button"
              aria-label="Get Pre-Approved"
              width="488"
              variant="primary-new-green"
              onClick={() => setShowHelpfulTips(false)}
            >
              Ok, got it
            </Button>
          </div>
        </ModalComponent>
      </div>
    </div>
  );
};

export default HelpfulTips;
