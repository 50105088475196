import { call, put, takeLatest, apply, race, take } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { createSocketConnection, uploadImageViaSocket, attachImageViaSocket, closeSocketConnection } from "./actions";
import io from "socket.io-client";
import configElement from "../../../../config";
import { AnyAction } from "redux";

import { startLoader, stopLoader } from "../../../../shared/components/common/Loader/store/actions";
import { WS_MSG, WS_STATUS, WS_CHANNEL, SOCKET, WS_CONNECTION_STATUS } from "../constants";
import { cookie } from "../../../../shared/utils";

interface IWSAction {
  method: string;
  args: [
    [
      {
        file?: string;
        image_url?: string;
        sessionId?: string;
        socket_id?: string;
      },
    ],
  ];
}

let socket: any;

const createSocket = () => {
  return new Promise((resolve, reject) => {
    const socket = io(`${configElement.serverUrl}/banking`, {
      path: SOCKET.PATH,
      transports: ["websocket", "pooling"],
    });

    socket.on(WS_MSG.CONNECT_ERR, reject);

    socket.on(WS_MSG.CONNECT, () => {
      // tslint:disable-next-line:no-console
      console.info(WS_STATUS.CONNECTED);
      // let missingHeartBeats = 0;
      // let needDisconnect = true;
      // socket.on(WS_MSG.HEARTBEAT, () => {
      //   missingHeartBeats = 0;
      // });
      //
      // const heartBeatInterval = setInterval(() => {
      //   if (missingHeartBeats > 1) {
      //     // tslint:disable-next-line:no-console
      //     console.info(WS_STATUS.HEARTBEAT_REJECTED);
      //     needDisconnect = false;
      //     socket.disconnect();
      //   }
      //   missingHeartBeats += 1;
      //   socket.emit(WS_MSG.HEARTBEAT);
      // }, 5000);
      //
      // socket.on(WS_MSG.DISCONNECT, () => {
      //   if (needDisconnect) {
      //     // tslint:disable-next-line:no-console
      //     console.info(WS_STATUS.DISCONECTED);
      //     return clearInterval(heartBeatInterval);
      //   }
      //
      //   // tslint:disable-next-line:no-console
      //   console.info(WS_STATUS.RECONNECT);
      //   clearInterval(heartBeatInterval);
      //   socket.connect();
      // });

      resolve(socket);
    });
  });
};

const createSocketChannel = (socket: any) => {
  return eventChannel((emit) => {
    const handler = (method: string, ...args: any) => {
      emit({
        method,
        args,
      });
    };

    socket.on(WS_CHANNEL.MESSAGE, handler);

    socket.on(WS_MSG.RECONNECT_ERR, () => {
      emit(END);
    });

    return () => {
      socket.off(WS_CHANNEL.MESSAGE, handler);
      socket.close();
    };
  });
};

function* createSocketIOConnection({ payload }: Record<string, string>) {
  try {
    socket = yield call(() => createSocket());
    yield put(
      createSocketConnection.success({
        connection: { status: WS_CONNECTION_STATUS.CONNECTED, id: socket.id },
      }),
    );
    yield socketListener();
  } catch (err) {
    const message = `[BROWSER] socket.io: ${err}`;
    // tslint:disable-next-line:no-console
    console.error(message);
    yield put(
      createSocketConnection.failure({
        message: err,
        connection: { status: WS_CONNECTION_STATUS.DISCONECTED, id: "" },
      }),
    );
  }
}

function* closeChannel() {
  socket.disconnect();
  yield put(closeSocketConnection.success({}));
}

//@ts-ignore
function* socketListener(): Generator<any> {
  const socketChannel: any = yield call(createSocketChannel, socket);
  const sessionId = cookie.readCookie("sessionId");
  while (true) {
    const [response]: any = yield race([take(socketChannel)]);
    if (response) {
      const { method, args } = response as IWSAction;
      switch (method) {
        case WS_MSG.ATTACHED:
          if (
            args.length &&
            args[0].length &&
            (args[0][0].sessionId === sessionId || args[0][0].socket_id === socket.id)
          ) {
            yield put(attachImageViaSocket.success(args[0]));
            yield put(stopLoader());
            yield call(closeChannel);
          }
          break;
        default:
          // tslint:disable-next-line:no-console
          console.warn(`Unknown event ${method}`);
      }
    }
  }
}

function* uploadMobileImage({ payload }: AnyAction) {
  try {
    yield put(startLoader());
    yield apply(socket, socket.send, [
      WS_MSG.UPLOAD,
      payload.map(({ image }: { error: string | null; image: string }) => ({ image })),
    ]);
  } catch (err) {
    yield put(uploadImageViaSocket.failure(err as string));
  } finally {
    yield put(stopLoader());
  }
}

function* attachMobileImage({ payload }: AnyAction) {
  try {
    yield put(startLoader());
    yield apply(socket, socket.send, [WS_MSG.ATTACH, payload]);
  } catch (err) {
    yield put(attachImageViaSocket.failure(err as string));
  } finally {
    yield put(stopLoader());
  }
}

export default function* uploadWatcher() {
  yield takeLatest(createSocketConnection.request, createSocketIOConnection);
  yield takeLatest(uploadImageViaSocket.request, uploadMobileImage);
  yield takeLatest(attachImageViaSocket.request, attachMobileImage);
  yield takeLatest(closeSocketConnection.request, closeChannel);
}
