import React, { ReactNode } from "react";
import classnames from "classnames";

import "./styles.scss";

interface IModalComponentProps {
  show: boolean;
  title: string;
  msg?: string;
  children?: ReactNode;
  imgSrc?: string;
  handleCloseSaveModal?: () => void;
  truncateTitleWidth?: boolean;
}

const ModalComponent: React.FunctionComponent<IModalComponentProps> = ({
  msg,
  title,
  children,
  show,
  imgSrc,
  handleCloseSaveModal,
  truncateTitleWidth,
}) => {
  return show ? (
    <div className="modal-component-container">
      <div className="modal">
        {handleCloseSaveModal ? (
          <div className="modal_remove-wrapper">
            <img className="remove" src="/icons/remove.svg" alt="Close" onClick={handleCloseSaveModal} />
          </div>
        ) : null}
        {imgSrc ? <img className="modal_image" src={imgSrc} alt="" /> : null}
        <div className={classnames("modal_title", { truncateTitleWidth })}>{title}</div>
        {msg ? <div className="modal_msg" dangerouslySetInnerHTML={{ __html: msg }} /> : null}
        <div className="modal_actions">{children}</div>
      </div>
    </div>
  ) : null;
};

export default ModalComponent;
