import React, { useState } from "react";
import "./styles.scss";
import Button from "../../../../shared/components/styled/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateQuoteStatus } from "../../../Qtn/store/actions";
import { QUOTE_TYPES } from "../../../Qtn/constants";
import configElement from "../../../../config";
import Content from "../../../../shared/components/common/Content/Content";
import Header from "../../../../shared/components/common/Header/Header";
import Loader from "../../../../shared/components/common/Loader/Loader";
import { withRouter, RouteChildrenProps, RouteComponentProps } from "react-router";
import { getSelectedProduct } from "../../../Products/store/selectors";
import { PRODUCT_TYPES_ENUM } from "../../../../shared/constants";
import ModalComponent from "../../../../shared/components/common/ModalComponent/ModalComponent";

const { ONLINE_QUOTE_STARTED, PRE_APPROVE, PRE_APPROVE_CONTINUE, FIRST_VERIFICATION } = QUOTE_TYPES;

const PRE_APPROVE_NOTES = [
  "Now that you have been pre-approved, the hardest part, we just need to verify the information submitted.",
  "You can either have someone contact you or continue to submit the information securely. ",
  "An invitation will be sent to you to submit some of the following documents: Identification, Proof of Address, Proof of Salary, etc.",
];

interface IPreApproveProps {
  match: {
    params: {
      quoteId: number | string;
    };
  };
}

const PreApprove: React.FunctionComponent<IPreApproveProps & RouteChildrenProps & RouteComponentProps> = ({
  match: {
    params: { quoteId },
  },
}) => {
  const [showModal, setModalStatus] = useState(false);
  const dispatch = useDispatch();
  const product = useSelector(getSelectedProduct());
  const setQuoteStatus = (status: number) => {
    dispatch(
      updateQuoteStatus.request({
        status,
        id: quoteId,
        callback: () => setModalStatus(true),
      }),
    );
  };

  return (
    <div className="proposal-modal-wrapper">
      <Header logo login />
      <main className="proposal-modal-success">
        <div className="proposal-body">
          <Content styleName="proposal-content-success">
            <Loader />
            <div className="pre-approve-page">
              <div className="pre-approve-page_title">What are the next steps?</div>
              <div className="pre-approve-page_notes">
                {PRE_APPROVE_NOTES.map((note, index) => (
                  <div key={index} className="note-item">
                    <div className="note-number">{`${index + 1}.`}</div>
                    <div className="note-text">{note}</div>
                  </div>
                ))}
              </div>
              <div className="pre-approve-actions">
                <Button
                  aria-label="Continue to Submit information"
                  type="button"
                  width="100%"
                  variant="primary-new-green"
                  className="form-submit"
                  onClick={() =>
                    setQuoteStatus(
                      product?.type_id === PRODUCT_TYPES_ENUM.CONSUMER_LOAN ? FIRST_VERIFICATION : PRE_APPROVE_CONTINUE,
                    )
                  }
                >
                  Continue to Submit Information
                </Button>
                <Button
                  aria-label="Please have someone contact me"
                  type="button"
                  width="100%"
                  variant="outlined-primary-new-green"
                  onClick={() =>
                    setQuoteStatus(
                      product?.type_id === PRODUCT_TYPES_ENUM.CONSUMER_LOAN ? FIRST_VERIFICATION : PRE_APPROVE,
                    )
                  }
                >
                  Please Have Someone Contact Me
                </Button>
                <Button
                  aria-label="Save and come back later"
                  type="button"
                  width="100%"
                  variant="secondary-new-green"
                  onClick={() => setQuoteStatus(ONLINE_QUOTE_STARTED)}
                >
                  Save & Come Back Later
                </Button>
              </div>
            </div>
          </Content>
          <div className="next_steps_modal">
            <ModalComponent
              show={showModal}
              imgSrc="/icons/checked_green.svg"
              title="Your account has been created"
              msg="An email has been sent to your inbox with instructions on how to set your password."
            >
              <Button
                aria-label="Go to the login page"
                type="button"
                className="form-submit"
                variant="primary-new-green"
                width="100%"
                onClick={() => window.open(configElement.maritimeUrl, "_self")}
              >
                Go to The Login Page
              </Button>
            </ModalComponent>
          </div>
        </div>
      </main>
    </div>
  );
};

export default withRouter(PreApprove);
