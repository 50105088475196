import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/resetStyles.scss";
import history from "./shared/history";
import { ConnectedRouter } from "connected-react-router";
import configureStore from "./store";
import { Provider } from "react-redux";
import "../src/i18n";

if (process.env.REACT_APP_ENV === "dev" || "develop") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true });
}

const { store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
