import React from "react";
import Button from "./../../../../shared/components/styled/Button";
import configElement from "../../../../config";

export default () => (
  <div className="uploaded-state">
    <div className="uploaded">
      <img src="/icons/checked_green.svg" alt="Uploaded" />
      <div className="uploaded_title">Photo successfully uploaded</div>
      <div className="uploaded_subtitle">
        Please return back to your web browser <br />
        to continue.
      </div>
      <Button
        onClick={() => window.open(configElement.maritimeFinancial, "_self")}
        width="100%"
        aria-label="Ok, got it"
        variant="primary-new-green"
        className="upload-photo"
      >
        Ok, Got it
      </Button>
    </div>
  </div>
);
