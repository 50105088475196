import { createSelector } from "reselect";

const selectProductQtn = (state: any) => state.qtn;

export const getProductQtn = () => createSelector(selectProductQtn, (state) => state.qtn);
export const getProductRate = () => createSelector(selectProductQtn, (state) => state.rate);
export const getSelectedSection = () => createSelector(selectProductQtn, (state) => state.selectedSection);
export const getLoanQuoteResponse = () => createSelector(selectProductQtn, (state) => state.qtnResponse);
export const getLoanQuoteOrganizationId = createSelector(
  [selectProductQtn],
  ({ qtnResponse, qtnOrganization }) => qtnResponse?.organization_id || qtnOrganization,
);
export const getQuote = () => createSelector(selectProductQtn, (state) => state.quote);
export const getQuoteOrganization = () => createSelector(selectProductQtn, (state) => state.qtnOrganization);
export const getQuoteError = () => createSelector(selectProductQtn, (state) => state.error);
export const getSectionProgress = () => createSelector(selectProductQtn, (state) => state.sectionProgress);
export const getFollowUp = () => createSelector(selectProductQtn, (state) => state.followupIds);
export const getFollowUpWithSection = () => createSelector(selectProductQtn, (state) => state.followupIdsWithSection);
export const getTableQuestionsState = () => createSelector(selectProductQtn, (state) => state.tableQuestionsState);
export const getLastFormikState = () => createSelector(selectProductQtn, (state) => state.lastFormikState);
export const getQtnInitialValues = () =>
  createSelector(selectProductQtn, (state) => {
    const { integration_name, ...qtnInitialValues } = state.qtnInitialValues;
    return qtnInitialValues;
  });
export const getQtnIntegrationType = () =>
  createSelector(selectProductQtn, (state) => state.qtnInitialValues?.integration_name);
