import React, { FC } from "react";
import Button from "./../../../../shared/components/styled/Button";

interface IPhotoEmptyStateProps {
  handleTakePhotoClick: (value: boolean) => void;
}

const PhotoEmptyState: FC<IPhotoEmptyStateProps> = (props) => {
  const { handleTakePhotoClick } = props;
  return (
    <div className="first-view">
      <img className="logo" src="/icons/photo_empty_state.svg" alt="Camera" />

      <Button
        width="100%"
        aria-label="Take Photo"
        variant="outlined-primary-new-green-dark"
        onClick={() => {
          handleTakePhotoClick(false);
        }}
      >
        <img src="/icons/phone_upload_green_new_dark.svg" alt="Camera" /> Take a Photo
      </Button>
    </div>
  );
};

export default PhotoEmptyState;
