import { ProductConsumerLoanCalculate, ProductsSelect } from "../containers/Products/containers";
import { Qtn } from "../containers/Qtn/containers";
import { Proposal } from "../containers/Proposal/containers";
import { Verify } from "../containers/Verify/containers";
import { SorryPage } from "../containers/SorryPage/containers";
import { ROUTES } from "../shared/constants";
import { PreApprove } from "../containers/Proposal/components/PreApproved";
import { ServePage } from "../containers/Proposal/components/ServePage";
import MobileUpload from "../containers/MobileUpload/components/TakePhoto/TakePhoto";
import ThanksPage from "../containers/Proposal/components/ThanksPage/ThanksPage";
import ProductCalculate from "../containers/Products/containers/ProductCalculate/ProductCalculate";

export const routesConfig = [
  {
    path: [ROUTES.MAIN, ROUTES.CONSUMER_LOAN, ROUTES.ADVISOR_CODE],
    exact: true,
    component: ProductsSelect,
    children: [],
  },
  {
    path: [ROUTES.CONSUMER_LOAN_POSSIBLE_RATE],
    exact: true,
    component: ProductConsumerLoanCalculate,
    children: [],
  },
  {
    path: [ROUTES.POSSIBLE_RATE, ROUTES.POSSIBLE_RATE_CAR, ROUTES.POSSIBLE_RATE_MORTGAGE],
    exact: true,
    component: ProductCalculate,
    children: [],
  },
  {
    path: [ROUTES.PRODUCT, ROUTES.CONSUMER_LOAN_PRODUCT],
    exact: true,
    component: Qtn,
    children: [],
  },
  {
    path: ROUTES.MOBILE_UPLOAD,
    exact: true,
    component: MobileUpload,
    children: [],
  },
  {
    path: [`${ROUTES.PROPOSAL}/:quoteId`, `${ROUTES.CONSUMER_LOAN_PROPOSAL}/:quoteId`],
    exact: true,
    component: Proposal,
    children: [
      {
        path: ROUTES.SERVE_PAGE,
        exact: true,
        component: ServePage,
        children: [],
      },
      {
        path: ROUTES.SORRY_PAGE,
        exact: true,
        component: SorryPage,
        children: [],
      },
      {
        path: ROUTES.PRE_APPROVE,
        exact: true,
        component: PreApprove,
        children: [],
      },
      {
        path: ROUTES.THANKS_PAGE,
        exact: true,
        component: ThanksPage,
        children: [],
      },
    ],
  },
  {
    path: ROUTES.VERIFY,
    exact: true,
    component: Verify,
    children: [],
  },
];

export const REDIRECT = ["activation", "reset"];
