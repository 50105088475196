import {
  CreateSocketActionTypes,
  UploadMobileImageActionTypes,
  AttachMobileImageActionTypes,
  CloseSocketActionTypes,
} from "./constants";
import { createAsyncAction } from "typesafe-actions";

export const createSocketConnection = createAsyncAction(
  CreateSocketActionTypes.CREATE_SOCKET_CONNECTION_REQUEST,
  CreateSocketActionTypes.CREATE_SOCKET_CONNECTION_SUCCESS,
  CreateSocketActionTypes.CREATE_SOCKET_CONNECTION_FAILURE,
)<undefined, any, Record<string, any>>();

export const closeSocketConnection = createAsyncAction(
  CloseSocketActionTypes.CLOSE_SOCKET_CONNECTION_REQUEST,
  CloseSocketActionTypes.CLOSE_SOCKET_CONNECTION_SUCCESS,
  CloseSocketActionTypes.CLOSE_SOCKET_CONNECTION_FAILURE,
)<undefined, any, Record<string, any>>();

export const uploadImageViaSocket = createAsyncAction(
  UploadMobileImageActionTypes.UPLOAD_MOBILE_IMAGE_REQUEST,
  UploadMobileImageActionTypes.UPLOAD_MOBILE_IMAGE_SUCCESS,
  UploadMobileImageActionTypes.UPLOAD_MOBILE_IMAGE_FAILURE,
)<any, any, string>();

export const attachImageViaSocket = createAsyncAction(
  AttachMobileImageActionTypes.ATTACH_MOBILE_IMAGE_REQUEST,
  AttachMobileImageActionTypes.ATTACH_MOBILE_IMAGE_SUCCESS,
  AttachMobileImageActionTypes.ATTACH_MOBILE_IMAGE_FAILURE,
)<any, any, string>();
