import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { checkUserQuote } from "../../../../Auth/store/actions";
import { validateEmail } from "../../../../../shared/utils/validators";
import { IQuestionProps } from "../../../interfaces";
import { useDebounce } from "../../../../../shared/hooks";

const TextQuestion: React.FunctionComponent<IQuestionProps> = ({
  updateSectionError,
  onBlur,
  integration_name,
  field: { onChange, value },
  size,
  form: { errors, touched, setFieldError },
  placeholder,
  showErrorComponent,
  internalId,
  is_required,
  disabled,
  product_id,
}) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500, 5);

  useEffect(() => {
    if (debounceSearch) {
      product_id && dispatch(checkUserQuote.request({ email: debounceSearch, product_id }));
    }
  }, [debounceSearch, dispatch, product_id]);

  const handleChange = (e: any) => {
    if (integration_name === "email") {
      dispatch(checkUserQuote.failure({}));
      validateEmail(e.target.value) && setSearch(e.target.value);
    }

    onChange(e);
    onBlur(e);
    setFieldError(integration_name, "");
    updateSectionError(integration_name);

    if (internalId !== undefined && !e.target.value && is_required) {
      setFieldError(integration_name, "This field is required");
    }
  };

  return (
    <>
      <input
        disabled={disabled}
        id={integration_name}
        name={integration_name}
        value={value[integration_name] || ""}
        className={`text-field ${size}`}
        type="text"
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleChange}
      />
      {showErrorComponent ? (
        <div className="table-error">{touched[integration_name] && errors[integration_name]}</div>
      ) : null}
    </>
  );
};

export default TextQuestion;
