import { all, fork } from "redux-saga/effects";
import { saga as productSaga } from "../containers/Products/store";
import { saga as qtnSaga } from "../containers/Qtn/store";
import { saga as authSaga } from "../containers/Auth/store";
import { saga as mobileUploadSaga } from "../containers/MobileUpload/components/store";

const sagas = [productSaga, qtnSaga, authSaga, mobileUploadSaga];

export default function* appSagas() {
  yield all(sagas.map(fork));
}
