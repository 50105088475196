import React, { ReactNode, useLayoutEffect, useRef, useState } from "react";
import classnames from "classnames";
import "./styles.scss";

interface ITooltipProps {
  children?: ReactNode;
  title: string;
  className?: string;
  scrollElementId?: string;
}

const Tooltip: React.FunctionComponent<ITooltipProps> = ({ children, title, className, scrollElementId }) => {
  const tooltipWrapper = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
  });

  useLayoutEffect(() => {
    const tooltipWrapperEl = tooltipWrapper.current;

    if (!tooltipWrapperEl) return;

    let scrollElement: HTMLElement | null;
    const rootElement = document.getElementById("root");

    const getTooltipPosition = () => {
      const rect = tooltipWrapperEl.getBoundingClientRect();

      return {
        left: rect.left + rect.width / 2,
        top: rect.top - 10,
      };
    };

    setPosition(getTooltipPosition());

    const scrolling = () => {
      setPosition(getTooltipPosition());
    };

    if (scrollElementId) {
      scrollElement = document.getElementById(scrollElementId);
      scrollElement?.addEventListener("scroll", scrolling);
    }

    rootElement?.addEventListener("scroll", scrolling);
    window.addEventListener("resize", scrolling);

    return () => {
      scrollElement?.removeEventListener("scroll", scrolling);
      rootElement?.removeEventListener("scroll", scrolling);
      window.removeEventListener("resize", scrolling);
    };
  }, [scrollElementId]);

  return (
    <div ref={tooltipWrapper} className={classnames("tooltip-wrapper", className)}>
      <div className="tooltip" style={{ ...position }}>
        {title}
      </div>
      {children}
    </div>
  );
};

export default Tooltip;
