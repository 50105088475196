import React from "react";
import { useSelector } from "react-redux";
import { getLoader } from "./store/selectors";
import "./styles.scss";

export default ({ isLoading = false }) => {
  const loading = useSelector(getLoader());
  return loading || isLoading ? (
    <div className="loader">
      <div className="spinner" />
    </div>
  ) : null;
};
