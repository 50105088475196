import React, { useCallback, useMemo } from "react";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, RouteChildrenProps, RouteComponentProps } from "react-router";
import Button from "../../../../shared/components/styled/Button";
import { getQuote } from "../../../Qtn/store/selectors";
import ProposalRateItem from "./ProposalRateItem";
import { push } from "connected-react-router";
import { ROUTES } from "../../../../shared/constants";
import { getSelectedProduct } from "../../../Products/store/selectors";

import "./styles.scss";

interface IProposalRateProps {
  match: {
    params: {
      quoteId: number | string;
    };
  };
  title: string;
  subtitle: string;
}

const ProposalRate: React.FunctionComponent<IProposalRateProps & RouteChildrenProps & RouteComponentProps> = ({
  match: {
    params: { quoteId },
  },
  title,
  subtitle,
}) => {
  const quote = useSelector(getQuote());
  const product = useSelector(getSelectedProduct());

  const dispatch = useDispatch();

  const negotiationLabel = useMemo(() => {
    return product && product.negotiation_max !== null && product.negotiation_min !== null
      ? `*Please note that this quote may be subject to a negotiation fee from ${product.negotiation_min} to ${product.negotiation_max}%`
      : "";
  }, [product]);

  const onProceedWithRateClick = useCallback(() => {
    dispatch(push(`${ROUTES.PROPOSAL}/${quoteId}${ROUTES.PRE_APPROVE}`));
  }, [dispatch, quoteId]);

  const onIwillThinkAboutItClick = useCallback(() => {
    dispatch(push(`${ROUTES.PROPOSAL}/${quoteId}${ROUTES.SERVE_PAGE}`));
  }, [dispatch, quoteId]);

  return (
    <>
      {quote && (
        <div className="proposal-rate">
          <div className="proposal-rate_title-block">
            <div className="proposal-rate_title-block_title">{title}</div>
            <div className="proposal-rate_title-block_subtitle">{subtitle}</div>
            {negotiationLabel ? <p className="negotiation_label">{negotiationLabel}</p> : null}
          </div>
          <div className="proposal-rate_rate-items-wrapper">
            <div className="rate">
              <ProposalRateItem title="Loan Term" value={`${quote.term} year${quote.term !== 1 ? "s" : ""}`} />
              <ProposalRateItem title="Rate" value={`${quote.value}%`} />
            </div>
            <div className="rate">
              <ProposalRateItem
                title="Monthly Payment"
                value={
                  <>
                    $
                    <NumberFormat
                      value={Math.round(quote.monthly_payment).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                }
              />
              <ProposalRateItem
                title="Loan Amount"
                value={
                  <>
                    $
                    <NumberFormat
                      value={Math.round(quote.amount).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                }
              />
            </div>
          </div>
          <div className="proposal-rate_rate-actions">
            <Button
              aria-label="Proceed With This Rate"
              type="button"
              variant="primary-new-green"
              width="100%"
              onClick={onProceedWithRateClick}
              className="form-submit"
            >
              Proceed With This Rate
            </Button>
            <Button
              aria-label="I Will Think About It"
              type="button"
              width="100%"
              onClick={onIwillThinkAboutItClick}
              variant="secondary-new-green"
            >
              I Will Think About It
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(ProposalRate);
