import { useFormikContext } from "formik";
import { useEffect, FC } from "react";

interface PropTypes {
  formikUpdEffect: () => void;
}

const QtnFormikValuesWatcher: FC<PropTypes> = ({ formikUpdEffect }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    formikUpdEffect && formikUpdEffect();
  }, [formikUpdEffect, values]);
  return null;
};

export default QtnFormikValuesWatcher;
