import { Reducer } from "redux";
import { ResizeActionTypes } from "./constants";
import produce from "immer";

const initialState: any = {
  isMobile: false,
};

const reducer: Reducer<any> = (state: any = initialState, action) => {
  switch (action.type) {
    case ResizeActionTypes.UPDATE_RESIZE_LISTENER:
      return produce(state, (nextState: any) => {
        nextState.isMobile = action.payload.isMobile;
      });
    default:
      return state;
  }
};

export { reducer as ResizeReducer };
