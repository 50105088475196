import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";
import { ILoanQuotePatchRequest } from "../interfaces";

interface IPayload {
  id: number | string;
  data?: any;
  query?: any;
}

export default {
  fetchProductQtn: (payload: { id: number | string; sessionId: string }) =>
    request(METHODS.GET, API.FETCH_PRODUCT_QTN(payload))(),
  saveLoanQuote: (payload: IPayload) => request(METHODS.POST, API.SAVE_LOAN_QUOTE(payload.id))(payload.data),
  fetchQuoteBySession: (sessionId: string) => request(METHODS.GET, API.FETCH_LOAN_QUOTE_BY_SESSION(sessionId))(),
  updateLoanQuote: (payload: IPayload) =>
    request(METHODS.PUT, API.UPDATE_LOAN_QUOTE(payload.id))(payload?.data || {}, { params: { ...payload.query } }),
  patchLoanQuote: ({ id, sendEmail, ...fields }: ILoanQuotePatchRequest & { sendEmail?: boolean }) =>
    request(METHODS.PATCH, API.PATCH_LOAN_QUOTE(id))(fields, { params: { sendEmail } }),
  calculateLoanQuote: (payload: IPayload) => request(METHODS.PATCH, API.CALCULATE_QUOTE(payload.id))(payload.data),
  updateQuoteStatus: (payload: IPayload) => request(METHODS.PATCH, API.UPDATE_QUOTE_STATUS(payload))({}),
  fetchQuoteById: (payload: IPayload) => request(METHODS.GET, API.FETCH_LOAN_QUOTE_BY_ID(payload))(),
  removeFile: (payload: any) => request(METHODS.PUT, API.REMOVE_FILE)(payload),
};
