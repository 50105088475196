import { cookie } from "../utils";
import configElement from "../../config";

export const BASE_HOST = configElement.serverUrl;
export const BASE_URL = `${BASE_HOST}/api`;

export default {
  FETCH_PRODUCTS: (isTesting: boolean, uniqLink?: string, integration_name?: string, type?: number) => {
    return `${BASE_URL}/products?order=order&direction=asc${isTesting ? "&testing=true" : ""}${
      type ? "&type=" + type : ""
    }${uniqLink ? "&uniqLink=" + uniqLink : ""}${integration_name ? "&integration_name=" + integration_name : ""}`;
  },
  FETCH_ADVISOR: (personal_code: string) => `${BASE_URL}/users/${personal_code}/advisor`,
  FETCH_ORGANIZATIONS: () => `${BASE_URL}/organization?order=desc&page=1&size=100&status=true`,
  FETCH_PRODUCT_QTN: (payload: { id: number | string; sessionId: string }) =>
    `${BASE_URL}/products/${payload.id}/questionnaire?session=${payload.sessionId || cookie.readCookie("sessionId")}`,
  SAVE_LOAN_QUOTE: (id: number | string) => `${BASE_URL}/quotes/${id}/quote?session=${cookie.readCookie("sessionId")}`,
  FETCH_LOAN_QUOTE_BY_SESSION: (sessionId: string) =>
    `${BASE_URL}/quotes/session/${sessionId || cookie.readCookie("sessionId")}`,
  UPDATE_LOAN_QUOTE: (id: number | string) => `${BASE_URL}/quotes/${id}?session=${cookie.readCookie("sessionId")}`,
  PATCH_LOAN_QUOTE: (id: number | string) => `${BASE_URL}/quotes/${id}`,
  CALCULATE_QUOTE: (id: number | string) => {
    const url = `${BASE_URL}/quotes/${id}/calculate?session=${cookie.readCookie("sessionId")}`;
    return sessionStorage.getItem("consumerLoan") ? url.concat(`&consumer=1`) : url;
  },
  UPDATE_QUOTE_STATUS: (payload: any) => `${BASE_URL}/quotes/${payload.id}/status?status=${payload.status}`,
  FETCH_LOAN_QUOTE_BY_ID: (payload: any) =>
    `${BASE_URL}/quotes/${payload.id}?session=${cookie.readCookie("sessionId")}`,
  CHECK_EMAIL: (payload: { [key: string]: string | number }) =>
    `${BASE_URL}/users/check-email?email=${encodeURIComponent(payload.email.toString().toLowerCase())}`,
  CHECK_USER_QUOTE: (payload: { [key: string]: string | number | null }) =>
    `${BASE_URL}/users/check-quote/product/${payload.product_id}?email=${encodeURIComponent(
      payload.email?.toString().toLowerCase() as string,
    )}`,
  VERIFY_EMAIL: (payload: { [key: string]: string | number | null }) =>
    `${BASE_URL}/users/verify-email/product/${payload.product_id}?email=${encodeURIComponent(
      payload.email?.toString().toLowerCase() as string,
    )}`,
  REMOVE_FILE: `${BASE_URL}/utils/remove-file`,
  FETCH_ORGANIZATION_BY_USER_EMAIL: (email: string) => `${BASE_URL}/organization/user-email/${email}`,
};
