import React from "react";
import { INotification } from "../../interface";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

const NotistackContent: React.FunctionComponent<INotification> = ({ title, message, variant }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSuccess = variant === "success";

  return (
    <div className={isSuccess ? classes.notificationContentSuccess : classes.notificationContent}>
      {isSuccess ? <img src={"/icons/checked_green_white.svg"} alt="Sucess" className={classes.successImage} /> : null}
      <div className={classes.contentWrapper}>
        <div className={classes.title}>{t(title)}</div>
        <div className={classes.message}>{t(message || "")}</div>
      </div>
    </div>
  );
};

export default NotistackContent;
