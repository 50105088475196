export enum WS_MSG {
  UPLOAD = "upload",
  UPLOADED = "uploaded",
  ATTACH = "attach",
  ATTACHED = "attached",
  HEARTBEAT = "heartbeat",
  DISCONNECT = "disconnect",
  CONNECT = "connect",
  CONNECT_ERR = "connect_error",
  RECONNECT_ERR = "reconnect_failed",
}

export enum WS_STATUS {
  CONNECTED = "[BROWSER] socket.io: Socket was connected",
  HEARTBEAT_REJECTED = "[BROWSER] socket.io: Heartbeat wasn't received from the server. Disconnecting...",
  DISCONECTED = "[BROWSER] socket.io: Socket was disconnected",
  RECONNECT = "[BROWSER] socket.io: Socket was disconnected. Trying to reconnect...",
}

export enum WS_CHANNEL {
  MESSAGE = "message",
}

export enum SOCKET {
  PATH = "/rtc",
}

export enum WS_CONNECTION_STATUS {
  DISCONECTED = 0,
  CONNECTED,
}
