import React from "react";
import { ITakenFilesState } from "../interfaces";

interface IPhotoSlides {
  currentSlide: number;
  handleLeftArrow: () => void;
  handleRightArrow: () => void;
  handleSlideScroll: (e: any) => void;
  files: ITakenFilesState[];
}

export default ({ files, handleSlideScroll }: IPhotoSlides) => (
  <div className="slider">
    <div className="slides" onScroll={handleSlideScroll}>
      {files.map((item, index) => (
        <div
          id={`slide-${index}`}
          key={item.uniq_id}
          className="slide"
          style={{ backgroundImage: `url(${item.image})` }}
        >
          {item.error ? <div className="error">{item.error}</div> : null}
        </div>
      ))}
    </div>
  </div>
);
