import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep, uniqBy } from "lodash";
import { FieldInputProps, FormikProps } from "formik";
import MobileDetect from "mobile-detect";
import { v4 as uuidv4 } from "uuid";

import QrModal from "../../../../../shared/components/common/QrModal/QrModal";
import Button from "../../../../../shared/components/styled/Button";
import configElement from "../../../../../config";
import fileView from "../../../../../shared/utils/fileView";
import { validateFileSize, validateFileType, removeAllWithoutlLast } from "../../../../../shared/utils/validators";
import { getLoanQuoteResponse } from "../../../store/selectors";
import { IQtnResponse } from "../../../interfaces";
import { getFilesFromWS, getSocketConnectionStatus } from "../../../../MobileUpload/components/store/selectors";
import { removeUploadedFile } from "../../../store/actions";
import { cookie } from "../../../../../shared/utils";
import { timestampFileName } from "../../../../../shared/utils/uploadFileTimestamp";
import { WS_CONNECTION_STATUS } from "../../../../MobileUpload/components/constants";
import { closeSocketConnection, createSocketConnection } from "../../../../MobileUpload/components/store/actions";

import "./styles.scss";

interface IAttachFile {
  updateSectionError: Function;
  size: string;
  integration_name: string;
  onBlur: Function;
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  placeholder?: string;
  id: number | null;
}

interface IFileFromWS {
  name: string;
  image: string;
  uniq_id?: string;
}

const AttachFileQuestion: React.FunctionComponent<IAttachFile> = ({
  updateSectionError,
  size,
  integration_name,
  onBlur,
  field: { value },
  form: { setFieldError, setFieldValue, setFieldTouched },
  placeholder,
  id,
}) => {
  const qtnResponse: IQtnResponse = useSelector(getLoanQuoteResponse());
  const files = useSelector(getFilesFromWS());
  const socketConnectionStatus = useSelector(getSocketConnectionStatus());
  const [answerId, setAnswerId] = useState<number | null>(null);
  const [showModal, setModalStatus] = useState<boolean>(false);
  const [socketSessionForCurrentField, setSocketSessionForCurrentField] = useState<boolean>(false);
  const [isInitialized, setInitStatus] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socketConnectionStatus === WS_CONNECTION_STATUS.DISCONECTED && showModal) {
      setSocketSessionForCurrentField(false);
      setModalStatus(false);
    } else if (socketConnectionStatus === WS_CONNECTION_STATUS.CONNECTED && socketSessionForCurrentField) {
      setModalStatus(true);
    }
    return () => {};
  }, [socketConnectionStatus, socketSessionForCurrentField, showModal]);

  const startSocketSession = useCallback(() => {
    if (socketConnectionStatus === WS_CONNECTION_STATUS.DISCONECTED && !socketSessionForCurrentField) {
      setSocketSessionForCurrentField(true);
      dispatch(createSocketConnection.request());
    }
  }, [dispatch, socketConnectionStatus, socketSessionForCurrentField]);

  const closeSocketSession = useCallback(() => {
    if (socketConnectionStatus === WS_CONNECTION_STATUS.CONNECTED) {
      dispatch(closeSocketConnection.request());
    }
  }, [dispatch, socketConnectionStatus]);

  const md = new MobileDetect(window.navigator.userAgent);

  // TODO: No effect, need to investigate further
  useEffect(() => {
    if (files && files.length) {
      const shouldAttach: IFileFromWS[] = [...files].filter(
        (f) => f.integration_name === integration_name && f.quoteId === qtnResponse.id,
      );
      if (shouldAttach.length) {
        setFieldValue(integration_name, getFileList(shouldAttach), false);
      }
    }
    // eslint-disable-next-line
  }, [files, integration_name, setFieldValue]);

  const getFileList = (shouldAttach: IFileFromWS[]) => {
    const fileNameField = "uniq_id";

    return uniqBy(
      cloneDeep([...value[integration_name]])
        .map((v) => {
          if (v.uniq_id) {
            return v;
          } else {
            v.uniq_id = uuidv4();
            return v;
          }
        })
        .concat(
          [...shouldAttach].map((newFile) => ({
            base64_name: newFile.name,
            original_file_name: newFile.name,
            value: newFile.image,
            custom_file_name: "",
            uniq_id: newFile.uniq_id,
          })),
        ),
      fileNameField,
    ).filter((f) => f.base64_name);
  };
  // TODO: No effect, need to investigate further
  useEffect(() => {
    if (Object.keys(value).length) {
      setInitStatus(true);

      if (value[integration_name]?.value && !value[integration_name].value.includes(";base64,")) {
        qtnResponse.quote_sections.forEach((section) => {
          section.responses.forEach((res) => {
            if (res.question_id === id) {
              setAnswerId(id as number);
            }
          });
        });
      }
    }
  }, [value, integration_name, qtnResponse, id, answerId]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    clearError();
    setTouched();

    if (validateFileSize(file.size)) {
      setFieldError(integration_name, "File size is more than 50MB");
      return;
    }

    if (!validateFileType(removeAllWithoutlLast(file.name, "."))) {
      setFieldError(integration_name, "Invalid file format");
      return;
    }
    convertFileToBase64(file);
  };

  const onChangeFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError();
    setFieldValue(integration_name, [{ ...value[integration_name][0], custom_file_name: e.target.value }], false);
    setTouched();
  };

  const convertFileToBase64 = (file: File) => {
    const reader = new FileReader();

    reader.onload = function (data) {
      data &&
        data.target &&
        addNewFileItem({
          newFileString: data.target.result as string,
          base64_name: file.name,
          last_modified_at: new Date(file.lastModified),
        });
      setTouched();
    };

    reader.readAsDataURL(file);
  };

  const addNewFileItem = ({
    newFileString,
    base64_name,
    last_modified_at,
  }: {
    newFileString: string;
    base64_name: string;
    last_modified_at: Date;
  }) => {
    if (value[integration_name].length === 1 && Object.values(value[integration_name][0]).every((str) => str === "")) {
      const fieldValue = [
        {
          base64_name,
          original_file_name: timestampFileName(base64_name),
          value: newFileString,
          custom_file_name: "",
          last_modified_at,
        },
      ];
      setFieldValue(integration_name, fieldValue, false);
      onBlur({ target: { name: integration_name, value: fieldValue } });
    } else {
      // TODO
    }
  };

  const removeFile = (index: number) => {
    const removeFilePayload = {
      original_file_name: "",
      value: "",
      custom_file_name: "",
      base64_name: "",
      last_modified_at: "",
    };
    clearError();
    if (value[integration_name].length === 1) {
      setFieldValue(integration_name, [removeFilePayload], false);
    } else {
      setFieldValue(
        integration_name,
        [...value[integration_name]].filter((_: any, idx: number) => idx !== index),
        false,
      );
    }
    onBlur({ target: { name: integration_name, value: [removeFilePayload] } });
    setTouched();
    // TODO: Does not do anything
    if (value[integration_name].value && !value[integration_name].value.includes(";base64,")) {
      dispatch(removeUploadedFile({ answerId }));
    }
  };

  const handleDownload = (data: Record<string, string>) => {
    fileView({ file_url: data.value, file_name: data.original_file_name });
  };

  const clearError = () => {
    setFieldError(integration_name, "");
    updateSectionError(integration_name);
  };
  const setTouched = () => setFieldTouched(integration_name, true, false);

  const renderFileNameField = (data: any, idx?: number) =>
    !idx ? (
      <input
        placeholder={placeholder || "Select a File"}
        className={`text-field ${size} file-input`}
        disabled={!data.custom_file_name && !data.original_file_name}
        value={data.custom_file_name || data.original_file_name}
        type="text"
        onChange={onChangeFileName}
      />
    ) : null;
  const getSessionId = () => cookie.readCookie("sessionId");

  const renderSingleFileComponent = (data: any) => {
    return data.value ? (
      <>
        {renderFileNameField(data)}
        <div>
          <div
            className="attached-file"
            onClick={(e) => {
              e.preventDefault();
              handleDownload(data);
            }}
          >
            <div>{data.original_file_name || data.base64_name}</div>
            <img
              src="/icons/remove.svg"
              alt="Remove file"
              onClick={(e) => {
                e.stopPropagation();
                removeFile(0);
              }}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        {renderFileNameField(data)}
        <div className="select-file-container">
          <label className="select-file-btn left" htmlFor={`attach-file-${integration_name}`}>
            <img src="/icons/ic_upload_green_new.svg" alt="Select File" />
            <span className="upload-from-phone">Select From {!!md.mobile() ? "Phone" : "Computer"}</span>
          </label>
          <input
            hidden
            id={`attach-file-${integration_name}`}
            type="file"
            name={integration_name}
            onChange={onChangeHandler}
          />
          {md.mobile() === null || window.innerWidth > 2100 ? (
            <Button
              type="button"
              className="select-file-btn"
              aria-label="upload from phone"
              width="200"
              onClick={startSocketSession}
            >
              <img src="/icons/phone_upload_green_new.svg" alt="Select File" />
              <span className="upload-from-phone">Upload From Phone</span>
            </Button>
          ) : (
            <React.Fragment>
              <label className="select-file-btn" htmlFor={`attach-file-${integration_name}-mobile`}>
                <img src="/icons/phone_upload_green_new.svg" alt="Select File" />
                <span className="select-file">Take a Photo</span>
              </label>
              <input
                hidden
                id={`attach-file-${integration_name}-mobile`}
                type="file"
                capture="camera"
                name={integration_name}
                onChange={onChangeHandler}
              />
            </React.Fragment>
          )}
        </div>
      </>
    );
  };

  const renderFileList = () => {
    return value[integration_name].map((data: any, index: number) => {
      return (
        <div key={`${integration_name}-${index}`}>
          <div
            className="attached-file"
            onClick={(e) => {
              e.preventDefault();
              handleDownload(data);
            }}
          >
            <div>{data.original_file_name || data.base64_name}</div>
            <img src="/icons/remove.svg" alt="Remove file" onClick={() => removeFile(index)} />
          </div>
        </div>
      );
    });
  };

  const renderFileComponentList = () => {
    if (value[integration_name].length === 1) {
      const data = value[integration_name][0];
      return renderSingleFileComponent(data);
    } else {
      return renderFileList();
    }
  };

  return isInitialized ? (
    <>
      {renderFileComponentList()}
      {showModal && (
        <QrModal
          show={showModal}
          title="Scan this QR-code with your camera"
          setModalStatus={closeSocketSession}
          url={`${configElement.baseAppUrl}/mobile-upload/${getSessionId()}/question/${integration_name}`}
        />
      )}
    </>
  ) : null;
};

export default AttachFileQuestion;
