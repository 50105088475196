import { generatePath } from "react-router";

export const generatePathWithQuery = (
  path = "",
  config: { params?: { [key: string]: string }; query?: Record<string, any> },
) => {
  const { params = {}, query } = config;

  const queryObject = new URLSearchParams();

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      queryObject.set(key, String(value));
    });
  }

  return `${generatePath(path, params)}?${queryObject.toString()}`;
};
