export enum ProductActionTypes {
  FETCH_PRODUCTS_REQUEST = "@@APP/FETCH_PRODUCTS_REQUEST",
  FETCH_PRODUCTS_SUCCESS = "@@APP/FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_FAILURE = "@@APP/FETCH_PRODUCTS_FAILURE",

  SET_PRODUCT_REQUEST = "@@APP/SET_PRODUCT_REQUEST",
  SET_PRODUCT_SUCCESS = "@@APP/SET_PRODUCT_SUCCESS",
  SET_PRODUCT_FAILURE = "@@APP/SET_PRODUCT_FAILURE",

  SET_PRODUCT_SELECT_PRODUCT = "@@APP/SET_PRODUCT_SELECT_PRODUCT",

  FETCH_ADVISOR_REQUEST = "@APP/FETCH_ADVISOR_REQUEST",
  FETCH_ADVISOR_SUCCESS = "@APP/FETCH_ADVISOR_SUCCESS",
  FETCH_ADVISOR_FAILURE = "@APP/FETCH_ADVISOR_FAILURE",

  FETCH_ORGANIZATION_REQUEST = "@APP/FETCH_ORGANIZATION_REQUEST",
  FETCH_ORGANIZATION_SUCCESS = "@APP/FETCH_ORGANIZATION_SUCCESS",
  FETCH_ORGANIZATION_FAILURE = "@APP/FETCH_ORGANIZATION_FAILURE",

  FETCH_ORGANIZATION_BY_USER_EMAIL_REQUEST = "@APP/FETCH_ORGANIZATION_BY_USER_EMAIL_REQUEST",
  FETCH_ORGANIZATION_BY_USER_EMAIL_SUCCESS = "@APP/FETCH_ORGANIZATION_BY_USER_EMAIL_SUCCESS",
  FETCH_ORGANIZATION_BY_USER_EMAIL_FAILURE = "@APP/FETCH_ORGANIZATION_BY_USER_EMAIL_FAILURE",
}
