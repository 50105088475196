import React from "react";
import Button from "./../../../../shared/components/styled/Button";

interface IPhotoSlideActions {
  handleTakePhotoClick: (value: boolean) => void;
  uploadPhoto: () => void;
}

export default ({ handleTakePhotoClick, uploadPhoto }: IPhotoSlideActions) => (
  <div className="buttons">
    <Button
      onClick={uploadPhoto}
      width="auto"
      aria-label="Add More"
      variant="primary-new-green"
      className="upload-photo"
    >
      Upload Photo
    </Button>
    <Button
      width="auto"
      aria-label="Retake"
      variant="outlined-primary-new-green-dark"
      className="retake"
      onClick={() => {
        handleTakePhotoClick(true);
      }}
    >
      Retake
    </Button>
  </div>
);
