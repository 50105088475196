import React, { useState, useEffect } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Button from "../../../../shared/components/styled/Button";
import { clearQuote } from "../../../Qtn/store/actions";
import { getTableQuestionsState } from "../../../Qtn/store/selectors";
import Content from "../../../../shared/components/common/Content/Content";
import Header from "../../../../shared/components/common/Header/Header";
import { ROUTES } from "../../../../shared/constants";

import "./styles.scss";

export default () => {
  const dispatch = useDispatch();
  const lastTableFieldState = useSelector(getTableQuestionsState());

  const [firstName, setFirtsName] = useState("");

  useEffect(() => {
    const { first_name } = lastTableFieldState;
    setFirtsName(first_name);
  }, [lastTableFieldState]);

  const redirectToMain = () => {
    dispatch(push(ROUTES.MAIN));
  };

  const handleExitClick = () => {
    dispatch(clearQuote.request({ data: null, callback: redirectToMain }));
  };

  return (
    <div className="proposal-modal-wrapper">
      <Header logo login />
      <main className="proposal-modal-success">
        <div className="proposal-body">
          <Content styleName="proposal-content-success">
            <div className="sorry-page">
              <img className="sorry-page_img" src="/icons/sorry.svg" alt="Sorry" />
              <div className="sorry-page_title">Sorry{firstName && `, ${firstName}`}!</div>
              <div className="sorry-page_msg">
                <div>
                  Based on the preliminary information you provided,
                  <span className="sorry-page_msg_accent-text">
                    &nbsp;we are unable to provide you with an online pre-approval
                  </span>{" "}
                  for this loan.
                </div>
                <div>
                  <span className="sorry-page_msg_accent-text">This does not mean that we cannot provide a loan</span>,
                  but simply that we require more information from you.
                </div>
                <div>
                  We will review the information you submitted and be in touch
                  <span className="sorry-page_msg_accent-text"> within 3 working days,</span> or if you prefer you can
                  contact us at
                  <span className="sorry-page_msg_link"> loans@maritimefinancial.com</span>
                </div>
              </div>
              <div aria-label="Go to the marketing page" className="sorry-actions">
                <Button
                  type="button"
                  width="100%"
                  onClick={handleExitClick}
                  variant="primary-new-green"
                  className="form-submit"
                >
                  Go to the marketing page
                </Button>
              </div>
            </div>
          </Content>
        </div>
      </main>
    </div>
  );
};
