import React, { useCallback } from "react";
import QRCode from "qrcode.react";

import "./styles.scss";
import ModalComponent from "../ModalComponent/ModalComponent";
interface IModalProps {
  show: boolean;
  title: string;
  setModalStatus: (value: boolean) => void;
  url: string;
}
const QrModal: React.FunctionComponent<IModalProps> = ({ title, show, setModalStatus, url }) => {
  const handleCloseModel = useCallback(() => {
    setModalStatus(false);
  }, [setModalStatus]);

  return (
    <ModalComponent show={show} title={title} handleCloseSaveModal={handleCloseModel} truncateTitleWidth>
      <div className="modal-subtitle">Please do not close the pop up while uploading files.</div>
      <div className="qr-section">
        <QRCode value={url} size={224} />
      </div>
    </ModalComponent>
  );
};
export default QrModal;
