import Button from "../../../../../shared/components/styled/Button";
import ModalComponent from "../../../../../shared/components/common/ModalComponent/ModalComponent";
import React, { FC, useCallback } from "react";

import "./index.scss";

interface ISaveAnswersModalProps {
  isNextStepModalOpened: boolean;
  setIsNextStepModalOpened: (value: boolean) => void;
  sendSubmitRequest: () => void;
}

const SaveAnswersModal: FC<ISaveAnswersModalProps> = (props) => {
  const { isNextStepModalOpened, setIsNextStepModalOpened, sendSubmitRequest } = props;

  const handleRemain = useCallback(() => setIsNextStepModalOpened(false), [setIsNextStepModalOpened]);
  const handleContinue = useCallback(() => sendSubmitRequest(), [sendSubmitRequest]);

  return (
    <div className="save-answers-modal">
      <ModalComponent
        show={isNextStepModalOpened}
        imgSrc="/icons/info_icon.svg"
        title="Save answers?"
        msg="Some of the required questions were not answered. Would you like to continue or fill them later?"
      >
        <div className="modal-buttons">
          <Button aria-label="Remain" width={200} onClick={handleRemain} variant="secondary-new-green">
            Remain
          </Button>
          <Button
            aria-label="Continue"
            width={200}
            variant="primary-new-green"
            onClick={handleContinue}
            className="form-submit"
          >
            Continue
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SaveAnswersModal;
