import { useMemo } from "react";
import { useLocation } from "react-router";

export const useHaveQuery = (query: string) => {
  const location = useLocation();

  const searchQuery = useMemo(() => new URLSearchParams(location.search), [location.search]);
  return query && searchQuery.has(query);
};

export const useQuery = (query: string) => {
  const location = useLocation();

  const searchQuery = useMemo(() => new URLSearchParams(location.search), [location.search]);
  return searchQuery.get(query) || undefined;
};
