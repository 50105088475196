import React, { useState, useRef, useEffect } from "react";
import { orderBy } from "lodash";
import classnames from "classnames";
import { ISelectQuestionProps, IQtnOption } from "../../../interfaces";
import "./styles.scss";

const DropdownQuestion: React.FunctionComponent<ISelectQuestionProps> = ({
  onBlur,
  updateSectionError,
  clearValue,
  updatedFollowupIds,
  questionChildrenIds,
  id,
  options,
  integration_name,
  field: { value, onChange },
  form: { touched, errors, setFieldError },
  size,
  showErrorComponent,
  parent,
}) => {
  const [isDrop, setDropdownStatus] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (ref && ref.current && !ref.current.contains(event.target)) {
      setDropdownStatus(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const toggleDropDown = () => setDropdownStatus(!isDrop);

  const handleSelect = (optId: number) => {
    const updatedValue = { target: { name: integration_name, value: optId } };
    updatedFollowupIds(Number(optId), options);

    onChange(updatedValue);
    updateSectionError(integration_name);
    onBlur(updatedValue);
    setFieldError(integration_name, "");
    if (!parent) {
      /**
       * Create Cleare Function for tableQuestions
       */
      clearValue(questionChildrenIds[id], integration_name);
    }
    toggleDropDown();
  };

  return (
    <>
      <div ref={ref} className={classnames(`dropdown-container ${size}`, { open: isDrop })}>
        <div className={classnames("dropdown", { open: isDrop })} onClick={toggleDropDown}>
          <div className="dropdown-selected-value">
            {!!value[integration_name]
              ? options.find((opt: IQtnOption) => opt.id === Number(value[integration_name]))?.title
              : "Choose an option"}
          </div>
          <img className={`${isDrop ? "open" : "hide"}`} src="/icons/arrow_back_green_new.svg" alt="" />
        </div>
        {isDrop ? (
          <div className={classnames(`dropdown-options ${size}`)}>
            {orderBy(options, ["order"], ["asc"]).map((opt: IQtnOption) => (
              <div onClick={() => handleSelect(opt.id)} key={opt.id} className={classnames(`option-item ${size}`)}>
                {opt.title}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {showErrorComponent ? (
        <div className="table-error">{touched[integration_name] && errors[integration_name]}</div>
      ) : null}
    </>
  );
};

export default DropdownQuestion;
