export enum AuthActionTypes {
  CHECK_USER_EMAIL_REQUEST = "@@APP/CHECK_USER_EMAIL_REQUEST",
  CHECK_USER_EMAIL_SUCCESS = "@@APP/CHECK_USER_EMAIL_SUCCESS",
  CHECK_USER_EMAIL_FAILURE = "@@APP/CHECK_USER_EMAIL_FAILURE",

  CHECK_USER_QUOTE_REQUEST = "@@APP/CHECK_USER_QUOTE_REQUEST",
  CHECK_USER_QUOTE_SUCCESS = "@APP/CHECK_USER_QUOTE_SUCCESS",
  CHECK_USER_QUOTE_FAILURE = "@APP/CHECK_USER_QUOTE_FAILURE",

  CHECK_USER_QUOTE_EXIST_REQUEST = "@@APP/CHECK_USER_QUOTE_EXIST_REQUEST",
  CHECK_USER_QUOTE_EXIST_SUCCESS = "@APP/CHECK_USER_QUOTE_EXIST_SUCCESS",
  CHECK_USER_QUOTE_EXIST_FAILURE = "@APP/CHECK_USER_QUOTE_EXIST_FAILURE",

  VERIFY_USER_EMAIL_REQUEST = "@@APP/VERIFY_USER_EMAIL_REQUEST",
  VERIFY_USER_EMAIL_SUCCESS = "@@APP/VERIFY_USER_EMAIL_SUCCESS",
  VERIFY_USER_EMAIL_FAILURE = "@@APP/VERIFY_USER_EMAIL_FAILURE",

  RESET_USER_STATE = "@@APP/RESET_USER_STATE",
}
