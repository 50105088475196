import React, { ReactNode, useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQtn, fetchLoanQuote, fetchLoanQuoteById } from "../../store/actions";
import { QtnSectionList, QtnSection } from "../../components";
import { cookie } from "../../../../shared/utils";
import Content from "../../../../shared/components/common/Content/Content";
import Header from "../../../../shared/components/common/Header/Header";
import Loader from "../../../../shared/components/common/Loader/Loader";
import "./styles.scss";
import { getAuthState } from "../../../Auth/store/selectors";
import { deviceDetect } from "../../../../utils/resizeListener/store/selectors";
import VerifyModal from "../../../../shared/components/common/VerifyModal/VerifyModal";
import { ROUTES } from "../../../../shared/constants";
import { generatePath, useParams } from "react-router";
import { push } from "connected-react-router";
import { getAdvisor } from "../../../Products/store/selectors";
import { IProductQtn } from "../../interfaces";
import { getLoanQuoteResponse, getProductQtn } from "../../store/selectors";
import { useGetEstimatedSurveyTime } from "../../../../shared/hooks";
import { ContinueWorkingWithMarketingPageModal } from "../../components/QtnModals";
import { setProductSelectProduct } from "../../../Products/store/actions";

interface IQtnProps {
  children?: ReactNode;
  match: any;
  location: any;
}

const Qtn: React.FunctionComponent<IQtnProps> = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = useSelector(deviceDetect());
  const advisorId = useSelector(getAdvisor());
  const [showModal, setModalStatus] = useState<boolean>(false);
  const [showVerifyModal, setVerifyModalStatus] = useState<boolean>(false);
  const { error } = useSelector(getAuthState());
  const [canCalculate, setCalculateStatus] = useState(false);
  const [sectionErrors, setSectionErrors] = useState<string[]>([]);
  const { consumerLink } = useParams<{ consumerLink: string }>();
  const [isQuoteLoaded, setQuoteLoaded] = useState(false);
  const quote = useSelector(getLoanQuoteResponse());

  const qtn: IProductQtn = useSelector(getProductQtn());

  const estimatedSurveyTime = useGetEstimatedSurveyTime(qtn);

  const {
    match: {
      params: { id, session },
    },
    location: { state },
  } = props;

  useEffect(() => {
    if (session) {
      cookie.writeCookie("sessionId", session, 3);
      if (isQuoteLoaded) {
        if (!!quote) {
          const { product } = quote?.questionnaire;
          dispatch(setProductSelectProduct(product));
          consumerLink
            ? dispatch(push(generatePath(ROUTES.CONSUMER_LOAN_PRODUCT, { id, consumerLink })))
            : dispatch(push(generatePath(ROUTES.PRODUCT, { id })));
        } else {
          dispatch(push(ROUTES.MAIN));
        }
      }
    }
  }, [session, dispatch, id, consumerLink, isQuoteLoaded, qtn, quote]);

  useEffect(() => {
    dispatch(getQtn.request({ id, sessionId: getSessionId() }));
  }, [id, dispatch, session]);

  useEffect(() => {
    const sessionId = getSessionId();
    if (state && state.id) {
      dispatch(fetchLoanQuoteById.request({ id: state.id, callback: () => setQuoteLoaded(true) }));
    } else if (!!sessionId) {
      dispatch(fetchLoanQuote.request({ sessionId, callback: () => setQuoteLoaded(true) }));
    } else {
      dispatch(push(ROUTES.MAIN));
    }
  }, [dispatch, state]);

  useEffect(() => {
    setModalStatus(error && error.checkEmail);
    setVerifyModalStatus(error && error.checkUserQuote);
  }, [error]);

  const getSessionId = () => cookie.readCookie("sessionId");

  const handleCloseContinueWorkingWithMarketingPageModal = useCallback(() => {
    setModalStatus(false);
  }, []);

  return (
    <main className="qtn-content">
      <Header
        logo={true}
        login={true}
        estimatedTime={estimatedSurveyTime}
        showProductSelector={!consumerLink}
        productId={id}
        showProceedPopup
      >
        <>
          Based on the information provided you will get approved subject to verification of the{" "}
          {!isMobile ? <br /> : null} information submitted
        </>
      </Header>
      <Content styleName="marginTop">
        <Loader />
        {isMobile ? (
          <QtnSectionList
            canCalculate={canCalculate}
            setCalculateStatus={setCalculateStatus}
            sectionErrors={sectionErrors}
            advisorId={advisorId}
            mobileMode
          >
            <QtnSection
              product_id={id}
              setCalculateStatus={setCalculateStatus}
              setSectionErrors={setSectionErrors}
              sectionErrors={sectionErrors}
              disabled={showModal}
            />
          </QtnSectionList>
        ) : (
          <>
            <QtnSectionList
              canCalculate={canCalculate}
              setCalculateStatus={setCalculateStatus}
              sectionErrors={sectionErrors}
              advisorId={advisorId}
            />
            <QtnSection
              product_id={id}
              setCalculateStatus={setCalculateStatus}
              setSectionErrors={setSectionErrors}
              sectionErrors={sectionErrors}
              disabled={showModal}
            />
          </>
        )}
      </Content>

      <ContinueWorkingWithMarketingPageModal
        showModal={showModal}
        onClose={handleCloseContinueWorkingWithMarketingPageModal}
      />
      <VerifyModal product_id={id} show={showVerifyModal} setModalStatus={setVerifyModalStatus} />
    </main>
  );
};

export default Qtn;
