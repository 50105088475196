import { Reducer } from "redux";
import { AttachMobileImageActionTypes, CloseSocketActionTypes, CreateSocketActionTypes } from "./constants";
import produce from "immer";
import { IFilesState } from "../interfaces";
import { WS_CONNECTION_STATUS } from "../constants";

const initialState: IFilesState = {
  files: null,
  error: null,
  loading: false,
  connection: { status: 0, id: "" },
};

const reducer: Reducer<IFilesState> = (state: IFilesState = initialState, action) => {
  switch (action.type) {
    case AttachMobileImageActionTypes.ATTACH_MOBILE_IMAGE_REQUEST:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.loading = true;
      });
    case AttachMobileImageActionTypes.ATTACH_MOBILE_IMAGE_SUCCESS:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.files = action.payload;
        nextState.loading = false;
      });

    case AttachMobileImageActionTypes.ATTACH_MOBILE_IMAGE_FAILURE:
      return produce(state, (nextState) => {
        nextState.error = action.payload;
        nextState.files = null;
        nextState.loading = false;
      });
    case CreateSocketActionTypes.CREATE_SOCKET_CONNECTION_SUCCESS:
      const {
        payload: { connection },
      } = action;
      return produce(state, (nextState) => {
        nextState.connection = connection;
      });
    case CloseSocketActionTypes.CLOSE_SOCKET_CONNECTION_SUCCESS:
      return produce(state, (nextState) => {
        nextState.connection = { status: WS_CONNECTION_STATUS.DISCONECTED, id: "" };
        nextState.loading = false;
      });
    default:
      return state;
  }
};

export { reducer as FilesReducer };
