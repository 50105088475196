import React, { ReactNode, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { RouteChildrenProps, RouteComponentProps, useParams, useRouteMatch } from "react-router";
import { isEqual } from "lodash";
import queryString from "query-string";
import { getProducts, getAdvisor } from "../../store/actions";
import { getAllProducts } from "../../store/selectors";
import Header from "../../../../shared/components/common/Header/Header";
import Loader from "../../../../shared/components/common/Loader/Loader";
import ProductCards from "../../components/ProductCards/ProductCards";
import HelpfulTips from "../../components/HelpfulTips/HelpfulTips";
import { getRoutes } from "../../../../store/selectors";
import { ROUTES } from "../../../../shared/constants";
import { clearQuote, setInitialQtnValues } from "../../../Qtn/store/actions";
import { resetUserState } from "../../../Auth/store/actions";
import { useHaveQuery } from "../../../../shared/hooks";
import { generatePathWithQuery } from "../../../../shared/utils";
import { cookie } from "../../../../shared/utils";
import { getQtnIntegrationType } from "../../../Qtn/store/selectors";
import {
  CAR_PRODUCTS_TYPE,
  DEFAULT_PRODUCTS_TYPE,
  MORTGAGE_PRODUCTS_TYPE,
  PRODUCT_TYPES,
} from "../../../../shared/utils/productHelpers";

import "./styles.scss";

interface IProductsProps {
  children?: ReactNode;
  location: {
    search?: string;
  };
}

const ProductsSelect: React.FunctionComponent<IProductsProps & RouteChildrenProps & RouteComponentProps> = (props) => {
  const {
    location: { search },
  } = props;
  const { consumerLink } = useParams<{ consumerLink: string }>();
  const filterIntegrationType = useSelector(getQtnIntegrationType(), isEqual);
  const dispatch = useDispatch();
  const route = useSelector(getRoutes());
  const productsList = useSelector(getAllProducts());
  const match = useRouteMatch();
  const isTesting = !!useHaveQuery("testing");

  const checkPersonalCode = useCallback(() => {
    if (
      ![ROUTES.POSSIBLE_RATE_CAR, ROUTES.POSSIBLE_RATE_MORTGAGE, ROUTES.POSSIBLE_RATE].includes(
        route.location.pathname,
      ) &&
      !consumerLink
    ) {
      const advisorCode = route.location.pathname.split("/").pop();
      if (advisorCode) {
        dispatch(getAdvisor.request({ personal_code: advisorCode }));
        cookie.writeCookie("personal_code", advisorCode, 30);
        dispatch(push(ROUTES.MAIN));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerLink]);

  useEffect(() => {
    if (consumerLink) {
      sessionStorage.setItem("consumerLoan", consumerLink);
    } else {
      sessionStorage.clear();
    }
    return () => {};
  }, [consumerLink]);

  useEffect(() => {
    checkPersonalCode();
    cookie.deleteCookie("employers_not_company");
  }, [checkPersonalCode]);

  useEffect(() => {
    const params = queryString.parse(decodeURI(search));

    if (params && params.email) {
      dispatch(setInitialQtnValues(params));
      localStorage.setItem("user", JSON.stringify(params));
    } else {
      localStorage.removeItem("user");
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (productsList.length <= 0) {
      dispatch(getProducts.request({ isTesting, uniqLink: consumerLink, integration_name: filterIntegrationType }));
    }
    return () => {};
  }, [dispatch, isTesting, route.location.pathname, consumerLink, filterIntegrationType, productsList.length]);

  useEffect(() => {
    if (route.location.pathname === ROUTES.MAIN) {
      dispatch(getProducts.request({ isTesting }));
    }
    return () => {};
  }, [dispatch, isTesting, route.location.pathname]);

  useEffect(() => {
    if (route.location.pathname === ROUTES.MAIN) {
      sessionStorage.removeItem("productType");
      dispatch(resetUserState());
      dispatch(
        clearQuote.request({
          data: null,
          callback: () => {
            dispatch(
              push(
                generatePathWithQuery(ROUTES.MAIN, {
                  query: isTesting ? { testing: true } : undefined,
                }),
              ),
            );
          },
        }),
      );
    }
  }, [dispatch, productsList.length, route.location.pathname, isTesting]);

  useEffect(() => {
    const { params }: any = match;
    const actionKey =
      params["advisorCode"] !== DEFAULT_PRODUCTS_TYPE ? params["advisorCode"] : sessionStorage.getItem("productType");
    switch (actionKey) {
      case CAR_PRODUCTS_TYPE:
        dispatch(getProducts.request({ isTesting, type: PRODUCT_TYPES.GENERAL_LOAN }));
        sessionStorage.setItem("productType", CAR_PRODUCTS_TYPE);
        break;
      case MORTGAGE_PRODUCTS_TYPE:
        dispatch(getProducts.request({ isTesting, type: PRODUCT_TYPES.MORTGAGE }));
        sessionStorage.setItem("productType", MORTGAGE_PRODUCTS_TYPE);
        break;

      default:
        break;
    }
    return () => {};
  }, [dispatch, isTesting, match, match.params]);

  return (
    <>
      <Header logo login showProductSelector={true} hideProductSelectorInDesktopHeader />
      <main className="product-select-wrapper">
        <Loader />
        <div className="product-wrapper">
          <div className="product-info">
            <div className="product-info__title">Please select a loan type</div>
            <HelpfulTips classInfo="product-info__helpful" />
          </div>
          <div className="product-additional-info">
            Based on the information provided you will get approved subject to verification of the information
            submitted.
          </div>
          <ProductCards products={productsList} isTesting={isTesting} consumerLink={consumerLink} />
        </div>
      </main>
    </>
  );
};

export default ProductsSelect;
