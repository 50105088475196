import React from "react";

import "./styles.scss";

interface IRangeRangeNotesProps {
  rangeLeftNotes?: string;
  rangeRightNotes?: string;
}

const RangeNotes: React.FunctionComponent<IRangeRangeNotesProps> = (props) => {
  const { rangeLeftNotes, rangeRightNotes } = props;

  return (
    <div className="range-notes">
      <div className="range-notes__left">{rangeLeftNotes}</div>
      <div className="range-notes__right">{rangeRightNotes}</div>
    </div>
  );
};

export default RangeNotes;
