import React, { useMemo } from "react";
import classnames from "classnames";

import classNames from "./styles.module.scss";

interface ISingleChoiceCollapseProps {
  label: string;
  isSelected: boolean;
  children?: React.ReactNode;

  handleSelect: () => void;
}

const SingleChoiceCollapse: React.FunctionComponent<ISingleChoiceCollapseProps> = (props) => {
  const { isSelected, handleSelect, label, children } = props;

  const isOpened = useMemo(() => isSelected && !!children, [isSelected, children]);

  return (
    <div
      className={classnames(
        [classNames.collapseChoice],
        { [classNames.opened]: isOpened },
        { [classNames.isChecked]: isSelected },
      )}
    >
      <div className={classNames.header} onClick={handleSelect}>
        <input hidden type="checkbox" checked={isSelected} readOnly />
        <img
          className={classnames([classNames.radioImage], { [classNames.none]: !isSelected })}
          src="/icons/opt_single_checked_green_new.svg"
          alt="Option"
        />
        <img
          className={classnames([classNames.radioImage], { [classNames.none]: isSelected })}
          src="/icons/opt_single_checked_empty_gray.svg"
          alt="Option"
        />

        <p className={classNames.label}>{label}</p>
      </div>
      {isOpened && <div className={classNames.body}>{children}</div>}
    </div>
  );
};

export default SingleChoiceCollapse;
