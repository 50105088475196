import React, { ReactNode } from "react";
import "./styles.scss";
import classnames from "classnames";

interface IContentProps {
  children: ReactNode;
  styleName?: string;
}

const Content: React.FunctionComponent<IContentProps> = ({ children, styleName }) => (
  <div className={classnames("content", styleName)}>{children}</div>
);

export default Content;
