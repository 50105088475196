import React, { FC } from "react";

interface IProposalRateProps {
  title: string;
  value: string | number | React.ReactNode;
}

const ProposalRateItem: FC<IProposalRateProps> = (props) => {
  const { title, value } = props;
  return (
    <div className="rate-item">
      <div className="rate-item_title">{title}</div>
      <div className="rate-item_value">{value}</div>
    </div>
  );
};

export default ProposalRateItem;
