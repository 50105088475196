import React from "react";

interface IButtonProps {
  width: string | number;
  variant?: string;
  style?: React.CSSProperties;
  shadow?: boolean;
  height?: string | number;
  onClick?: () => void;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  width,
  height = 44,
  variant,
  style,
  shadow,
  onClick,
  children,
  className,
  type,
  ...props
}) => {
  const baseStyle: React.CSSProperties = {
    height: `${height}px`,
    borderRadius: "6px",
    fontFamily: "HelveticaNeue, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19.6px",
    cursor: "pointer",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: `${width}px`,
    width: "100%",
    paddingTop: "4px",
    whiteSpace: "nowrap",
    ...style,
  };

  if (shadow) {
    baseStyle.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.13)";
  }

  switch (variant) {
    case "primary":
      baseStyle.background = "rgba(28, 77, 161, 1)";
      baseStyle.color = "#ffffff";
      baseStyle.border = "none";
      break;
    case "primary-new-green":
      baseStyle.background = "rgba(10, 31, 61, 1)";
      baseStyle.color = "#ffffff";
      baseStyle.border = "none";
      baseStyle.borderRadius = "100px";
      break;
    case "secondary-new-green":
      baseStyle.background = "transparent";
      baseStyle.color = "rgba(8, 143, 143, 1)";
      baseStyle.border = "none";
      baseStyle.borderRadius = "100px";
      break;
    case "secondary":
      baseStyle.background = "linear-gradient(94.34deg, #4ac6a4 3.68%, #34ad8c 96.01%)";
      baseStyle.color = "#ffffff";
      baseStyle.border = "none";
      break;
    case "outlined-primary":
      baseStyle.background = "#ffffff";
      baseStyle.color = "rgba(28, 77, 161, 1)";
      baseStyle.border = "2px solid rgba(28, 77, 161, 1)";
      break;
    case "outlined-primary-new-green":
      baseStyle.background = "#ffffff";
      baseStyle.color = "rgba(8, 143, 143, 1)";
      baseStyle.border = "2px solid rgba(8, 143, 143, 1)";
      baseStyle.borderRadius = "100px";
      break;
    case "outlined-primary-new-green-dark":
      baseStyle.background = "#ffffff";
      baseStyle.color = "rgba(10, 31, 61, 1)";
      baseStyle.border = "2px solid rgba(10, 31, 61, 1)";
      baseStyle.borderRadius = "100px";
      break;
    case "outlined-primary-navy":
      baseStyle.background = "#ffffff";
      baseStyle.color = "rgba(28, 77, 161, 1)";
      baseStyle.border = "2px solid rgba(28, 77, 161, 1)";
      break;
    case "outlined":
      baseStyle.background = "#ffffff";
      baseStyle.color = "#bdbdbd";
      baseStyle.border = "2px solid #bdbdbd";
      break;
    case "disabled":
      baseStyle.background = "rgba(222, 222, 222, 1)";
      baseStyle.color = "#ffffff";
      baseStyle.border = "none";
      baseStyle.cursor = "not-allowed";
      break;
    case "ghost":
      baseStyle.background = "transparent";
      baseStyle.color = "#ffffff";
      baseStyle.border = "2px solid #ffffff";
      break;
    default:
      baseStyle.background = "#ffffff";
      baseStyle.color = "rgba(28, 77, 161, 1)";
      baseStyle.border = "none";
      break;
  }

  return (
    <button style={baseStyle} onClick={onClick} className={className} type={type} {...props}>
      {children}
    </button>
  );
};

export default Button;
