import React, { useEffect, useState } from "react";
import "./styles.scss";
import { IQuestionProps } from "../../../interfaces";
import NumberFormat from "react-number-format";

const NumberQuestion: React.FunctionComponent<IQuestionProps> = ({
  updateSectionError,
  integration_name,
  is_negative_possible,
  onBlur,
  prefix,
  suffix,
  form: { touched, errors, setFieldError, setFieldValue, setFieldTouched },
  field: { value },
  size,
  placeholder,
  showErrorComponent,
  internalId,
  is_required,
}) => {
  const [numberProps, setNumberProps] = useState({});
  const fieldValue = value[integration_name];

  useEffect(
    () => {
      if (fieldValue) {
        setFieldTouched(integration_name, true, false);
        setFieldError(integration_name, "");
        updateSectionError(integration_name);
      }
      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldValue, integration_name],
  );

  useEffect(() => {
    if (prefix === "$") {
      setNumberProps({
        thousandSeparator: ",",
      });
    }
  }, [prefix]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(integration_name, e.target.value);
    setFieldTouched(integration_name, true, false);
    setFieldError(integration_name, "");
    updateSectionError(integration_name);
    onBlur(e);

    if (internalId !== undefined && !e.target.value && is_required) {
      setFieldError(integration_name, "This field is required");
    }
  };
  return (
    <>
      <div className={`number-field ${size}`}>
        {prefix ? (
          <label htmlFor={integration_name} className="number-field-prefix">
            {prefix}
          </label>
        ) : null}
        <NumberFormat
          id={integration_name}
          name={integration_name}
          value={value[integration_name] || ""}
          allowNegative={!!is_negative_possible}
          {...numberProps}
          onBlur={onBlur}
          className="field"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const formatedValue = {
              target: { name: integration_name, value: e.target.value.replace(/(\d+),(?=\d{3}(\D|$))/g, "$1") },
            };
            handleChange(formatedValue as any);
          }}
          placeholder={placeholder}
        />
        {suffix ? (
          <label htmlFor={integration_name} className="number-field-suffix">
            {suffix}
          </label>
        ) : null}
      </div>
      {showErrorComponent ? (
        <div className="table-error">{touched[integration_name] && errors[integration_name]}</div>
      ) : null}
    </>
  );
};

export default NumberQuestion;
