import * as Yup from "yup";
import { QUESTION_TYPES } from "../../../constants";
import {
  IQtnSection,
  IQtnResponse,
  IProductQtn,
  IQtnQuestion,
  IResponse,
  IQtnResponseSection,
  IFormInit,
  IQuestionsWithValue,
} from "../../../interfaces";
import * as _ from "lodash";

export const initFormik = (selectedSection: IQtnSection, qtnResponse: IQtnResponse, qtn: IProductQtn) => {
  const { TEXT, SINGLE_CHOICE, MULTIPLE_CHOISE, NUMBER, DATE, DROPDOWN, TABLE, FILE, SMART_TYPING } = QUESTION_TYPES;
  const initialValues: IFormInit = {};
  const validationSchema: IFormInit = {};

  let sectionQuestions: IQtnQuestion[] = [];
  qtn.sections.forEach((section: IQtnSection) => (sectionQuestions = [...sectionQuestions, ...section.questions]));
  const questionsWithValue: IQuestionsWithValue[] = sectionQuestions.map((question: IQtnQuestion) => {
    const updatedQuestion: IQuestionsWithValue = { ...question, value: null, option_id: null };
    if (qtnResponse && qtnResponse.quote_sections) {
      qtnResponse.quote_sections.forEach((quote_section: IQtnResponseSection) => {
        quote_section.responses.forEach((res: IResponse) => {
          if (question.id === res.question_id) {
            if (res.integration_name_prefix === null && question.question_type.type !== "table") {
              if (res.value) {
                updatedQuestion.value = res.value;
              }

              if (res.option_id && question.question_type.type !== MULTIPLE_CHOISE) {
                updatedQuestion.option_id = res.option_id;
              }

              if (res.option_id && question.question_type.type === MULTIPLE_CHOISE) {
                if (!updatedQuestion.option_id) {
                  updatedQuestion.option_id = [res.option_id];
                } else {
                  //@ts-ignore
                  updatedQuestion.option_id.push(res.option_id);
                }
              }

              updatedQuestion.resources = res.resources;
            } else {
              updatedQuestion.value = _.chain(
                quote_section.responses.filter(
                  (quoteRes: IResponse) => quoteRes.integration_name_prefix && question.id === quoteRes.question_id,
                ),
              )
                // Group the elements of Array based on `integration_name_prefix` property
                .groupBy("integration_name_prefix")
                // `key` is group's name (integration_name_prefix), `value` is the array of objects
                .map((value, key) => {
                  return { integration_name_prefix: key, [question.integration_name]: value };
                })
                .value();
            }
          }
        });
      });
    }

    return updatedQuestion;
  });

  questionsWithValue.forEach((question: IQuestionsWithValue) => {
    const name = question.integration_name;

    switch (question.question_type.type) {
      case FILE:
        const common = {
          original_file_name: Yup.string().nullable(),
          custom_file_name: Yup.string().nullable(),
          base64_name: Yup.string().nullable(),
        };

        if (question.is_required && !question.is_hidden) {
          validationSchema[name] = Yup.array().of(
            Yup.object().shape({
              ...common,
              value: Yup.string().required("This field is required"),
            }),
          );
        } else {
          validationSchema[name] = Yup.array().of(
            Yup.object().shape({
              ...common,
              value: Yup.string().nullable(),
            }),
          );
        }

        initialValues[name] =
          question.resources && question.resources.length
            ? [...question.resources].map((res) => {
                const { file_name = "", image_url = "", original_file_name = "" } = res;
                // @ts-ignore
                const initial_file_name = original_file_name || image_url.split("/").pop().split(".")[0];
                const custom_file_name = original_file_name || file_name;

                return {
                  original_file_name: initial_file_name,
                  value: image_url,
                  custom_file_name,
                  base64_name: "",
                };
              })
            : [
                {
                  original_file_name: "",
                  value: "",
                  custom_file_name: "",
                  base64_name: "",
                },
              ];
        break;
      case TEXT:
      case DROPDOWN:
      case SINGLE_CHOICE:
      case SMART_TYPING:
        const answer = getResponseValue(question);
        if (!!initialValues[name] === false) initialValues[name] = answer || "";
        const commonYupScheme = Yup.string().email("Invalid email format");

        if (question.is_required) {
          validationSchema[name] =
            name === "email"
              ? commonYupScheme.required("This field is required")
              : Yup.string().required("This field is required");
        } else {
          validationSchema[name] = name === "email" ? commonYupScheme : Yup.string();
        }
        break;
      case TABLE:
        if (question.is_required) {
          validationSchema[name] = Yup.array().min(1, "This fields are required").required("This fields are required");
        } else {
          validationSchema[name] = Yup.array();
        }
        const val = getTableResponseValue(question);
        initialValues[name] = val || [];

        val.forEach((v: any, idx: number) => {
          Object.keys(v).forEach((key) => {
            initialValues[key] = v[key];
            validationSchema[key] =
              val.length - 1 === idx ? Yup.string() : Yup.string().required("This field is required");
          });
        });
        break;
      case MULTIPLE_CHOISE:
        if (question.is_required) {
          validationSchema[name] = Yup.array().min(1, "This field is required").required("This field is required");
        } else {
          validationSchema[name] = Yup.array();
        }
        initialValues[name] = [
          ...new Set(Array.isArray(question.option_id) ? question.option_id : [question.option_id].filter(Boolean)),
        ];
        break;
      case NUMBER:
        const commonNumberYupScheme = Yup.string();
        if (question.is_required) {
          validationSchema[name] = commonNumberYupScheme.required("This field is required");
        } else {
          validationSchema[name] = commonNumberYupScheme;
        }
        initialValues[name] = getResponseValue(question) || "";
        break;
      case DATE:
        if (question.is_required) validationSchema[name] = Yup.date().required("This field is required");
        initialValues[name] = getResponseValue(question) || "";
        break;
      default:
        break;
    }
  });

  return { initialValues, validationSchema: Yup.object(validationSchema) };
};

export const getTableResponseValue = (question: IQuestionsWithValue) => {
  const { table_questions } = question;

  if (Array.isArray(question.value)) {
    return getTableFieldValue(question);
  } else {
    return getFieldValue(table_questions, true, true);
  }
};

export const getTableFieldValue = (question: IQuestionsWithValue) => {
  const { TEXT, NUMBER, DATE, DROPDOWN, FILE } = QUESTION_TYPES;
  const init: any = [];

  const { table_questions, value } = question;

  value.forEach((valAnswer: any) => {
    const initItem: any = {};

    table_questions.forEach((tQuestion: any) => {
      switch (tQuestion.question_type.type) {
        case DROPDOWN:
          const dropDownAnswer = valAnswer[question.integration_name].find(
            (v: any) => v.table_question_id === tQuestion.id,
          );

          if (dropDownAnswer) {
            initItem[
              `${dropDownAnswer.id}~${valAnswer.integration_name_prefix}~${tQuestion.integration_name}`
            ] = dropDownAnswer ? dropDownAnswer.option_id : "";
          }
          break;
        case NUMBER:
        case DATE:
        case FILE:
        case TEXT:
          const answer = valAnswer[question.integration_name].find((v: any) => v.table_question_id === tQuestion.id);

          if (answer) {
            initItem[`${answer.id}~${valAnswer.integration_name_prefix}~${tQuestion.integration_name}`] = answer
              ? answer.value
              : "";
          }

          break;
        default:
          break;
      }
    });

    init.unshift(initItem);
  });

  return init;
};

export const getFieldValue = (
  table_questions: any,
  isEmpty: boolean,
  isArray: boolean,
  prefix?: string | number | null,
) => {
  if (isEmpty) {
    return [];
  }

  const { TEXT, NUMBER, DATE, DROPDOWN, FILE } = QUESTION_TYPES;
  const init: any = {};

  table_questions.forEach((tQuestion: any) => {
    switch (tQuestion.question_type.type) {
      case DROPDOWN:
      case NUMBER:
      case DATE:
      case TEXT:
      case FILE:
        init[prefix ? `${prefix}~${tQuestion.integration_name}` : tQuestion.integration_name] = "";
        break;
      default:
        break;
    }
  });

  return isArray ? [init] : init;
};

const getResponseValue = (question: IQuestionsWithValue) => {
  return question.option_id ? question.option_id : question.value;
};
export const findFollowupQuestions = (
  selectedSection: IQtnSection,
  qtn: IProductQtn,
  qtnResponse: IQtnResponse,
  sectionProgress: any,
) => {
  let followupQuestionsId: number[] = [];
  let questionChildren: { [key: string]: number[] } = {};

  let sectionQuestions: IQtnQuestion[] = [];
  qtn.sections.forEach((section: IQtnSection) => (sectionQuestions = [...sectionQuestions, ...section.questions]));

  sectionQuestions.forEach((question: IQtnQuestion) => {
    if (question.table_questions && question.table_questions.length) {
      question.table_questions.forEach((tQuestion: any) => {
        tQuestion.options.forEach((opt: any) => {
          if (opt.followup_questions && opt.followup_questions.length) {
            const tableFollowUpIds: number[] = [];
            opt.followup_questions.forEach((followupOpt: any) => tableFollowUpIds.push(followupOpt.followup_id));
            followupQuestionsId = followupQuestionsId.concat(tableFollowUpIds);
          }
        });
      });
    }

    question.options.forEach((opt: any) => {
      if (opt.followup_questions && opt.followup_questions.length) {
        const followUpIds: number[] = [];
        opt.followup_questions.forEach((followupOpt: any) => followUpIds.push(followupOpt.followup_id));
        followupQuestionsId = followupQuestionsId.concat(followUpIds);
      }
    });
  });

  sectionQuestions.forEach((question: IQtnQuestion) => {
    questionChildren[question.id] = [];

    const startID = question.id;

    const foundQuestion = sectionQuestions.find((q) => q.id === startID);

    if (foundQuestion) {
      const optionWithFollowUp = foundQuestion.options
        .filter((opt: any) => opt.followup_questions && opt.followup_questions.length)
        .map((opt: any) => opt.followup_questions);

      const followUpIds: number[] = [];
      optionWithFollowUp.flat().forEach((followupOpt: any) => followUpIds.push(followupOpt.followup_id));

      questionChildren[question.id] = questionChildren[question.id].concat(followUpIds);
    }
  });

  /**
   * Get all follow up children question
   */

  Object.keys(questionChildren).forEach((id) => {
    questionChildren[id].forEach((followUpQuestion: number) => {
      questionChildren[id] = questionChildren[id].concat(questionChildren[followUpQuestion]);
    });
  });

  /**
   * Remove follow up questions with value
   */

  const questionList = [...sectionQuestions].flat();
  const getTableQuestionIds: number[] = questionList.filter((q) => q.question_type.type === "table").map((q) => q.id);

  if (qtnResponse) {
    qtnResponse.quote_sections.forEach((qSections) => {
      qSections.responses.forEach((res) => {
        if (
          followupQuestionsId.includes(res.question_id as number) &&
          !!res.value &&
          !getTableQuestionIds.includes(res.question_id as number)
        ) {
          followupQuestionsId = followupQuestionsId.filter((fQuestion) => fQuestion !== res.question_id);
        }
      });
    });
  }

  /**
   * Remove follow up questions if parent question has selected option
   */
  if (sectionProgress) {
    const singleChoiceQuestions: any[] = [];
    selectedSection.questions.forEach((question) => {
      if (question.question_type.type === QUESTION_TYPES.SINGLE_CHOICE) {
        singleChoiceQuestions.push(question);
      }
    });

    selectedSection.questions.forEach((question: IQtnQuestion) => {
      const value = sectionProgress[question.integration_name];
      if (value && !Array.isArray(value)) {
        singleChoiceQuestions.forEach((q) => {
          q.options.forEach((opt: any) => {
            if (opt.id === Number(value)) {
              opt.followup_questions.forEach((followupQuestion: any) => {
                if (followupQuestionsId.includes(followupQuestion.followup_id)) {
                  followupQuestionsId = followupQuestionsId.filter(
                    (fQuestion) => fQuestion !== followupQuestion.followup_id,
                  );
                }
              });
            }
          });
        });
      }
    });
  }

  return { followupQuestionsId, questionChildren };
};

export const updateValidationSchema = (
  selectedSection: IQtnSection,
  followupIds: number[],
  qtnResponse: IQtnResponse,
  qtn: IProductQtn,
) => {
  const selectedSectionCopy = { ...selectedSection };

  selectedSectionCopy.questions = selectedSectionCopy.questions.filter(
    (question) => question.is_required && !followupIds.includes(question.id),
  );
  const { validationSchema } = initFormik(selectedSectionCopy, qtnResponse, qtn);
  return validationSchema;
};
