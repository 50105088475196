import React from "react";
import RateConsumerOptions from "./RateConsumerOptions/RateConsumerOptions";

import "./styles.scss";

interface IProductConsumerRateProps {
  term: number;
  monthly_payment: number;
  total_interest: number;
  total_fees: number;
  total_amount: number;
  validateFormFields: () => void;
}

const ProductConsumerRate: React.FunctionComponent<IProductConsumerRateProps> = (props) => {
  return (
    <div className="product-consumer-rate">
      <RateConsumerOptions {...props} />
    </div>
  );
};

export default ProductConsumerRate;
