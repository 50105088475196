import { makeStyles } from "@material-ui/core";

export default makeStyles((theme: any) => ({
  notificationContent: {
    fontStyle: "normal",
    fontWeight: "normal",
    padding: "2px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    [theme.breakpoints.up("xl")]: {
      minHeight: "110px !important",
      width: "600px !important",
    },
  },
  notificationContentSuccess: {
    fontStyle: "normal",
    fontWeight: "normal",
    padding: "2px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "linear-gradient(219.73deg, #4AC6A4 3.68%, #34AD8C 96.01%) !important",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: "12px",
  },
  title: {
    lineHeight: "24px",
    fontFamily: theme.custom.fontRoboto,
    margin: 0,
    padding: 0,
    width: "315px",
    [theme.breakpoints.up("xl")]: {
      width: "500px",
      fontSize: "28px",
      lineHeight: "38px",
      marginBottom: "8px",
    },
  },
  message: {
    fontFamily: theme.custom.fontRoboto,
    lineHeight: "130%",
    margin: 0,
    padding: 0,
    [theme.breakpoints.up("xl")]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },
  successImage: {
    [theme.breakpoints.up("xl")]: {
      width: "48px",
      height: "48px",
      marginRight: "12px",
    },
  },
}));
