import React, { useState, useEffect, useRef, FunctionComponent, useCallback, useMemo, Fragment } from "react";
import { Formik, Form, Field, FormikProps, FormikValues } from "formik";
import { FORM } from "../../constants";
import ProductRate from "../ProductRate/ProductRate";
import { getAdvisor } from "../../store/selectors";
import * as actions from "../../store/actions";
import { getProductRate, getQtnInitialValues } from "../../../Qtn/store/selectors";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { setQuoteRate, createLoanQuote, fetchLoanQuote, getQtn, updateLoanQuote } from "../../../Qtn/store/actions";
import { getProductQtn, getLoanQuoteResponse, getFollowUp } from "../../../Qtn/store/selectors";
import { IProductQtn, IQtnResponse } from "../../../Qtn/interfaces";
import { ROUTES } from "../../../../shared/constants";
import { cookie } from "../../../../shared/utils";
import { removeLeadingZero, removeSeparator } from "../../../../shared/utils/validators";
import { v4 as uuidv4 } from "uuid";
import { CREDIT } from "../../constants";
import { groupAnswersBySections } from "../../../Qtn/components/QtnSection/utils";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import classnames from "classnames";
import { generatePath } from "react-router";
import { IProduct, IProductTerm } from "../../interfaces";
import { scrollToElement } from "../../../Qtn/components/QtnSection/utils";
import Recaptcha from "../../../../shared/components/common/Recaptcha/Recaptcha";
import RangeField from "../../../../shared/components/common/RangeField/RangeField";

import { getMinDownpayment, getProductPercent, isMorgageType } from "../../../../shared/utils/productHelpers";

import "./styles.scss";

const ProductLoanOptions: FunctionComponent<any> = ({ product }: { product: IProduct }) => {
  const dispatch = useDispatch();
  const advisor = useSelector(getAdvisor());
  const [initialFormState, setInitialformState] = useState<any>(FORM.RATE_OPTIONS.SCHEME);
  const [minDownpayment, setMinDownpayment] = useState(0);
  const [itemPriceExist, setItemPriceExist] = useState<number | null>(product ? product.price.value : null);
  const rate: any = useSelector(getProductRate());
  const qtn: IProductQtn = useSelector(getProductQtn());
  const qtnResponse: IQtnResponse = useSelector(getLoanQuoteResponse());
  const followupIds = useSelector(getFollowUp());
  const [termTable, setTerm] = useState<IProductTerm[]>([]);
  const [rateOpts, setRateOpt] = useState<{ [key: string]: number }>({ min: 0, max: 0 });
  const [recaptcha, setRecaptcha] = useState(false);
  const submitFormRef = useRef<Function | null>(null);
  const redirectedUserData = useSelector(getQtnInitialValues());
  const [validationSchema, setValidationSchema] = useState<{ [key: string]: any }>(Yup.object().shape({}));
  const mortgageType = useMemo(() => isMorgageType(product), [product]);
  const productPercent = useMemo(() => getProductPercent(product), [product]);
  const [initialPlaceholders, setInitialPlaceholders] = useState<any>({});

  useEffect(() => {
    setInitialPlaceholders({ ...initialFormState });
  }, [initialFormState]);

  useEffect(() => {
    if (qtn && qtn.product) {
      const {
        product: { term, loan_settings },
      } = qtn;

      setTerm(term);
      setRateOpt({ min: loan_settings.min_loan_rate, max: loan_settings.max_loan_rate });
    }
  }, [qtn]);

  const calculateMinDownpayment = useCallback(
    (price: number): number => {
      if (!price) return 0;
      const result = product && getMinDownpayment(price, parseFloat(productPercent));
      return result || 0;
    },
    [product, productPercent],
  );

  useEffect(() => {
    if (product) {
      let price = product.price.value || product.amount_min + product.downpayment_min;
      let initialDownpayment = product.downpayment_min;
      let amount = product.amount_min;
      if (mortgageType) {
        price = product.amount_min + calculateMinDownpayment(price);
        initialDownpayment = calculateMinDownpayment(price);
        price = product.amount_min + initialDownpayment;
        amount = price - initialDownpayment;
      }
      minDownpayment || setMinDownpayment(initialDownpayment);
      setInitialformState({
        ...FORM.RATE_OPTIONS.SCHEME,
        price,
        amount,
        downpayment: initialDownpayment,
        term: product.loan_settings.default_term,
      });
      setValidationSchema(
        Yup.object().shape({
          downpayment: Yup.number()
            .positive("Invalid price")
            .min(product.downpayment_min, "Invalid downpayment")
            .max(
              itemPriceExist && itemPriceExist < product.downpayment_max ? itemPriceExist : product.downpayment_max,
              "Invalid downpayment",
            )
            .test("downpayment", "Invalid downpayment", (value: any) => {
              if (mortgageType && itemPriceExist) {
                const minDownpayment = getMinDownpayment(itemPriceExist, parseFloat(getProductPercent(product)));
                return value >= minDownpayment;
              }
              return true;
            })
            .required("This field is required"),
          amount: Yup.number()
            .positive("Invalid price")
            .min(product.amount_min, "Invalid amount")
            .max(
              itemPriceExist && itemPriceExist < product.amount_max ? itemPriceExist : product.amount_max,
              "Invalid amount",
            )
            .when("price", (price: number, schema: any) => {
              return price ? schema.max(price, `Must be less than or equal to ${price}`) : schema;
            })
            .required("This field is required"),
          price: Yup.number().positive("Invalid price").required("This field is required"),
          term: Yup.number()
            .min(product.duration_min, "Invalid term")
            .max(product.duration_max, "Invalid term")
            .required("This field is required"),
        }),
      );
    }
  }, [product, itemPriceExist, calculateMinDownpayment, minDownpayment, mortgageType]);

  const loanCalc = (principal: number, rate: number, term: number) => {
    // Can use a whole number percentage or decimal
    let updatedRate = 0;
    const updatedTerm = term * 12;

    if (rate > 1) {
      updatedRate = rate * 0.01;
    } else {
      updatedRate = rate;
    }
    // Can accept term in years or months
    const monthlyRate = updatedRate / 12;
    const factor = Math.pow(monthlyRate + 1, updatedTerm);
    const numerator = monthlyRate * factor;
    const denominator = factor - 1;
    const quotient = numerator / denominator;
    const payment = principal * quotient;

    return payment.toFixed(2);
  };

  const handleSubmit = (data: any) => {
    let initial: any = [];
    qtn?.sections?.forEach((section) => {
      section.questions.forEach((question) => {
        if (question.integration_name === CREDIT.AMOUNT) {
          initial.push({
            question_id: question.id,
            section_id: section.id,
            value: data.amount,
          });
        }

        if (question.integration_name === CREDIT.EI) {
          const termPercent = termTable.find((termItem) => termItem.term === Number(data.term))?.percent || 0;

          const calculatedMaxRate = rateOpts.max + termPercent;
          const maxRate = calculatedMaxRate > rateOpts.max ? rateOpts.max : calculatedMaxRate;

          const expectedInstalment = loanCalc(data.amount, maxRate, data.term);

          initial.push({
            question_id: question.id,
            section_id: section.id,
            value: Number(expectedInstalment),
          });
        }
      });
    });

    if (qtnResponse) {
      qtnResponse?.quote_sections?.forEach((quote_section) => {
        quote_section.responses.forEach((res) => {
          initial = initial.map((init: any) => {
            if (init.question_id === res.question_id) {
              init.id = res.id as number;
            }
            return init;
          });
        });
      });
    }

    setRecaptcha(false);

    !rate ? createQuote(data, initial) : updateQuote(data, initial);
  };

  const createQuote = (data: any, initial: any) => {
    const payload = {
      qtn: [...initial],
      sections: [],
      rate: {
        ...data,
        advisor_id: advisor,
      },
      user: { ...(redirectedUserData ? redirectedUserData : {}) },
    };
    if (product) {
      dispatch(
        createLoanQuote.request({
          data: groupAnswersBySections(payload, followupIds, qtn),
          id: product.id,
          callback: () => {
            redirectAfterSaveQuote(data);
            cookie.deleteCookie("personal_code");
            dispatch(actions.getAdvisor.success(null));
          },
        }),
      );
    }
  };
  const updateQuote = (data: any, initial: any) => {
    if (product) {
      const payload = {
        qtn: [...initial].map((initanswer) => {
          return {
            ...initanswer,
            questionnaire_id: product.id,
          };
        }),
        sections: [],
        rate: data,
      };

      dispatch(
        updateLoanQuote.request({
          data: groupAnswersBySections(payload, followupIds, qtn),
          id: qtnResponse.id,
          callback: () => redirectAfterSaveQuote(data),
        }),
      );
    }
  };

  const redirectAfterSaveQuote = (data: any) => {
    redirectToQtn();
    dispatch(setQuoteRate(data));
  };

  const redirectToQtn = () => product && dispatch(push(generatePath(ROUTES.PRODUCT, { id: product.id })));

  useEffect(() => {
    const sessionId = getSessionId();

    if (!!sessionId) {
      dispatch(fetchLoanQuote.request({ sessionId }));
    } else {
      setSessionId();
    }
  }, [dispatch]);

  useEffect(() => {
    const sessionId = getSessionId();
    if (sessionId && product) {
      dispatch(getQtn.request({ id: product.id, sessionId }));
    }
  }, [product, dispatch]);

  const getSessionId = () => cookie.readCookie("sessionId");
  const setSessionId = () => cookie.writeCookie("sessionId", uuidv4(), 3);

  const isCarLoanProduct = useMemo(() => product && product.title.includes("Car Loans"), [product]);

  return (
    product && (
      <div className="loan-options">
        <div className="content-wrapper">
          {recaptcha ? (
            <Recaptcha
              onSuccess={(token) => {
                if (submitFormRef.current) {
                  //@ts-ignore
                  submitFormRef.current();
                }
              }}
            />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={rate ? rate : initialFormState}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {(props: FormikProps<FormikValues>) => {
                const { values, setFieldValue, setFieldTouched, errors, validateForm, submitForm } = props;
                const minDownpeymentLabel = mortgageType ? minDownpayment : product.downpayment_min;
                const { amount, downpayment, term, price } = values;
                submitFormRef.current = submitForm;

                const handleCkeckPriceGreaterThanValue = (value: number, field: string) => {
                  price && price >= value && updateFieldValue(price - value, field);
                };

                const updateFieldValue = async (value: number, name: string) => {
                  await setFieldValue(name, value);
                  await setFieldTouched(name, true);
                };

                const handleChangeField = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = Number(removeSeparator(e.target.value)) || initialPlaceholders[field] || 0;
                  const name = e.target.name;
                  handleCkeckPriceGreaterThanValue(value, field);
                  setFieldValue(name, value);
                  setFieldTouched(name, true, false);
                };

                const handleChangeItemPrice = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = Number(removeLeadingZero(removeSeparator(e.target.value))) || 0;
                  const name = e.target.name;

                  setItemPriceExist(value);
                  setFieldValue(name, value);
                  setFieldTouched(name, true, false);
                  const downpayment = amount ? value - amount : 0;
                  if (mortgageType) {
                    const result = calculateMinDownpayment(value);
                    setMinDownpayment(result);
                    updateFieldValue(result, field);
                    setFieldValue("amount", value - result);
                    setFieldTouched("amount", true, true);
                    return;
                  }
                  updateFieldValue(downpayment, field);
                };

                const validateFormFields = async () => {
                  const errors = await validateForm();
                  const errorsList = Object.keys(errors);
                  if (errorsList.length) {
                    scrollToElement(errorsList[0]);
                  } else {
                    setRecaptcha(true);
                  }
                };
                return (
                  product && (
                    <Form id="loan-options" className="product-wrapper">
                      <div className="product-information">
                        <div className="product-information__title">
                          For your personalised quote, please provide the required details.
                        </div>
                        <Field name="price">
                          {(field: any) => (
                            <div className="field-container">
                              <label htmlFor="price">
                                <span>Cost of Asset</span>
                                <span className="subtitle">
                                  ({isCarLoanProduct ? "e.g. car" : "e.g. house, property"})
                                </span>
                              </label>
                              <div className={classnames("field-input", { error: errors.price })}>
                                <div className={classnames("input-prefix", { error: errors.price })}>$</div>
                                <NumberFormat
                                  autoFocus
                                  {...field}
                                  name="price"
                                  allowNegative={false}
                                  allowLeadingZeros={false}
                                  thousandSeparator={true}
                                  placeholder={
                                    initialPlaceholders.price ? initialPlaceholders.price.toLocaleString() : ""
                                  }
                                  value={props.touched["price"] ? price : ""}
                                  onChange={handleChangeItemPrice("downpayment")}
                                  decimalScale={0}
                                />
                              </div>
                              <div className="input-error">{errors.price ? errors.price : null}</div>
                            </div>
                          )}
                        </Field>
                        <Field name="downpayment">
                          {(field: any) => (
                            <div className="field-container">
                              <label htmlFor="downpayment">
                                <span>Downpayment</span>
                                <span className="subtitle">
                                  {mortgageType ? (
                                    <NumberFormat
                                      {...field}
                                      value={productPercent}
                                      type="text"
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={`Min. `}
                                      suffix={"% of Cost of Asset"}
                                    />
                                  ) : (
                                    <Fragment>
                                      <NumberFormat
                                        {...field}
                                        value={minDownpeymentLabel}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={`$${String.fromCharCode(160)}`}
                                      />{" "}
                                      -{" "}
                                      <NumberFormat
                                        value={product.downpayment_max}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={`$${String.fromCharCode(160)}`}
                                      />
                                    </Fragment>
                                  )}
                                </span>
                              </label>
                              <div className={classnames("field-input", { error: errors.downpayment })}>
                                <div className={classnames("input-prefix", { error: errors.downpayment })}>$</div>
                                <NumberFormat
                                  type="text"
                                  name="downpayment"
                                  allowNegative={true}
                                  thousandSeparator={true}
                                  placeholder={
                                    initialPlaceholders.downpayment
                                      ? initialPlaceholders.downpayment.toLocaleString()
                                      : ""
                                  }
                                  value={props.touched["downpayment"] ? downpayment : ""}
                                  onChange={handleChangeField("amount")}
                                />
                              </div>
                              <div className="input-error">{errors.downpayment ? errors.downpayment : null}</div>
                            </div>
                          )}
                        </Field>
                        <Field name="term">
                          {() => (
                            <RangeField
                              title="Term"
                              min={product.duration_min}
                              max={product.duration_max}
                              onChange={(value) => {
                                setFieldValue("term", value);
                                setFieldTouched("term", true, true);
                              }}
                              placeholder={initialPlaceholders.term}
                              value={props.touched["term"] ? term : ""}
                              isSticky={true}
                              rangeLeftNotes={`${product.duration_min} year${product.duration_min > 1 ? "s" : ""}`}
                              rangeRightNotes={`${product.duration_max} year${product.duration_max > 1 ? "s" : ""}`}
                              error={errors.term}
                              needsAutocorrection={true}
                              showRange={false}
                            />
                          )}
                        </Field>
                        <Field name="amount">
                          {() => (
                            <RangeField
                              title="Loan/Mortgage Amount"
                              min={product.amount_min}
                              max={product.amount_max}
                              onChange={(value) => {
                                setFieldValue("amount", value);
                                setFieldTouched("amount", true, true);
                              }}
                              placeholder={
                                initialPlaceholders.amount ? initialPlaceholders.amount.toLocaleString() : ""
                              }
                              value={props.touched["amount"] ? amount : ""}
                              isSticky={true}
                              rangeLeftNotes={`$${product.amount_min.toLocaleString()}`}
                              rangeRightNotes={`$${product.amount_max.toLocaleString()}`}
                              prefix={`$${String.fromCharCode(160)}`}
                              error={errors.amount}
                              needsAutocorrection={false}
                              showRange={false}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="product-results">
                        <ProductRate
                          amount={amount}
                          downpayment={downpayment}
                          term={term}
                          product={product}
                          setFieldValue={setFieldValue}
                          termTable={termTable}
                          rateOpts={rateOpts}
                          hasErrors={!!Object.keys(errors).length}
                          validateFormFields={validateFormFields}
                        />
                      </div>
                    </Form>
                  )
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    )
  );
};

export default ProductLoanOptions;
