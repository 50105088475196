import { useMemo } from "react";
import { IProductQtn, IQtnQuestion, IQtnSection } from "../../containers/Qtn/interfaces";

const countRequiredFields = (questions: IQtnQuestion[]) => {
  return questions.reduce((total, question) => {
    return !question.is_hidden && question.is_required ? total + 1 : total;
  }, 0);
};

const calculateSectionTime = (section: IQtnSection) => {
  const requiredFieldsCount = countRequiredFields(section.questions);
  return requiredFieldsCount * 15;
};

const calculateTotalTime = (sections: IQtnSection[]) => {
  return sections.reduce((totalTime, section) => {
    return totalTime + calculateSectionTime(section);
  }, 0);
};

const useGetEstimatedSurveyTime = (qtn: IProductQtn) => {
  const estimatedSurveyTime = useMemo(() => {
    if (!qtn?.sections.length) return 0;

    const totalSurveyTimeInSeconds = calculateTotalTime(qtn.sections);
    return totalSurveyTimeInSeconds / 60;
  }, [qtn]);

  return estimatedSurveyTime;
};

export default useGetEstimatedSurveyTime;
