import React, { useMemo } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { loanInfoSection } from "../utils";
import { getSelectedSection } from "../../../store/selectors";
import { IQtnSection, IQtnQuestion, IQtnFormProps } from "../../../interfaces";
import QuestionField from "../../../../../shared/components/common/QuestionField/QuestionField";

const QtnForm: React.FunctionComponent<IQtnFormProps> = (props) => {
  const {
    followupIds,
    clearValue,
    updatedFollowupIds,
    renderQuestionByType,
    setSectionErrors,
    sectionErrors,
    values,
    lastValidationState,
    updateSectionErrors,
  } = props;
  const { consumerLink } = useParams<{ consumerLink: string }>();
  const selectedSection: IQtnSection = loanInfoSection(useSelector(getSelectedSection()), {
    optionEffect: props.setFieldValue,
    consumerLink,
  });

  const sectionQuestions = useMemo(() => {
    return selectedSection.questions
      .filter((question) => !question.is_hidden)
      .filter((question) => {
        if (!followupIds.includes(question.id)) {
          return true;
        }

        if (
          followupIds.includes(question.id) &&
          values[question.integration_name] &&
          values[question.integration_name].length
        ) {
          return true;
        }

        return false;
      });
  }, [followupIds, selectedSection.questions, values]);

  return (
    <div id="section-body" className="section-body">
      {sectionQuestions.map((question: IQtnQuestion) => (
        <QuestionField
          {...props}
          key={question.id}
          question={question}
          clearValue={clearValue}
          updatedFollowupIds={updatedFollowupIds}
          setSectionErrors={setSectionErrors}
          sectionErrors={sectionErrors}
          lastValidationState={lastValidationState}
          updateSectionErrors={updateSectionErrors}
        >
          {renderQuestionByType(question)}
        </QuestionField>
      ))}
    </div>
  );
};

export default QtnForm;
