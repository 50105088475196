import React, { useCallback } from "react";
import NumberFormat from "react-number-format";

import "./styles.scss";
import classnames from "classnames";
import Button from "../../../../../../shared/components/styled/Button";

interface IRateConsumerOptionsProps {
  term: number;
  monthly_payment: number;
  total_interest: number;
  total_fees: number;
  total_amount: number;
  validateFormFields: () => void;
}

const RateConsumerOptions: React.FunctionComponent<IRateConsumerOptionsProps> = (props) => {
  const { monthly_payment, total_interest, total_fees, total_amount, validateFormFields } = props;

  const handleAltRateSubmit = useCallback(() => {
    validateFormFields();
  }, [validateFormFields]);

  return (
    <div className={classnames("rate-wrapper", "consumer")}>
      <div className="rate-wrapper__title-box">
        <div className="rate-wrapper__title">Recommended Rate</div>
      </div>
      <div className="rate-option-wrapper">
        <div className="rate-option first">
          <div className="rate-option__name">Monthly Payment</div>
          <div className="rate-option__value">
            <NumberFormat
              value={monthly_payment}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`$${String.fromCharCode(160)}`}
            />
          </div>
        </div>
        <div className="rate-option">
          <div className="rate-option__name">Total Interest</div>
          <div className="rate-option__value">
            <NumberFormat
              value={total_interest}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`$${String.fromCharCode(160)}`}
            />
          </div>
        </div>
        <div className="rate-option">
          <div className="rate-option__name">Total Fees</div>
          <div className="rate-option__value">
            <NumberFormat
              value={total_fees}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`$${String.fromCharCode(160)}`}
            />
          </div>
        </div>
        <div className="rate-option">
          <div className="rate-option__name">Total Amount to Be Repaid</div>
          <div className="rate-option__value">
            {" "}
            <NumberFormat
              value={total_amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`$${String.fromCharCode(160)}`}
            />
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          aria-label="Get Approved"
          width="100%"
          variant="outlined-primary-new-green"
          onClick={handleAltRateSubmit}
        >
          Get Approved
        </Button>
      </div>
    </div>
  );
};

export default RateConsumerOptions;
