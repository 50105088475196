import { Reducer } from "redux";
import { QtnActionTypes } from "./constants";
import produce from "immer";
import { IQtnState } from "../interfaces";

const initialState: IQtnState = {
  selectedSection: null,
  sectionProgress: null,
  lastFormikState: null,
  followupIds: null,
  followupIdsWithSection: null,
  qtn: null,
  rate: null,
  error: null,
  qtnResponse: null,
  quote: null,
  qtnOrganization: null,
  qtnInitialValues: {},
  tableQuestionsState: {},
};

const reducer: Reducer<IQtnState> = (state: IQtnState = initialState, action) => {
  switch (action.type) {
    case QtnActionTypes.FETCH_QTN_SUCCESS:
      return produce(state, (nextState) => {
        nextState.error = null;
        nextState.qtn = action.payload;
        nextState.selectedSection = action.payload ? action.payload.sections[0] : null;
      });
    case QtnActionTypes.FETCH_QTN_FAILURE:
      return produce(state, (nextState) => {
        nextState.error = action.payload;
      });
    case QtnActionTypes.SET_QTN_SECTION_SUCCESS:
      return produce(state, (nextState) => {
        nextState.selectedSection = action.payload;
        nextState.error = null;
      });
    case QtnActionTypes.CREATE_LOAN_QUOTE_SUCCESS:
    case QtnActionTypes.UPDATE_LOAN_QUOTE_SUCCESS:
    case QtnActionTypes.PATCH_LOAN_QUOTE_SUCCESS:
    case QtnActionTypes.UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_SUCCESS:
      return produce(state, (nextState) => {
        nextState.qtnResponse = action.payload;
        nextState.error = null;
      });
    case QtnActionTypes.FETCH_LOAN_QUOTE_SUCCESS:
      return produce(state, (nextState) => {
        nextState.qtnResponse = action.payload;
        nextState.error = null;
      });
    case QtnActionTypes.FETCH_LOAN_QUOTE_FAILURE:
      return produce(state, (nextState) => {
        nextState.error = action.payload;
      });
    case QtnActionTypes.CALCULATE_QUOTE_SUCCESS:
      return produce(state, (nextState) => {
        nextState.quote = action.payload;
      });
    case QtnActionTypes.CALCULATE_QUOTE_FAILURE:
      return produce(state, (nextState) => {
        nextState.error = action.payload;
      });
    case QtnActionTypes.SET_QUOTE_RATE:
      return produce(state, (nextState) => {
        nextState.rate = action.payload;
        nextState.error = null;
      });
    case QtnActionTypes.UPDATE_SECTION_PROGRESS:
      return produce(state, (nextState) => {
        nextState.sectionProgress = action.payload;
      });
    case QtnActionTypes.SAVE_LAST_FROMIK_STATE:
      return produce(state, (nextState) => {
        nextState.lastFormikState = action.payload;
      });
    case QtnActionTypes.SET_QTN_INITIAL_VALUES:
      return produce(state, (nextState) => {
        nextState.qtnInitialValues = action.payload;
      });

    case QtnActionTypes.SET_QTN_ORGANIZATION:
      return produce(state, (nextState) => {
        nextState.qtnOrganization = action.payload;
      });
    case QtnActionTypes.UPDATE_FOLLOW_UP:
      return produce(state, (nextState) => {
        nextState.followupIds = action.payload;
      });
    case QtnActionTypes.UPDATE_FOLLOW_UP_WITH_SECTION:
      return produce(state, (nextState) => {
        nextState.followupIdsWithSection = action.payload;
      });
    case QtnActionTypes.UPDATE_TABLE_QESTIONS_STATE:
      return produce(state, (nextState) => {
        nextState.tableQuestionsState = action.payload;
      });
    case QtnActionTypes.CLEARE_QUOTE_AFTER_REJECT_SUCCESS:
      return produce(state, (nextState) => {
        nextState.selectedSection = null;
        nextState.sectionProgress = null;
        nextState.followupIds = null;
        nextState.followupIdsWithSection = null;
        nextState.qtn = null;
        nextState.rate = null;
        nextState.error = null;
        nextState.qtnResponse = null;
        nextState.quote = null;
        nextState.tableQuestionsState = {};
      });
    default:
      return state;
  }
};

export { reducer as QtnReducer };
