import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import jwt from "jsonwebtoken";
import { notificationActions } from "../../../Notifications/store/actions";
import { push } from "connected-react-router";
import { cookie } from "../../../../shared/utils";

interface IVerifyProps {
  match: {
    params: {
      id: number;
    };
  };
  location: {
    search: string;
  };
}

const Verify: React.FunctionComponent<IVerifyProps> = (props) => {
  const dispatch = useDispatch();

  const {
    match: {
      params: { id },
    },
    location: { search },
  } = props;

  useEffect(() => {
    if (search && search.split("token=").length) {
      const params = search.split("token=");
      const token = params[params.length - 1];

      if (token) {
        const decoded: any = jwt.decode(token);
        const { questionnaire_id } = decoded.data;

        if (decoded && decoded.exp < new Date().getTime()) {
          dispatch(push(`/product/${questionnaire_id}`));
          dispatch(notificationActions.error({ title: "Verification link is expired" }));
        } else {
          cookie.deleteCookie("sessionId");
          dispatch(
            push({
              pathname: `/product/${questionnaire_id}`,
              state: decoded ? { ...decoded.data } : {},
            }),
          );
        }
      }
    }
  }, [dispatch, search, id]);

  return null;
};

export default Verify;
