import React, { useEffect, useRef, useState, ReactNode } from "react";
import { RouteComponentProps } from "react-router";
import MobileDetect from "mobile-detect";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { v4 as uuidv4 } from "uuid";
import { fetchLoanQuote, getQtn } from "../../../Qtn/store/actions";
import { attachImageViaSocket, closeSocketConnection, createSocketConnection } from "../store/actions";
import { getLoanQuoteResponse, getProductQtn } from "../../../Qtn/store/selectors";
import { getFilesFromWS, getFilesFromWSLoader } from "../store/selectors";
import PhotosEmptyState from "./PhotosEmptyState";
import PhotoSlides from "./PhotoSlides";
import PhotoSlideActions from "./PhotoSlideActions";
import UploadedPhotoState from "./UploadedPhotoState";
import Loader from "../../../../shared/components/common/Loader/Loader";
import { IQtnResponse, IProductQtn } from "../../../Qtn/interfaces";
import { ITakenFilesState } from "../interfaces";
import {
  validateFileSize,
  validateFileType,
  removeAllWithoutlLast,
  FILE_MAX_SIZE,
} from "../../../../shared/utils/validators";
import "./styles.scss";
import Header from "../../../../shared/components/common/Header/Header";

interface IMobileUploadProps {
  children?: ReactNode;
  match: { params: { integrationName: string; sessionId: string } };
}

const MobileUpload: React.FunctionComponent<IMobileUploadProps & RouteComponentProps> = (props) => {
  const [files, setFiles] = useState<ITakenFilesState[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isRetake, setIsRetake] = useState<boolean>(false);
  const uploadFileInput = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const qtnResponse: IQtnResponse = useSelector(getLoanQuoteResponse());
  const filesUploaded = useSelector(getFilesFromWS());
  const isLoadingFile = useSelector(getFilesFromWSLoader());
  const qtn: IProductQtn = useSelector(getProductQtn());
  const [questionTitle, setQuestionTitle] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchLoanQuote.request({ sessionId: props.match.params.sessionId }));
  }, [dispatch, props.match.params.sessionId]);

  useEffect(() => {
    if (qtnResponse) {
      dispatch(getQtn.request({ id: qtnResponse.questionnaire_id, sessionId: props.match.params.sessionId }));
    }
  }, [qtnResponse, dispatch, props.match.params.sessionId]);

  useEffect(() => {
    const {
      match: {
        params: { integrationName },
      },
    } = props;

    if (qtn && !questionTitle) {
      qtn.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.integration_name === integrationName) {
            setQuestionTitle(question.title);
          }
        });
      });
    }
  }, [qtn, props, questionTitle]);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile() === null) {
      dispatch(push("/"));
    }

    let element = document.getElementById("root");
    element?.classList.add("no-scroll");

    return () => {
      element?.classList.remove("no-scroll");
    };
  }, [dispatch]);

  const uploadPhoto = () => {
    if (files && files.length) {
      dispatch(
        attachImageViaSocket.request(
          files.map((f) => ({
            ...f,
            sessionId: props.match.params.sessionId,
            quoteId: qtnResponse?.id,
          })),
        ),
      );
    }
  };

  const validateFile = (file: File) => {
    if (!validateFileType(removeAllWithoutlLast(file.name, "."))) {
      return "Invalid file format";
    }

    if (validateFileSize(file.size)) {
      return "File size is more than " + (FILE_MAX_SIZE / (1024 * 1024)).toFixed(0) + "MB";
    }

    return false;
  };

  const handleTakePhotoClick = (isRetake: boolean) => {
    setIsRetake(isRetake);
    uploadFileInput.current?.click();
  };

  const setLocationHash = (slideNumber: number) => {
    window.location.hash = ``;
    window.location.hash = `#slide-${slideNumber}`;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      match: {
        params: { integrationName },
      },
    } = props;
    const md = new MobileDetect(window.navigator.userAgent);
    const [file] = e.target.files || [];
    if (file) {
      let error: boolean | string = validateFile(file);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const reconstructedFile: ITakenFilesState = {
          image: reader.result,
          error: error ? error : null,
          integration_name: integrationName,
          name: md.is("iPhone") ? `${uuidv4()}_${file.name}` : file.name,
          last_modified: file.lastModified,
          uniq_id: uuidv4(),
        };
        if (!isRetake) {
          setFiles((prev) => [reconstructedFile, ...prev]);
          setCurrentSlide(0);
          setLocationHash(0);
        } else {
          setFiles((prev) => {
            let prevClone = [...prev];
            prevClone[currentSlide] = reconstructedFile;
            return prevClone;
          });
        }
      };
    }
  };

  const handleLeftArrow = () => currentSlide > 0 && setLocationHash(currentSlide - 1);
  const handleRightArrow = () => currentSlide < files.length - 1 && setLocationHash(currentSlide + 1);

  const handleSlideScroll = (e: any) => {
    if (e.target.scrollLeft !== 0) {
      setCurrentSlide(Math.round(e.target.scrollLeft / e.target.offsetWidth));
    } else {
      setCurrentSlide(0);
    }
  };

  useEffect(() => {
    dispatch(createSocketConnection.request());
    return () => {
      dispatch(closeSocketConnection.request());
    };
  }, [dispatch]);

  return (
    <>
      <Header logo showProductSelector={false} hideSidebar />
      <Loader isLoading={isLoadingFile} />
      <div className="take-photo">
        <div className="take-photo-content">
          {filesUploaded ? (
            <UploadedPhotoState />
          ) : !files.length ? (
            <PhotosEmptyState handleTakePhotoClick={handleTakePhotoClick} />
          ) : (
            <div className="image-viewer">
              <PhotoSlides
                currentSlide={currentSlide}
                handleSlideScroll={handleSlideScroll}
                files={files}
                handleRightArrow={handleRightArrow}
                handleLeftArrow={handleLeftArrow}
              />
              <PhotoSlideActions handleTakePhotoClick={handleTakePhotoClick} uploadPhoto={uploadPhoto} />
            </div>
          )}
          <input
            type="file"
            id="upload-file"
            accept="image/*"
            capture="camera"
            onChange={handleFileChange}
            ref={uploadFileInput}
          />
        </div>
      </div>
    </>
  );
};

export default MobileUpload;
