import { NotificationActionTypes } from "./constants";
import { action, Action } from "typesafe-actions";
import { NotificationVariants } from "../store/constants";

export type NotificationActions = { [key: string]: (notification: { title: string; message?: string }) => Action<any> };
export const notificationActions: NotificationActions = {};

NotificationVariants.forEach((variant) => {
  notificationActions[variant] = (notification: { title: string; message?: string }) =>
    action(NotificationActionTypes.ENQUEUE_SNACKBAR, {
      key: (new Date().getTime() + Math.random()).toString(),
      message: notification.message,
      title: notification.title,
      variant,
    });
});

export const removeSnackbar = (key: string) => action(NotificationActionTypes.REMOVE_SNACKBAR, key);
