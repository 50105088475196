import React from "react";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { getTrackBackground, Range } from "react-range";
import { FormikErrors } from "formik";
import RangeNotes from "./RangeNotes/RangeNotes";

import "./styles.scss";

interface IRangeFieldProps {
  title: string;
  subtitle?: string;
  note?: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: (value: number) => void;
  prefix?: string;
  suffix?: string;
  isSticky?: boolean;
  rangeLeftNotes?: string;
  rangeRightNotes?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null;
  needsAutocorrection?: boolean;
  showRange?: boolean;
}

const RangeFieldWithDotLabel: React.FunctionComponent<IRangeFieldProps> = (props) => {
  const {
    title,
    subtitle,
    note,
    onChange,
    value,
    min = 0,
    max,
    prefix,
    suffix,
    rangeLeftNotes,
    rangeRightNotes,
    error,
    step = 1,
    isSticky = false,
    showRange = true,
  } = props;

  return (
    <>
      <div className={classnames("field-container range-field-with-dot-label", { sticky: isSticky }, { error })}>
        <div className="title-wrapper">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div className="min-max-values">
          <div className="min-value">
            <NumberFormat
              value={min}
              displayType={"text"}
              thousandSeparator={true}
              prefix={prefix ? `$${String.fromCharCode(160)}` : undefined}
              suffix={suffix}
            />
          </div>
          <div className="max-value">
            <NumberFormat
              value={max}
              displayType={"text"}
              thousandSeparator={true}
              prefix={prefix ? `$${String.fromCharCode(160)}` : undefined}
              suffix={suffix}
            />
          </div>
        </div>
        {showRange ? (
          <div className={isSticky ? "field-input-sticky" : "field-input"}>
            <Range
              step={step}
              min={min}
              max={max}
              values={[value <= min ? min : value]}
              onChange={(value) => {
                onChange(value[0]);
              }}
              renderTrack={({ props, children }) => (
                <div
                  className="track"
                  {...props}
                  style={{
                    ...props.style,
                    height: isSticky ? "8px" : "8px",
                    margin: isSticky ? "0 12px" : "0 10px",
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: "#EDEDED",
                    background: getTrackBackground({
                      values: [value],
                      colors: [
                        isSticky ? (error ? "#E25969" : "#1C4DA1") : "rgba(8, 143, 143, 1)",
                        "rgba(237, 237, 237, 1)",
                      ],
                      min: min,
                      max: max,
                    }),
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <React.Fragment key="thumb">
                  <div
                    className="thumb"
                    {...props}
                    style={{
                      ...props.style,
                      height: isSticky ? "14px" : "14px",
                      width: isSticky ? "14px" : "14px",
                      borderRadius: "16px",
                      border: "3px solid rgba(8, 143, 143, 1)",
                      backgroundColor: isSticky ? "#FFFFFF" : "#FFFFFF",
                      outline: "none",
                    }}
                  >
                    <div
                      className="thumb-value"
                      style={{
                        position: "absolute",
                        bottom: "-39px",
                        left: "-45px",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "14px",
                        fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                        padding: "4px 8px 4px 8px",
                        borderRadius: "4px",
                        backgroundColor: "rgba(8, 143, 143, 1)",
                        whiteSpace: "nowrap",
                        width: "fit-content",
                      }}
                    >
                      <NumberFormat
                        value={value}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={prefix ? `$${String.fromCharCode(160)}` : undefined}
                        suffix={suffix}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>
        ) : null}
        <div className="note">{note}</div>
      </div>
      {(rangeLeftNotes || rangeRightNotes) && (
        <RangeNotes rangeLeftNotes={rangeLeftNotes} rangeRightNotes={rangeRightNotes} />
      )}
      <div className="input-error">{error ? error : null}</div>
    </>
  );
};

export default RangeFieldWithDotLabel;
