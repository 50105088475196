import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { getProductQtn, getFollowUp } from "../../../../containers/Qtn/store/selectors";
import { IProductQtn } from "../../../../containers/Qtn/interfaces";
import { useSelector } from "react-redux";
import "./styles.scss";
import { getFollowupNames } from "../../../../containers/Qtn/components/QtnSection/utils";

interface IPieChartProps {
  sectionId: number;
  progress: number;
  sectionErrors: Array<string>;
}

const PieChart: React.FunctionComponent<IPieChartProps> = ({ sectionId, progress, sectionErrors }) => {
  const [error, setError] = useState<number>(0);
  const qtn: IProductQtn = useSelector(getProductQtn());
  const followupIds = useSelector(getFollowUp() || []);

  useEffect(() => {
    const section = qtn.sections.find((section) => section.id === sectionId);
    const followupIntegrationNames = getFollowupNames(followupIds, qtn);

    if (section && sectionErrors.length) {
      const integrationNames = [...section.questions]
        .filter((question) => !question.is_hidden && question.is_required)
        .map((question) => question.integration_name);

      let hasErrors = sectionErrors.filter(
        (v: any) => integrationNames.includes(v) && !followupIntegrationNames.includes(v),
      );

      setError(hasErrors.length * 100 + progress);
    } else {
      setError(0);
    }
  }, [sectionId, qtn, progress, sectionErrors, followupIds]);

  return (
    <div className="flex-wrapper">
      <div className="single-chart">
        <svg viewBox="0 -2 40 40" className={classnames("circular-chart", { color: progress || error })}>
          <path
            className="circle-bg"
            d="M20 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle-error"
            strokeDasharray={`${error}, 100`}
            d="M20 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="circle-success"
            strokeDasharray={`${progress}, 100`}
            d="M20 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="50%" y="50%" textAnchor="middle" dy=".3em" className="progress-text">
            {progress || 0}%
          </text>
        </svg>
      </div>
    </div>
  );
};

export default PieChart;
