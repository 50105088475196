export const FORM = {};

export const QUESTION_TYPES = {
  TEXT: "text",
  SINGLE_CHOICE: "single_choice",
  MULTIPLE_CHOISE: "multiple_choice",
  NUMBER: "number",
  DATE: "date",
  DROPDOWN: "dropdown",
  TABLE: "table",
  FILE: "file",
  SMART_TYPING: "smart_typing",
};

export const QUOTE_TYPES = {
  ONLINE_QUOTE_STARTED: 10,
  PRE_APPROVE: 11,
  PRE_APPROVE_CONTINUE: 12,
  NEED_ADMIN_REVIEW: 13,
  STARTED: 15,
  ARCHIVED: 16,
  PRE_APPROVE_NOT_READY: 17,
  FIRST_VERIFICATION: 18,
};

export const FIELD_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  WIDE: "wide",
  FULL: "full",
};

export const NOTIFICATIONS = {
  savedYourAnswers: { title: "We saved your answers!", message: "Please use your email to continue later." },
  somethingWentWrong: { title: "Something went wrong", message: "" },
};
