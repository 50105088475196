import React, { useEffect } from "react";
import Content from "../../../../shared/components/common/Content/Content";
import Header from "../../../../shared/components/common/Header/Header";
import { ProposalRate, ProposalRateConsumerLoan } from "../../components";
import { useSelector } from "react-redux";
import { getQuoteError } from "../../../Qtn/store/selectors";
import Loader from "../../../../shared/components/common/Loader/Loader";
import { useDispatch } from "react-redux";
import { getQuote } from "../../../Qtn/store/selectors";
import { push } from "connected-react-router";
import { withRouter, RouteChildrenProps, RouteComponentProps, useParams } from "react-router";
import { fetchLoanQuoteById } from "../../../Qtn/store/actions";
import { ROUTES } from "../../../../shared/constants";

import "./styles.scss";

interface IProposalProps {
  match: {
    params: {
      quoteId: number | string;
    };
  };
}

const Proposal: React.FunctionComponent<IProposalProps & RouteChildrenProps & RouteComponentProps> = ({
  match: {
    params: { quoteId },
  },
}) => {
  const dispatch = useDispatch();
  const error = useSelector(getQuoteError());
  const quote = useSelector(getQuote());
  const { consumerLink } = useParams<{ consumerLink: string }>();

  useEffect(() => {
    if (quoteId && !quote) {
      dispatch(fetchLoanQuoteById.request({ id: quoteId, calculate: true }));
    }

    if (error || (quote && quote.auto_reject)) {
      dispatch(push(`${ROUTES.PROPOSAL}/${quoteId}${ROUTES.SORRY_PAGE}`));
    }
  }, [error, quote, dispatch, quoteId]);
  //TODO: ProposalRateConsumerLoan.tsx need to update styles

  return (
    <div className="proposal-modal-wrapper">
      <Header logo login />
      <main className="proposal-modal-success">
        <Loader />
        {quote ? (
          <Content styleName="proposal-content-success">
            <img className="proposal-img" src="/icons/checked_green.svg" alt="" />
            {consumerLink ? (
              <ProposalRateConsumerLoan title="Congrats" subtitle="You have been Pre-Approved!" />
            ) : (
              <ProposalRate title="Congrats" subtitle="You have been Pre-Approved!" />
            )}
          </Content>
        ) : null}
      </main>
    </div>
  );
};

export default withRouter(Proposal);
