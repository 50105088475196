import { ProductActionTypes } from "./constants";
import { createAction, createAsyncAction } from "typesafe-actions";
import { IProduct, IUserOrganization } from "../interfaces";

export const getProducts = createAsyncAction(
  ProductActionTypes.FETCH_PRODUCTS_REQUEST,
  ProductActionTypes.FETCH_PRODUCTS_SUCCESS,
  ProductActionTypes.FETCH_PRODUCTS_FAILURE,
)<{ isTesting?: boolean; uniqLink?: string; integration_name?: string; type?: number }, IProduct[], string>();

export const setProduct = createAsyncAction(
  ProductActionTypes.SET_PRODUCT_REQUEST,
  ProductActionTypes.SET_PRODUCT_SUCCESS,
  ProductActionTypes.SET_PRODUCT_FAILURE,
)<undefined, IProduct, string>();

export const setProductSelectProduct = createAction(ProductActionTypes.SET_PRODUCT_SELECT_PRODUCT)<IProduct | null>();

export const getAdvisor = createAsyncAction(
  ProductActionTypes.FETCH_ADVISOR_REQUEST,
  ProductActionTypes.FETCH_ADVISOR_SUCCESS,
  ProductActionTypes.FETCH_ADVISOR_FAILURE,
)<{ personal_code: string }, any, any>();

export const getOrganizations = createAsyncAction(
  ProductActionTypes.FETCH_ORGANIZATION_REQUEST,
  ProductActionTypes.FETCH_ORGANIZATION_SUCCESS,
  ProductActionTypes.FETCH_ORGANIZATION_FAILURE,
)<undefined, any, any>();

export const getOrganizationByUserEmail = createAsyncAction(
  ProductActionTypes.FETCH_ORGANIZATION_BY_USER_EMAIL_REQUEST,
  ProductActionTypes.FETCH_ORGANIZATION_BY_USER_EMAIL_SUCCESS,
  ProductActionTypes.FETCH_ORGANIZATION_BY_USER_EMAIL_FAILURE,
)<{ email: string }, IUserOrganization | null, any>();
