import {
  IQtnResponse,
  IResponse,
  IProductQtn,
  ISection,
  ILoanQuoteUserAnswers,
  IQtnSection,
  ISectionProgress,
} from "../../../interfaces";
import moment from "moment";
import { QUESTION_TYPES } from "../../../constants";
import { Dispatch } from "redux";
import { CONSUMER_LOAN_TYPES_OPTIONS } from "../../../../../shared/constants";

interface IFormInit {
  [key: string]: any;
}

export const getLoanQuoteUserAnswers = (
  qtnResponse: IQtnResponse,
  qtn: IProductQtn,
  userAnswers: IFormInit,
  update: boolean,
  followupIds: number[],
) => {
  const { first_name, last_name, dob, email, telephone_number } = userAnswers;
  const sectionQuestions = qtn.sections.flatMap((section) => section.questions);

  const payload = {
    followupIds,
    user: {
      first_name,
      last_name,
      date_of_birth: dob || null,
      email,
      telephone_number: telephone_number || "0000000000",
    },
    sections: [],
    qtn: [...sectionQuestions].map((questionObj) => {
      const question = { ...questionObj };
      if (question.question_type.type === QUESTION_TYPES.SMART_TYPING) {
        question.options = [];
      }
      let option_id = null;
      let value = null;

      let questionAnswerId = null;

      if (update && qtnResponse) {
        qtnResponse.quote_sections.forEach((section) => {
          section.responses.forEach((savedAnswer) => {
            if (savedAnswer.question_id === question.id) {
              questionAnswerId = savedAnswer.id;
            }
          });
        });
      }
      let res =
        question.options.length === 0
          ? userAnswers[question.integration_name]
          : question.options.find((opt: any) => {
              return opt.id === Number(userAnswers[question.integration_name]);
            });

      if (question.question_type.type === QUESTION_TYPES.MULTIPLE_CHOISE) {
        res = userAnswers[question.integration_name].reduce((acc: any, optionId: number) => {
          const option = question.options.find((opt: any) => opt.id === Number(optionId));
          return option ? [...acc, option] : acc;
        }, []);
      }

      if (Object.prototype.toString.call(res) === "[object Date]") {
        value = moment(res).isValid() ? new Date(res) : null;
      } else if (
        Array.isArray(res) &&
        [QUESTION_TYPES.FILE, QUESTION_TYPES.MULTIPLE_CHOISE].includes(question.question_type.type) === false
      ) {
        const valueForUpdate = JSON.parse(JSON.stringify(res)).map((row: any) => {
          Object.keys(row).forEach((key) => {
            row[key] = userAnswers[key];
          });
          return row;
        });
        value = valueForUpdate;
      } else if (
        !Array.isArray(res) &&
        typeof res === "object" &&
        question.question_type.type !== QUESTION_TYPES.FILE
      ) {
        option_id = res.id;
        value = res.title;
      } else if (res && res.length && question.question_type.type === QUESTION_TYPES.FILE) {
        const updatedValue: any[] = [];

        res.forEach((r: { [key: string]: any }) => {
          const { original_file_name, custom_file_name, last_modified_at } = r;
          updatedValue.push({
            value: r.value,
            last_modified_at,
            file_name: custom_file_name
              ? custom_file_name
              : res.value && res.value.includes(original_file_name)
              ? null
              : original_file_name,
          });
        });

        if (updatedValue.length === 1 && Object.values(updatedValue[0]).every((v) => !v)) {
          value = null;
        } else {
          value = updatedValue;
        }
      } else if (Array.isArray(res) && question.question_type.type === QUESTION_TYPES.MULTIPLE_CHOISE) {
        option_id = [];
        value = [];
        res.forEach((_opt) => {
          option_id.push(_opt.id);
          value.push(_opt.title);
        });
      } else {
        value = res ? String(res) : null;
      }
      let section = qtn.sections.find((section) => section.questions.some((q) => q.id === question.id)) || { id: null };

      const data: IResponse = {
        questionnaire_id: qtn.id,
        question_id: question.id,
        section_id: section.id,
        question_type_id: question.question_type.id,
        value,
      };

      if (questionAnswerId) data.id = questionAnswerId;
      if (option_id) data.option_id = option_id;

      return data;
    }),
  };

  payload.qtn = payload.qtn.filter((q) => {
    if (Array.isArray(q.value)) {
      return !!q.value.length;
    }

    return !!q.value;
  });

  payload.qtn = payload.qtn.map((q) => {
    if (Array.isArray(q.value) && q.question_type_id === 7) {
      q.value = q.value.map((customeAnswer) => {
        const answerWithValidIntegrationName: any = {};
        Object.keys(customeAnswer).forEach((key) => {
          answerWithValidIntegrationName[key.replace(/^.+new~/, "")] = customeAnswer[key];
        });

        return answerWithValidIntegrationName;
      });
    }

    return q;
  });

  if (Object.values(payload.user).filter((val) => val && val.length).length === 0) {
    //@ts-ignore
    delete payload.user;
  }

  return { payload };
};

export const scrollToElement = (elem: string | null) => {
  if (!elem) return;

  const elemWithErr = document.getElementById(elem);
  if (elemWithErr) elemWithErr.scrollIntoView({ block: "start", behavior: "smooth" });
};

export const scrollToTop = (element: string) => {
  const elem = document.getElementById(element);
  if (elem) {
    elem.scrollTop = 0;
  }
};

export const getAge = (date: Date) => {
  const today = moment();
  const dob = moment(new Date(date), "YYYY");
  return today.diff(dob, "years");
};

export const removeFollowUpIfTQHasAnswer = (payload: ILoanQuoteUserAnswers, followupIds: number[]) => {
  let clearedFollowupIds: number[] = followupIds && Array.isArray(followupIds) ? [...followupIds] : [];

  payload.qtn.forEach((question) => {
    if (question && question.question_type_id === 7 && question.value.length) {
      clearedFollowupIds = clearedFollowupIds.filter((id) => id !== question.question_id);
    }
  });

  return clearedFollowupIds;
};

export const groupAnswersBySections = (payload: ILoanQuoteUserAnswers, allFollowUps: number[], qtn: IProductQtn) => {
  const followupIds = removeFollowUpIfTQHasAnswer(payload, allFollowUps);
  if (!payload.qtn.length) {
    return payload;
  } else {
    const sectionsIds: number[] = [];

    payload.qtn.forEach((question) => {
      sectionsIds.push(question.section_id as number);
    });

    payload.sections = [...new Set(sectionsIds)].map((sectionId) => {
      const questions = payload.qtn
        .filter((question) => question.section_id === sectionId)
        .map((question) => question.question_id);

      const followUp = followupIds || [];
      const sectionStat = getSectionStatus(
        sectionId,
        followUp,
        qtn,
        payload.qtn.filter((q) => q.section_id === sectionId),
      );
      return {
        questions,
        section_id: sectionId as number,
        section_completed: sectionStat,
      };
    }) as ISection[];
  }

  payload.followupIds = followupIds || [];

  return payload;
};

export const getSectionStatus = (sectionId: number, followupIds: number[], qtn: IProductQtn, answers: any) => {
  const section = qtn.sections.find((s) => s.id === sectionId);
  if (section) {
    const questionsToValidate = section.questions.filter(
      (q) => q.is_required && !q.is_hidden && !followupIds.includes(q.id),
    );
    const questionsToValidateIds = questionsToValidate.map((q) => q.id);
    const answersId = answers.map(
      (answer: {
        option_id: number | null;
        question_id: number;
        questionnaire_id: number;
        section_id: number;
        value: any;
      }) => answer.question_id,
    );

    return questionsToValidateIds.every((qId) => answersId.includes(qId));
  }

  return false;
};

export const getFollowupNames = (followupIds: number[], qtn: IProductQtn) => {
  if (!followupIds || !followupIds.length || !qtn) {
    return [];
  }

  let sectionQuestions: any[] = [];
  qtn.sections.forEach((section) => sectionQuestions.push(section.questions));
  sectionQuestions = sectionQuestions.flat();

  const followupIdsNames: string[] = [];

  followupIds.forEach((id) => {
    const foundFollowUp = sectionQuestions.find((question) => question.id === id);

    if (foundFollowUp) {
      followupIdsNames.push(foundFollowUp.integration_name);
    }
  });

  return followupIdsNames;
};

export const removeFollowUp = (followupIds: number[], errorFields: string[], qtn: IProductQtn) => {
  return errorFields.filter((fieldName) => !getFollowupNames(followupIds, qtn).includes(fieldName));
};

export const getUniqIntegrationName = (newValue: any, internalId: number, integration_name: string) => {
  /**
   * After saving all questions with Table Type got uniq integration_name_prefix
   * For getting saved customer unswer we should use integration_name_prefix + integration_name
   */

  const uniqIntegrationName: string = Object.keys(newValue[internalId]).find((key) => key === integration_name) || "";
  if (uniqIntegrationName) return uniqIntegrationName;

  const foundIntNameWithPrefix = Object.keys(newValue[internalId]).find((key) => key.includes(integration_name)) || "";
  if (foundIntNameWithPrefix) return foundIntNameWithPrefix;

  return "";
};

export const bindExistUser = (updatedLoanQuote: ILoanQuoteUserAnswers, user: any) => {
  if (updatedLoanQuote.user) {
    const userFields = Object.keys(updatedLoanQuote.user);

    userFields.forEach((item) => {
      if (updatedLoanQuote.user![item] === false) {
        if (user[item]) {
          updatedLoanQuote.user![item] = user[item] as Extract<keyof typeof updatedLoanQuote.user, string>;
        } else {
          delete updatedLoanQuote.user![item];
        }
      }
    });
  }

  if (updatedLoanQuote.user) {
    updatedLoanQuote.user.id = user.id;
  }

  return updatedLoanQuote;
};

export const selectEffect: Record<string, any> = {
  organizations: (fn: Function, dispatch: Dispatch) => (args: any) => dispatch(fn(args)),
};

export const loanInfoSection = (
  data: IQtnSection,
  methods: {
    optionEffect: Function;
    setAmount?: Function;
    setTerm?: Function;
    setFees?: Function;
    consumerLink: string;
  },
) => {
  const { optionEffect, setAmount, setTerm, consumerLink } = methods;
  if (!consumerLink) {
    return data;
  }
  const changeActionVoid = () => {};
  const qtns: Record<string, any> = {
    amount: { action: (value: string | number) => (setAmount && setAmount("amount", value)) || changeActionVoid },
    term: { action: (value: string | number) => (setTerm && setTerm("term", value)) || changeActionVoid },
    // total_fees: { action: () => setFees('total_fees') || changeActionVoid },
    loan_options: {
      previewCalculation: true,
      options: [
        {
          title: "Take it out of the money disbursed to me",
          optionType: CONSUMER_LOAN_TYPES_OPTIONS.DISBURSEMENT,
          default: true,
        },
        { title: "Add to my total loan amount", optionType: CONSUMER_LOAN_TYPES_OPTIONS.CREDIT_BALANCE },
      ],
    },
  };
  const transofrmed = {
    ...data,
    questions: data.questions.map((qtn) => {
      const additional = qtns[qtn.integration_name];
      if (additional) {
        if (additional?.options) {
          additional.options = additional.options.map((aOpt: any) => {
            const opt = qtn.options.find((opt) => {
              return opt.title.trim() === aOpt.title;
            });
            return { ...opt, ...aOpt };
          });
        }
        qtn = {
          ...qtn,
          ...additional,
          optionEffect: (value: string | number) => {
            return optionEffect("option_type", value);
          },
        };
      }
      return qtn;
    }),
  };

  return transofrmed;
};

export const calculateProgress = (
  id: number,
  qtn: IProductQtn,
  sectionProgress: ISectionProgress,
  followupIds: number[],
) => {
  if (!sectionProgress) {
    return 0;
  }

  if (qtn.sections) {
    const section = qtn.sections.find((section) => section.id === id);

    if (section) {
      const integrationNames = section.questions
        .filter((question) => !question.is_hidden && question.is_required)
        .filter((question) => {
          if (question.question_type.type !== QUESTION_TYPES.TABLE) {
            return true;
          } else {
            let tableQuestionHasAnswers = false;

            return tableQuestionHasAnswers;
          }
        })
        .map((q) => q.integration_name);

      const total = removeFollowUp(
        followupIds,
        Object.keys(sectionProgress).filter((fieldName) => integrationNames.includes(fieldName)),
        qtn,
      );
      const withAnswers = integrationNames.filter((fieldName) => {
        if (Array.isArray(sectionProgress[fieldName])) {
          return (
            sectionProgress[fieldName].length &&
            sectionProgress[fieldName].every((item: Record<string, string> & { value: string }) => {
              const isFileField =
                item?.hasOwnProperty("value") &&
                item?.hasOwnProperty("base64_name") &&
                item?.hasOwnProperty("custom_file_name") &&
                item?.hasOwnProperty("original_file_name");
              if (!!item?.value) {
                return Boolean(item.value);
              } else if (isFileField) {
                return Boolean(item.value);
              } else {
                return Boolean(item);
              }
            })
          );
        } else if (fieldName === "loan_options") {
          //loan options should have a value by default
          return true;
        } else {
          return !!sectionProgress[fieldName];
        }
      });
      return Math.round(((withAnswers.length > total.length ? total.length : withAnswers.length) * 100) / total.length);
    }
  }

  return 0;
};

export const checkProductFormsIsStarted = (
  qtn: IProductQtn,
  sectionProgress: ISectionProgress,
  followupIds: number[],
): boolean => {
  const qtnSectionIds = qtn?.sections.map((section) => section.id);

  let isStarted = false;

  qtnSectionIds?.forEach((sectionId) => {
    if (!isStarted) {
      const progress = calculateProgress(sectionId, qtn, sectionProgress, followupIds);
      if (progress > 0) {
        isStarted = true;
      }
    }
  });

  return isStarted;
};
