import React from "react";
import RateOptions from "./RateOptions/RateOptions";
import { IProduct, IProductTerm } from "../../interfaces";
import "./styles.scss";

interface IProductRateProps {
  amount: number;
  downpayment: number;
  term: number;
  product: IProduct;
  setFieldValue: (field: string, value: any) => void;
  rateOpts: { [key: string]: number };
  termTable: IProductTerm[];
  hasErrors: boolean;
  validateFormFields: () => void;
}

const ProductRate: React.FunctionComponent<IProductRateProps> = ({ product, ...props }) => {
  const getAlternativeRates = () => {
    const { term, termTable } = props;
    const { duration_max } = product;
    const termInt = Number(term);
    const DEFAULT_MODIFIER = termInt >= product.duration_max ? -2 : 2;
    const termModifier = termTable[termTable.findIndex((item) => item.term === termInt)]?.modifier || DEFAULT_MODIFIER;
    const termModAbs = Math.abs(termModifier);
    let alternativeTerm = termInt + termModifier;
    let alternativeMore = alternativeTerm + termModifier;

    if (termInt > product.duration_max) {
      alternativeTerm = product.duration_max - termModAbs;
      alternativeMore = product.duration_max - alternativeTerm;
    } else if (termInt > product.duration_min) {
      const alternativeMid = termInt - (termInt === duration_max ? termModAbs + termModAbs : termModAbs);

      alternativeMore = alternativeTerm > duration_max ? duration_max : alternativeTerm;
      alternativeTerm = alternativeMid < product.duration_min ? product.duration_min : alternativeMid;
    }

    return (
      <>
        <RateOptions title={"Rate #2"} {...{ ...props, term: alternativeTerm }} />
        <RateOptions title={"Rate #3"} {...{ ...props, term: alternativeMore }} />
      </>
    );
  };

  return (
    <div className="product-rate">
      <RateOptions title={"Recommended Rate #1"} isMainRate={true} {...props} />
      <h3 className="rate-title">Other Rates</h3>
      {getAlternativeRates()}
    </div>
  );
};

export default ProductRate;
