import { Form, Formik, FormikProps, FormikValues } from "formik";
import { FORMS } from "../../../../../shared/constants";
import classnames from "classnames";
import Button from "../../../../../shared/components/styled/Button";
import ModalComponent from "../../../../../shared/components/common/ModalComponent/ModalComponent";
import React, { FC } from "react";
import { IProduct } from "../../../interfaces";

interface IVerifyModalProps {
  showVerifyEmailModal: boolean;
  product: IProduct;
  handleSubmit: (values: FormikValues) => void;
  handleClose: () => void;
}

const VerifyEmailModal: FC<IVerifyModalProps> = (props) => {
  const { showVerifyEmailModal, product, handleSubmit, handleClose } = props;
  return (
    <ModalComponent
      show={showVerifyEmailModal}
      title="Looks like you may have already started a quote"
      imgSrc="/icons/information.svg"
      truncateTitleWidth
    >
      <div>
        <div className="subtitle">Please verify your email to continue</div>
        <Formik
          enableReinitialize
          initialValues={FORMS(product ? product.id : null).ALREADY_STARTED.INIT}
          validateOnChange={false}
          validationSchema={FORMS(product ? product.id : null).ALREADY_STARTED.SCHEMA}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<FormikValues>) => {
            const { touched, errors, handleChange, values, setFieldError, dirty } = props;
            if (!showVerifyEmailModal) {
              setFieldError("email", "");
            }

            const handleChangeField = (e: any) => {
              handleChange(e);
              setFieldError("email", "");
            };

            return (
              <Form className="modal-form">
                <div className={classnames("form-field", { error: touched.email && errors.email })}>
                  <label htmlFor="email">Email*</label>
                  <input onChange={handleChangeField} value={values.email || ""} type="text" name="email" id="email" />
                  <div className="form-field-error">{touched.email && errors.email}</div>
                </div>
                <div className="modal-buttons">
                  <Button
                    aria-label="Cancel"
                    type="button"
                    width={172}
                    onClick={handleClose}
                    variant="secondary-new-green"
                  >
                    Cancel
                  </Button>
                  <Button
                    width={172}
                    variant={!dirty || !!(touched.email && errors.email) ? "disabled" : "primary-new-green"}
                    aria-label="Verify"
                    type="submit"
                    disabled={!dirty || !!(touched.email && errors.email)}
                    className="from-submit"
                  >
                    Verify
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalComponent>
  );
};

export default VerifyEmailModal;
