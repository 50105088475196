import React, { ReactNode } from "react";
import classnames from "classnames";

import "./styles.scss";

interface IModalProps {
  show: boolean;
  title: string;
  img: boolean;
  msg: string;
  children?: ReactNode;
  imgSrc?: string;
}

const Modal: React.FunctionComponent<IModalProps> = ({ msg, title, children, show, img, imgSrc }) => {
  return show ? (
    <div className="modal-container">
      <div className={classnames("modal", { "modal-small": !img && !imgSrc })}>
        {img && !imgSrc && <img className="modal-img" src="/images/proposal.svg" alt="" />}
        {imgSrc ? <img src={imgSrc} alt="" /> : null}
        <h3 className="modal-title">{title}</h3>
        <p className="modal-msg" dangerouslySetInnerHTML={{ __html: msg }} />
        <div className={classnames("modal-actions", { "full-width": !img })}>{children}</div>
      </div>
    </div>
  ) : null;
};

export default Modal;
