import { call, put, takeLatest, select } from "redux-saga/effects";
import { QtnActionTypes } from "./constants";
import sagaAssessor from "../../../utils/sagaAssessor";
import {
  getQtn,
  fetchLoanQuote,
  createLoanQuote,
  updateLoanQuote,
  calculateQuote,
  updateQuoteStatus,
  setQuoteRate,
  fetchLoanQuoteById,
  clearQuote,
  updateLoanBeforeUnmountQuote,
  patchLoanQuote,
  updateDraftLoanQuote,
} from "./actions";
import api from "../api";
import { AnyAction } from "redux";
import { notificationActions } from "../../Notifications/store/actions";
import { startLoader, stopLoader } from "../../../shared/components/common/Loader/store/actions";
import { cookie } from "../../../shared/utils";
import {
  getLastFormikState,
  getProductQtn,
  getLoanQuoteResponse,
  getFollowUp,
  getQuoteOrganization,
  getLoanQuoteOrganizationId,
} from "./selectors";
import { getLoanQuoteUserAnswers, groupAnswersBySections } from "../components/QtnSection/utils";
import { ILoanQuotePatchRequest, IDraftLoanQuote } from "../interfaces";
import { NOTIFICATIONS } from "../constants";

const fetchProductQtn = ({ payload }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const data = yield call(api.fetchProductQtn, payload);
        yield put(getQtn.success(data));
      },
    getQtn.failure,
  );

const fetchExistLoanQuote = ({ payload: { sessionId, callback } }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        yield put(startLoader());
        // @ts-ignore
        const data = yield call(api.fetchQuoteBySession, sessionId);
        if (data && data.status && data.status === 204) {
          // yield put(fetchLoanQuote.success(null));
          yield put(stopLoader());
          if (callback) {
            callback();
          }
        } else {
          yield put(fetchLoanQuote.success(data));
          const { amount, downpayment, term, price } = data;
          yield put(setQuoteRate({ amount, downpayment, term, price }));
          yield put(stopLoader());
          if (callback) {
            callback();
          }
        }
      },
    fetchLoanQuote.failure,
  );

const saveLoanQuote = ({ payload }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        const qtUserJSON = localStorage.getItem("user");
        const qtUser = qtUserJSON && JSON.parse(qtUserJSON);
        // @ts-ignore
        const organizationId = yield select(getLoanQuoteOrganizationId);
        const extended = {
          ...payload,
          data: { ...(qtUser && { user: { email: qtUser.email } }), ...payload.data, organization_id: organizationId },
        };
        // @ts-ignore
        const data = yield call(api.saveLoanQuote, extended);
        yield put(createLoanQuote.success(data));
      },
    createLoanQuote.failure,
    payload.callback,
  );

const updateLoanQuoteById = ({ payload }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const organization = yield select(getQuoteOrganization());
        const reqPayload = { ...payload.data, organization_id: organization };
        // @ts-ignore
        const data = yield call(api.updateLoanQuote, { ...payload, data: { ...reqPayload } });
        yield put(updateLoanQuote.success(data));
      },
    updateLoanQuote.failure,
    payload.callback,
  );

const patchLoanQuoteSaga = ({
  payload: { callback, ...fields },
}: {
  payload: ILoanQuotePatchRequest & { callback: () => void };
}) => {
  return sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const data = yield call(api.patchLoanQuote, fields);
        yield put(updateLoanQuote.success(data));
      },
    updateLoanQuote.failure,
    callback,
  );
};

function* updateDraftLoanQuoteById({ payload }: IDraftLoanQuote) {
  const { data, id, callback, isWelcomeEmail } = payload;

  try {
    yield put(startLoader());
    const body = { data, id, query: { sendEmail: isWelcomeEmail || undefined } };
    // @ts-ignore
    const response = yield call(api.updateLoanQuote, body);

    yield put(updateLoanQuote.success(response));
    yield put(notificationActions.success(NOTIFICATIONS.savedYourAnswers));
  } catch (err) {
    yield put(notificationActions.error(NOTIFICATIONS.somethingWentWrong));
  } finally {
    callback?.();
    yield put(stopLoader());
  }
}

const calculateLoanQuote = ({ payload }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const data = yield call(api.calculateLoanQuote, {
          id: payload.id,
          data: { advisorId: payload.advisorId, type: payload.type },
        });
        yield put(calculateQuote.success(data));
      },
    calculateQuote.failure,
    payload.callback,
  );

const updateLoanQuoteStatus = ({ payload }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const data = yield call(api.updateQuoteStatus, payload);
        yield put(updateQuoteStatus.success(data));
      },
    updateQuoteStatus.failure,
    payload.callback,
  );

const fetchExistLoanQuoteById = ({ payload }: AnyAction) =>
  sagaAssessor(
    () =>
      function* () {
        // @ts-ignore
        const data = yield call(api.fetchQuoteById, payload);
        yield put(fetchLoanQuote.success(data));
        if (payload.calculate) {
          yield put(calculateQuote.success(data));
        }

        if (payload?.callback) {
          payload?.callback();
        }

        const { amount, downpayment, term, price } = data;
        yield put(setQuoteRate({ amount, downpayment, term, price }));
      },
    fetchLoanQuoteById.failure,
  );

function* clearQuoteSaga({ payload: { callback } }: { payload: { data: any; callback?: () => void } }) {
  cookie.deleteCookie("sessionId");

  try {
    yield put(clearQuote.success({ data: null }));
    if (callback) {
      callback();
    }
  } catch (err) {
    yield put(clearQuote.failure(err as any));
  }
}

function* removeUploadedFileFromQuote({ payload }: AnyAction) {
  yield call(api.removeFile, payload);
}

function* updateLoanQuoteBeforeUnmount({ payload }: AnyAction) {
  const sessionId = cookie.readCookie("sessionId");

  if (!!sessionId) {
    // @ts-ignore
    const lastSavedFormikState = yield select(getLastFormikState());
    // @ts-ignore
    const qtn = yield select(getProductQtn());
    // @ts-ignore
    const qtnResponse = yield select(getLoanQuoteResponse());
    // @ts-ignore
    const followupIds = yield select(getFollowUp());

    const answers = getLoanQuoteUserAnswers(qtnResponse, qtn, lastSavedFormikState, true, followupIds);

    const data = groupAnswersBySections(answers.payload, followupIds, qtn);

    try {
      // @ts-ignore
      const result = yield call(api.updateLoanQuote, { ...payload, data });
      yield put(updateLoanBeforeUnmountQuote.success(result));
    } catch (err) {
      yield put(updateLoanBeforeUnmountQuote.failure(err as string));
    }
  }
}

export default function* productWatcher() {
  yield takeLatest(patchLoanQuote.request, patchLoanQuoteSaga);
  yield takeLatest(updateDraftLoanQuote.request, updateDraftLoanQuoteById);
  yield takeLatest(clearQuote.request, clearQuoteSaga);
  yield takeLatest(QtnActionTypes.FETCH_QTN_REQUEST, fetchProductQtn);
  yield takeLatest(QtnActionTypes.FETCH_LOAN_QUOTE_REQUEST, fetchExistLoanQuote);
  yield takeLatest(QtnActionTypes.CREATE_LOAN_QUOTE_REQUEST, saveLoanQuote);
  yield takeLatest(QtnActionTypes.UPDATE_LOAN_QUOTE_REQUEST, updateLoanQuoteById);
  yield takeLatest(QtnActionTypes.CALCULATE_QUOTE_REQUEST, calculateLoanQuote);
  yield takeLatest(QtnActionTypes.UPDATE_QUOTE_STATUS_REQUEST, updateLoanQuoteStatus);
  yield takeLatest(QtnActionTypes.FETCH_LOAN_QUOTE_BY_ID_REQUEST, fetchExistLoanQuoteById);
  yield takeLatest(QtnActionTypes.REMOVE_UPLOADED_FILE, removeUploadedFileFromQuote);
  yield takeLatest(QtnActionTypes.UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_REQUEST, updateLoanQuoteBeforeUnmount);
}
