import React from "react";
import { IQuestionProps, IQtnOption } from "../../../interfaces";
import "./styles.scss";
import * as _ from "lodash";
import classnames from "classnames";

const MultipleChoiceQuestion: React.FunctionComponent<IQuestionProps> = ({
  updateSectionError,
  integration_name,
  id,
  options,
  onBlur,
  field: { value },
  form: { setFieldError, setFieldValue },
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const optvalue = parseInt(e.target.value);
    let selectedOptions = value && value[integration_name] ? [...value[integration_name]] : [];

    selectedOptions =
      !selectedOptions.length || !selectedOptions.includes(optvalue)
        ? [...selectedOptions, optvalue]
        : selectedOptions.filter((optId) => optId !== optvalue);
    setFieldValue(integration_name, selectedOptions);
    setFieldError(integration_name, "");
    updateSectionError(integration_name);
    onBlur({ target: { name: integration_name, value: selectedOptions } });
  };

  const selectedOption = value[integration_name] || [];

  return (
    <div id={integration_name}>
      {_.orderBy(options, ["order"], ["asc"]).map((opt: IQtnOption) => {
        return (
          <React.Fragment key={opt.id}>
            <label
              htmlFor={`opt-${id}-${opt.id}`}
              className={classnames("option", { selected: selectedOption.includes(opt.id) })}
            >
              <img
                className="opt-img"
                src={
                  selectedOption.includes(opt.id)
                    ? "/icons/checkbox_checked_green_new.svg"
                    : "/icons/checkbox_empty_gray.svg"
                }
                alt="Option"
              />
              {opt.title}
            </label>
            <input
              hidden
              id={`opt-${id}-${opt.id}`}
              name={integration_name}
              type="checkbox"
              value={opt.id}
              onChange={handleChange}
              onBlur={onBlur}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default MultipleChoiceQuestion;
