import React, { useState, useEffect, useRef, FunctionComponent, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { generatePath, useParams } from "react-router";
import { push } from "connected-react-router";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { isEqual } from "lodash";
import { FORM } from "../../constants";
import {
  getSelectedProduct,
  getOrganizationsOptions,
  getOrganizationById,
  getUserOrganization,
} from "../../store/selectors";
import { ROUTES } from "../../../../shared/constants";
import Recaptcha from "../../../../shared/components/common/Recaptcha/Recaptcha";

import {
  createLoanQuote,
  fetchLoanQuote,
  setQuoteRate,
  updateLoanQuote,
  setOrganizationQtnValues,
} from "../../../Qtn/store/actions";
import { cookie } from "../../../../shared/utils";
import { v4 as uuidv4 } from "uuid";
import { IProductQtn, IQtnResponse } from "../../../Qtn/interfaces";
import { getFollowUp, getLoanQuoteResponse, getProductQtn, getProductRate } from "../../../Qtn/store/selectors";
import { groupAnswersBySections, scrollToElement } from "../../../Qtn/components/QtnSection/utils";
import * as actions from "../../store/actions";

import { SmartTypingQuestion } from "../../../Qtn/components";

import "./styles.scss";
import ProductConsumerRate from "../../components/ProductConsumerTypeLoanOptions/ProductConsumerRate/ProductConsumerRate";
import { PaymentCalculation } from "../../components/ProductConsumerTypeLoanOptions/utils/Payment";
import {
  calculateMonthlyPayment,
  calculateTotalAmount,
  calculateTotalFees,
  calculateTotalInterest,
} from "../../components/ProductConsumerTypeLoanOptions/utils/calculateFunctions";
import Header from "../../../../shared/components/common/Header/Header";
import Loader from "../../../../shared/components/common/Loader/Loader";
import RangeFieldWithDotLabel from "../../../../shared/components/common/RangeFieldWithDotLabel/RangeFieldWithDotLabel";

const ProductConsumerLoanCalculate: FunctionComponent<any> = () => {
  const dispatch = useDispatch();
  const product = useSelector(getSelectedProduct());
  const savedUserOrganization = useSelector(getUserOrganization());
  const rate: any = useSelector(getProductRate());
  const issetProduct = useRef<boolean>(false);
  const { consumerLink } = useParams<{ consumerLink: string }>();
  const [recaptcha, setRecaptcha] = useState(false);
  const [isDisabledOrganization, setIsDisabledOrganization] = useState(false);
  const organizationSelector = useSelector(getOrganizationById, isEqual);
  const qtn: IProductQtn = useSelector(getProductQtn());
  const qtnResponse: IQtnResponse = useSelector(getLoanQuoteResponse());
  const followupIds = useSelector(getFollowUp());
  const smartTypingOptions: Record<string, any[]> = useSelector(getOrganizationsOptions());
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const quoteOrganization = useMemo(() => (organizationId ? organizationSelector(organizationId) : undefined), [
    organizationId,
    organizationSelector,
  ]);

  useEffect(() => {
    if (!smartTypingOptions.organizations.length) {
      dispatch(actions.getOrganizations.request());
    }

    return () => {};
  }, [dispatch, smartTypingOptions.organizations]);

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    const email = savedUser && JSON.parse(savedUser)?.email;
    if (email) {
      dispatch(actions.getOrganizationByUserEmail.request({ email }));
    }
    return () => {
      dispatch(actions.getOrganizationByUserEmail.success(null));
    };
  }, [dispatch]);

  const handleSubmit = () => {
    const data = {
      amount: formik.values.amount,
      term: formik.values.term,
      monthly_payment: formik.values.monthly_payment,
      total_interest: formik.values.total_interest,
      total_fees: formik.values.total_fees,
      total_amount_repaid: formik.values.total_amount,
    };
    let initial: any = [];

    if (qtnResponse) {
      qtnResponse.quote_sections.forEach((quote_section) => {
        quote_section.responses.forEach((res) => {
          initial = initial.map((init: any) => {
            if (init.question_id === res.question_id) {
              init.id = res.id as number;
            }
            return init;
          });
        });
      });
    }
    setRecaptcha(false);
    // Save text value of organization name completed by user not from our list
    if (!quoteOrganization && formik?.values?.organization) {
      cookie.writeCookie("employers_not_company", String(formik.values.organization), 1);
    }
    !rate ? createQuote(data, initial) : updateQuote(data, initial);
  };

  const createQuote = (data: any, initial: any) => {
    const payload = {
      qtn: [...initial],
      sections: [],
      rate: {
        ...data,
      },
    };
    if (product) {
      dispatch(
        createLoanQuote.request({
          data: groupAnswersBySections(payload, followupIds, qtn),
          id: product.id,
          callback: () => {
            redirectAfterSaveQuote(data);
            cookie.deleteCookie("personal_code");
            dispatch(actions.getAdvisor.success(null));
          },
        }),
      );
    }
  };

  const updateQuote = (data: any, initial: any) => {
    if (product) {
      const payload = {
        qtn: [...initial].map((initAnswer) => {
          return {
            ...initAnswer,
            questionnaire_id: product.id,
          };
        }),
        sections: [],
        rate: data,
      };

      dispatch(
        updateLoanQuote.request({
          data: groupAnswersBySections(payload, followupIds, qtn),
          id: qtnResponse.id,
          callback: () => redirectAfterSaveQuote(data),
        }),
      );
    }
  };

  const redirectAfterSaveQuote = (data: any) => {
    redirectToQtn();
    dispatch(setQuoteRate(data));
  };

  const redirectToQtn = () =>
    product && dispatch(push(generatePath(ROUTES.CONSUMER_LOAN_PRODUCT, { id: product.id, consumerLink })));

  useEffect(() => {
    const sessionId = getSessionId();

    if (!!sessionId) {
      dispatch(fetchLoanQuote.request({ sessionId }));
    } else {
      setSessionId();
    }
  }, [dispatch]);

  const getSessionId = () => cookie.readCookie("sessionId");
  const setSessionId = () => cookie.writeCookie("sessionId", uuidv4(), 3);

  const formik = useFormik({
    initialValues: FORM.CONSUMER_OPTIONS.SCHEME,
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape({
      amount: Yup.number().positive("Invalid price").required("This field is required"),
      term: Yup.number().positive("Invalid price").required("This field is required"),
      monthly_payment: Yup.number().positive("Invalid price").required("This field is required"),
      total_interest: Yup.number().positive("Invalid price").required("This field is required"),
      total_fees: Yup.number().positive("Invalid price").required("This field is required"),
      total_amount: Yup.number().positive("Invalid price").required("This field is required"),
      organization: Yup.number().positive().nullable(),
    }),
  });

  useEffect(() => {
    if (savedUserOrganization && savedUserOrganization.id && formik && !isDisabledOrganization) {
      const { id, name } = savedUserOrganization;
      dispatch(setOrganizationQtnValues(id));
      setOrganizationId(id);
      formik.setFieldValue("organization", name);
      setIsDisabledOrganization(true);
    }
    return () => {
      isDisabledOrganization && setIsDisabledOrganization(false);
    };
  }, [dispatch, isDisabledOrganization, formik, savedUserOrganization]);

  useEffect(() => {
    const payment: any = PaymentCalculation.from({
      ...formik.values,
      rate_max: quoteOrganization?.interest_rate || product?.rate_max,
      negotiation_max: product?.negotiation_max ?? 0,
    })
      .map(calculateTotalFees)
      .map(calculateMonthlyPayment)
      .map(calculateTotalAmount)
      .map(calculateTotalInterest)
      .unwrap();
    formik.setFieldValue("monthly_payment", payment.monthlyPayment);
    formik.setFieldValue("total_interest", payment.totalInterest);
    formik.setFieldValue("total_fees", payment.totalFees);
    formik.setFieldValue("total_amount", payment.totalBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.term, formik.values.amount, formik.values.option_type, product, quoteOrganization]);

  useEffect(() => {
    if (product && !issetProduct.current) {
      const payment: any = PaymentCalculation.from({
        ...product,
      })
        .map(calculateTotalFees)
        .map(calculateMonthlyPayment)
        .map(calculateTotalAmount)
        .map(calculateTotalInterest)
        .unwrap();

      issetProduct.current = true;
      formik.setValues({
        ...FORM.CONSUMER_OPTIONS.SCHEME,
        term: payment.term,
        amount: payment.amount,
        monthly_payment: payment.monthlyPayment,
        total_interest: payment.totalInterest,
        total_fees: payment.totalFees,
        total_amount: payment.totalBalance,
      });
    }
  }, [product, issetProduct, formik]);

  const validateFormFields = () => {
    const errors = formik.validateForm();
    const errorsList = Object.keys(errors);
    if (errorsList.length) {
      scrollToElement(errorsList[0]);
    } else {
      setRecaptcha(true);
    }
  };

  const selectOrganization = useCallback(
    (id) => {
      const value = id !== 0 ? id : null;
      dispatch(setOrganizationQtnValues(value));
      setOrganizationId(value);
    },
    [dispatch],
  );

  return (
    <>
      <Header logo login productId={product?.id} showProductSelector={false} />
      <Loader />
      {product && (
        <div className="consumer-loan-calculate-wrapper">
          <div className="content-wrapper">
            {recaptcha ? (
              <Recaptcha
                onSuccess={() => {
                  handleSubmit();
                }}
              />
            ) : (
              product && (
                <div className="product-consumer-loan-wrapper">
                  <FormikProvider value={formik}>
                    <div className="product-consumer-loan-results">
                      <div className="product-consumer-loan-results_title">
                        For your personalised quote, please provide the required details.
                      </div>
                      <div className="product-consumer-loan-results_questions">
                        <div className="content-row">
                          <div>
                            <div className="field-label">Organization Name</div>
                            <SmartTypingQuestion
                              field={{ value: formik.values, onChange: formik.handleChange }}
                              onBlur={formik.handleBlur}
                              integration_name={"organization"}
                              typingOptions={smartTypingOptions.organizations}
                              selectEffect={selectOrganization}
                              form={{
                                touched: formik.touched,
                                errors: formik.errors,
                                setFieldError: formik.setFieldError,
                                setFieldTouched: formik.setFieldTouched,
                              }}
                              disabled={isDisabledOrganization}
                            />
                          </div>
                        </div>
                        <div className="content-row">
                          <RangeFieldWithDotLabel
                            title="Loan Amount"
                            subtitle="Max Amount = 3x gross salary"
                            min={product.amount_min}
                            max={product.amount_max}
                            step={500}
                            onChange={(value) => {
                              formik.setFieldValue("amount", value);
                            }}
                            value={formik.values.amount}
                            prefix="$"
                          />
                        </div>
                        <div className="content-row">
                          <RangeFieldWithDotLabel
                            title="Loan Term"
                            min={product.duration_min}
                            max={product.duration_max}
                            onChange={(value) => {
                              formik.setFieldValue("term", value);
                            }}
                            value={formik.values.term}
                            suffix={formik.values.term > 1 ? " months" : " month"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="product-consumer-loan-rate">
                      <ProductConsumerRate
                        term={formik.values.term}
                        monthly_payment={formik.values.monthly_payment}
                        total_interest={formik.values.total_interest}
                        total_fees={formik.values.total_fees}
                        total_amount={formik.values.total_amount}
                        validateFormFields={validateFormFields}
                      />
                    </div>
                  </FormikProvider>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductConsumerLoanCalculate;
