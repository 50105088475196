const cookie = {
  readCookie: (name: string) => {
    let i,
      c,
      ca,
      nameEQ = name + "=";
    ca = document.cookie.split(";");
    for (i = 0; i < ca.length; i++) {
      c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return "";
  },

  writeCookie: (name: string, value: string, days: number) => {
    let date, expires;

    if (days) {
      date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "";
    }

    const cookie = name + "=" + value + expires + "; path=/";
    document.cookie = cookie;
  },

  deleteCookie: (name: string) => {
    cookie.writeCookie(name, "", 0);
  },

  // clearCookie: (names: string[]) => {
  //   names.forEach((name) => cookie.writeCookie(name, "", 0));
  // },
};

export default cookie;
