import React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

interface INotificationActionProps {
  snackKey: any;
}

const NotistackContainer: React.FunctionComponent<INotificationActionProps> = ({ snackKey }) => {
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.iconButton} onClick={() => closeSnackbar(snackKey)}>
      <img alt={t("Close")} src="/icons/notificationClose.svg" />
    </div>
  );
};

export default NotistackContainer;
