import tokenHandler from "./tokenHandler";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import schemas from "../schemas";
import { SCHEMAS } from "../constants";

axios.defaults.headers.post["Content-Type"] = "application/json";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  const token = tokenHandler.get();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  delete config.headers["Content-Length"];
  return config;
});

const findSchema = (schemaKey: string) => {
  // @ts-ignore
  let requestSchema = schemas[schemaKey];

  if (requestSchema) return requestSchema;

  Object.keys(schemas).forEach((key) => {
    const regex = new RegExp(key);
    const result = regex.exec(schemaKey);
    if (result && result.length === 1) {
      // @ts-ignore
      requestSchema = schemas[key];
    }
  });

  return requestSchema;
};

const makeRequest = (instance: AxiosInstance) => (method: string, url: string, params: any) => {
  const requestSchema = findSchema(`${url}${SCHEMAS.REQUEST}${method}`);

  if (requestSchema) {
    // @ts-ignore
    const valid = requestSchema(...params);
    if (!valid) {
      return Promise.reject({
        error: "Sended transfer object isn't valid",
      });
    }
  }

  // @ts-ignore
  return instance[method](url, ...params);
};

axiosInstance.interceptors.response.use(
  (res: AxiosResponse) => {
    let response = res.data || res;
    const { config } = res || {};
    const { url, method } = config || {};
    const responseSchema = findSchema(`${url}${SCHEMAS.RESPONSE}${method}`);
    if (response.error) {
      return Promise.reject(response.error);
    }

    if (responseSchema) {
      const valid = responseSchema(response);
      if (!valid) {
        return Promise.reject({
          error: "Recevied transfer object isn't valid",
        });
      }
    }

    return response;
  },
  (error: AxiosError) => {
    const { response } = error || {};
    const { data } = response || {};
    if (data) {
      return Promise.reject(data);
    }
    return Promise.reject(error);
  },
);
/**
 * Axios wrapper
 *
 * @param  {string} method Method of the request
 * @param  {string} url url of the request
 *
 * @return {object} wrapped axios function that receives params
 */
export default (method: string, url: string) => (...params: any[]) => makeRequest(axiosInstance)(method, url, params);
