import { IConfiguration, IConfigurationObject } from "./shared/interfaces/Configuration";

const { REACT_APP_ENV = "dev" } = process.env;

const config: IConfigurationObject = {
  dev: {
    env: REACT_APP_ENV,
    baseAppUrl: "https://app.dev.banking.maritimefinancial.com",
    maritimeUrl: "https://app.dev.maritimefinancial.com",
    serverUrl: "https://api.dev.banking.maritimefinancial.com",
    maritimeFinancial: "https://maritimefinancial.com",
    recaptchaAPIKey: process.env.RECAPTCHA_KEY || "6LdrnbAaAAAAAK0LYjFad5yu_gtgXt-IcSq4qgHa",
  },
  qa: {
    env: REACT_APP_ENV,
    baseAppUrl: "https://app.qa.banking.maritimefinancial.com",
    maritimeUrl: "https://app.qa.maritimefinancial.com",
    serverUrl: "https://api.qa.banking.maritimefinancial.com",
    recaptchaAPIKey: process.env.RECAPTCHA_KEY || "6LdrnbAaAAAAAK0LYjFad5yu_gtgXt-IcSq4qgHa",
    maritimeFinancial: "https://maritimefinancial.com",
  },
  uat: {
    env: REACT_APP_ENV,
    baseAppUrl: "https://app.uat.banking.maritimefinancial.com",
    maritimeUrl: "https://app.uat.maritimefinancial.com",
    serverUrl: "https://api.uat.banking.maritimefinancial.com",
    recaptchaAPIKey: process.env.RECAPTCHA_KEY || "6LdrnbAaAAAAAK0LYjFad5yu_gtgXt-IcSq4qgHa",
    maritimeFinancial: "https://maritimefinancial.com",
  },
  production: {
    env: REACT_APP_ENV,
    baseAppUrl: "https://app.banking.maritimefinancial.com",
    maritimeUrl: "http://app.maritimefinancial.com",
    serverUrl: "https://api.banking.maritimefinancial.com",
    googleAnalyticsId: "UA-39114004-1",
    recaptchaAPIKey: process.env.RECAPTCHA_KEY || "6Lf0yicbAAAAACLfAUdeggHPT0B2XiKY_ZKWdirt",
    maritimeFinancial: "https://maritimefinancial.com",
    hjid: 2310090,
    hjsv: 6,
  },
};

const configElement: IConfiguration = config[REACT_APP_ENV];
export default configElement;
