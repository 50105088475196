import React, { useState, useEffect } from "react";
import "./styles.scss";
import { getProductQtn, getFollowUp } from "../../store/selectors";
import { IProductQtn } from "../../interfaces";
import { useSelector } from "react-redux";
import { getFollowupNames } from "../../../../containers/Qtn/components/QtnSection/utils";

interface IQtnSectionErrorProps {
  sectionId: number;
  sectionErrors: Array<string>;
}

const QtnSectionError: React.FunctionComponent<IQtnSectionErrorProps> = ({ sectionId, sectionErrors }) => {
  const qtn: IProductQtn = useSelector(getProductQtn());
  const [errors, setErrors] = useState<number>(0);
  const followupIds = useSelector(getFollowUp() || []);

  useEffect(() => {
    const section = qtn.sections.find((section) => section.id === sectionId);
    const followupIntegrationNames = getFollowupNames(followupIds, qtn);

    if (section && sectionErrors.length) {
      const integrationNames = [...section.questions]
        .filter((question) => !question.is_hidden && question.is_required)
        .map((question) => question.integration_name);

      let hasErrors = sectionErrors.filter(
        (v: any) => integrationNames.includes(v) && !followupIntegrationNames.includes(v),
      );

      setErrors(hasErrors.length);
    } else {
      setErrors(0);
    }
  }, [qtn, sectionId, sectionErrors, followupIds]);

  return errors ? (
    <div className="section-error">{`You have ${errors} error${errors > 1 ? "s" : ""} in this section.`}</div>
  ) : null;
};

export default QtnSectionError;
