import React, { useCallback } from "react";
import configElement from "../../../../config";

import "./styles.scss";
import Header from "../../../../shared/components/common/Header/Header";
import Content from "../../../../shared/components/common/Content/Content";
import Button from "../../../../shared/components/styled/Button";

const ThanksPage: React.FunctionComponent = () => {
  const onReturnToAccountClick = useCallback(() => {
    window.location.assign(configElement.maritimeUrl + "/clientQuotes");
  }, []);
  return (
    <div className="proposal-modal-wrapper">
      <Header logo login />
      <main className="proposal-modal-success">
        <Content styleName="proposal-content-success">
          <img className="proposal-img" src="/icons/checked_green.svg" alt="" />

          <div className="thanks-content">
            <div className="thanks-content_title-block">
              <div className="thanks-content_title-block_title">Thank you!</div>
              <div className="thanks-content_title-block_subtitle">
                You're one step closer to getting an unsecured loan with Caribbean Microfinance. Your application will
                be reviewed and you will receive an update within 1 working day.
              </div>
            </div>
            <div className="thanks-content_actions">
              <Button
                aria-label="I Will Think About It"
                type="button"
                width="100%"
                onClick={onReturnToAccountClick}
                variant="primary-new-green"
                className="form-submit"
              >
                Return to My Account
              </Button>
            </div>
          </div>
        </Content>
      </main>
    </div>
  );
};

export default ThanksPage;
