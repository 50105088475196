import * as Yup from "yup";
import { debounce } from "lodash";
import { formikAsyncValidator } from "../utils/validators";

Yup.addMethod(Yup.mixed, "asyncEmailValidation", function (product_id: number | null) {
  //@ts-ignore
  return this.test(
    "asyncEmailValidation",
    "Sorry, we can’t seem to find a quote associated with this email",
    (value: string) => {
      return new Promise((resolve, reject) =>
        debounce(formikAsyncValidator, 500)({ product_id, email: value }, resolve, reject),
      );
    },
  );
});

const FORMS = (product_id: number | null) => ({
  ALREADY_STARTED: {
    INIT: {
      email: "",
    },
    SCHEMA: Yup.object().shape({
      email: Yup.string()
        .email("The entered email is not valid")
        .required("This field is required")
        //@ts-ignore
        .asyncEmailValidation(product_id),
    }),
  },
});

export default FORMS;
