import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormikValues } from "formik";

import { IProduct } from "../../../interfaces";
import { resetUserState, verifyUserEmail } from "../../../../Auth/store/actions";
import Button from "../../../../../shared/components/styled/Button";

import "./styles.scss";
import VerifyEmailModal from "./VerifyEmailModal";

interface IProductCardDescriptionProps {
  product: IProduct;
}

const VerifyEmail: React.FunctionComponent<IProductCardDescriptionProps> = ({ product }) => {
  const dispatch = useDispatch();
  const [showVerifyEmailModal, setVerifyEmailModalStatus] = useState<boolean>(false);

  const handleSubmit = (values: FormikValues) => {
    dispatch(verifyUserEmail.request({ email: values.email, product_id: product.id }));
    setVerifyEmailModalStatus(false);
  };

  const handleClose = () => {
    dispatch(resetUserState());
    setVerifyEmailModalStatus(false);
  };

  return (
    <>
      <Button
        className="already-started-btn product-card-footer__second-button"
        aria-label="Already Started a Quote?"
        variant="secondary-new-green"
        type="button"
        width="100%"
        onClick={() => setVerifyEmailModalStatus(true)}
      >
        Already Started a Quote?
      </Button>
      <VerifyEmailModal
        showVerifyEmailModal={showVerifyEmailModal}
        product={product}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </>
  );
};

export default VerifyEmail;
