import { Reducer } from "redux";
import { LoaderActionTypes } from "./constants";
import produce from "immer";

interface LoaderState {
  loading: 0 | 1;
}

const initialState: LoaderState = {
  loading: 0,
};

const reducer: Reducer<LoaderState> = (state: LoaderState = initialState, action) => {
  switch (action.type) {
    case LoaderActionTypes.START_LOADER:
      return produce(state, (nextState) => {
        nextState.loading = 1;
      });
    case LoaderActionTypes.STOP_LOADER:
      return produce(state, (nextState) => {
        nextState.loading = 0;
      });
    default:
      return state;
  }
};

export { reducer as LoaderReducer };
