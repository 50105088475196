import React, { useState, useEffect } from "react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { getAge } from "../../QtnSection/utils";
import "./styles.scss";
import { IDateQuestionProps } from "../../../interfaces";

const DateQuestion: React.FunctionComponent<IDateQuestionProps> = ({
  updateSectionError,
  onBlur,
  integration_name,
  form: { touched, errors, setFieldError },
  field: { value, onChange },
  size,
  showErrorComponent,
  is_possible_past,
  is_possible_future,
}) => {
  const [min, setMin] = useState<any>(new Date());
  const [max, setMax] = useState<any>(new Date());

  useEffect(() => {
    if (is_possible_future) {
      setMax(new Date().setFullYear(new Date().getFullYear() + 50));
    }

    if (is_possible_past) {
      setMin(new Date().setFullYear(1800));
    }
  }, [is_possible_past, is_possible_future]);

  const getDate = (date: Date | null) => {
    return date ? new Date(date) : null;
  };

  const handleDateChange = (date: Date | null) => {
    const updatedValue = {
      target: { name: integration_name, value: date },
    };

    /**
     * This is a quick temporary solution that we will change at the stage of creating the questionnaire builder
     */
    // @ts-ignore
    if (integration_name === "dob" && date && moment(date).isValid) {
      onChange({ target: { name: "age", value: String(getAge(date)) } });
    }

    onChange(updatedValue);
    setFieldError(integration_name, "");
    updateSectionError(integration_name);
    onBlur(updatedValue);
  };

  return (
    <>
      <div id={integration_name} className={`date-field ${size}`}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="dense"
            disableFuture={!is_possible_future}
            keyboardIcon={<img src="/icons/calendar_green_new.svg" alt="Datepicker" />}
            inputVariant="outlined"
            id="date-picker-dialog"
            name={integration_name}
            format="dd/MM/yyyy"
            openTo="year"
            views={["year", "month", "date"]}
            placeholder="DD/MM/YYYY"
            value={getDate(value ? value[integration_name] : null)}
            onChange={handleDateChange}
            minDate={min}
            maxDate={max}
            onClose={() => (document.documentElement.style.overflow = "auto")}
            onOpen={() => (document.documentElement.style.overflow = "hidden")}
          />
        </MuiPickersUtilsProvider>
        {showErrorComponent ? (
          <div className="table-error">{touched[integration_name] && errors[integration_name]}</div>
        ) : null}
      </div>
    </>
  );
};

export default DateQuestion;
