import { API, METHODS } from "../../../shared/constants";
import { request } from "../../../shared/utils";

export default {
  fetchProducts: <T>(isTesting: boolean, uniqLink?: string, integration_name?: string, type?: number): T =>
    request(METHODS.GET, API.FETCH_PRODUCTS(isTesting, uniqLink, integration_name, type))(),
  fetchOrganizations: () => request(METHODS.GET, API.FETCH_ORGANIZATIONS())(),
  fetchAdvisor: (personal_code: string) => request(METHODS.GET, API.FETCH_ADVISOR(personal_code))(),
  fetchOrganizationByUserEmail: (email: string) => request(METHODS.GET, API.FETCH_ORGANIZATION_BY_USER_EMAIL(email))(),
};
