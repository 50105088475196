export default {
  MAIN: "/",
  ADVISOR_CODE: "/:advisorCode",
  PRODUCT: "/product/:id/:session?",
  PROPOSAL: "/proposal",
  CONSUMER_LOAN_PROPOSAL: "/consumer-loan/:consumerLink/proposal",
  POSSIBLE_RATE: "/possible-rate/:id?",
  POSSIBLE_RATE_CAR: "/possible-rate-car/:id?",
  POSSIBLE_RATE_MORTGAGE: "/possible-rate-mortgage/:id?",
  LOGIN: "/login",
  VERIFY: "/verify",
  SORRY_PAGE: "/sorry",
  PRE_APPROVE: "/pre-approve",
  THANKS_PAGE: "/thanks",
  SERVE_PAGE: "/serve",
  MOBILE_UPLOAD: "/mobile-upload/:sessionId/question/:integrationName",
  CONSUMER_LOAN: "/consumer-loan/:consumerLink",
  CONSUMER_LOAN_PRODUCT: "/consumer-loan/:consumerLink/product/:id/:session?",
  CONSUMER_LOAN_POSSIBLE_RATE: "/consumer-loan/:consumerLink/possible-rate",
};
