import React from "react";
import classnames from "classnames";

import classNames from "./styles.module.scss";

interface INumberFieldProps {
  label: string;
  name: string;
  value?: string;
  parentClassName?: string;

  onChange: (e: React.ChangeEvent<any>) => void;
}

const NumberField: React.FunctionComponent<INumberFieldProps> = (props) => {
  const { onChange, value, label, name, parentClassName = "" } = props;

  return (
    <div className={classnames(parentClassName, [classNames.numberField])}>
      <label htmlFor={name} className={classNames.label}>
        {label}
      </label>
      <input className={classNames.input} type="number" value={value || ""} onChange={onChange} name={name} id={name} />
    </div>
  );
};

export default NumberField;
