import React, { useState, useRef, useEffect, ChangeEvent, useCallback, useMemo } from "react";
import { orderBy } from "lodash";
import classnames from "classnames";
import { ISmartQtnOption } from "../../../interfaces";
import { useDebounce } from "../../../../../shared/hooks/";
import { isArray } from "util";
import "./styles.scss";

const SmartTypingQuestion: React.FunctionComponent<any> = ({
  onBlur,
  updateSectionError,
  clearValue,
  updatedFollowupIds,
  questionChildrenIds,
  id,
  typingOptions,
  selectEffect,
  integration_name,
  field: { value, onChange },
  form: { touched, errors, setFieldError, setFieldTouched },
  size,
  showErrorComponent,
  parent,
  disabled,
}) => {
  const [searchValue, setSearchValue] = useState(value[integration_name] || "");
  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);
  const ref = useRef(null);
  const shouldSearch = useRef(true);

  useEffect(() => {
    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value[integration_name] && !searchValue && !touched[integration_name]) {
      setSearchValue(value[integration_name]);
    }
    return () => {};
  }, [integration_name, searchValue, touched, value]);

  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (ref && ref.current && !ref.current.contains(event.target)) {
      setFilteredOptions([]);
    }
  };

  const debounceSearch = useDebounce(searchValue, 500, 3);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleSelect = useCallback(
    (opt: { id?: number; name: string }) => {
      const updatedValue = { target: { name: integration_name, value: opt.name } };
      opt.id && updatedFollowupIds && updatedFollowupIds(Number(opt.id), typingOptions);
      selectEffect && selectEffect(opt.id || 0);
      onChange(updatedValue);
      updateSectionError && updateSectionError(integration_name);
      onBlur(updatedValue);
      setFieldError(integration_name, "");
      shouldSearch.current = false;
      if (!parent) {
        /**
         * Create clear fn for tableQuestions
         */
        clearValue && clearValue(questionChildrenIds[id], integration_name);
      }
      opt.id && setSearchValue(opt.name);
      setFilteredOptions([]);
    },
    [
      clearValue,
      id,
      integration_name,
      onBlur,
      onChange,
      parent,
      questionChildrenIds,
      selectEffect,
      setFieldError,
      typingOptions,
      updateSectionError,
      updatedFollowupIds,
    ],
  );

  useEffect(() => {
    const MIN_LETTERS = 3;
    if (!debounceSearch) {
      selectEffect(null);
      setFilteredOptions([]);
    }
    if (debounceSearch && debounceSearch.length >= MIN_LETTERS && shouldSearch.current && searchValue) {
      const results = [...(isArray(typingOptions) ? typingOptions : [])].filter((option: ISmartQtnOption) => {
        const regex = new RegExp(`[a-zA-Z]*${debounceSearch}[a-zA-Z]*`, "gi");
        return option.name.match(regex);
      });
      results.length ? setFilteredOptions([...results]) : handleSelect({ name: debounceSearch });
    }
    return () => {};
  }, [debounceSearch, handleSelect, searchValue, typingOptions, selectEffect]);

  const filterOptions = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      shouldSearch.current = true;
      setSearchValue(value);
      touched[integration_name] || setFieldTouched(integration_name, true, false);
    },
    [integration_name, setFieldTouched, touched],
  );

  const sortedOptions = useMemo(() => orderBy(filteredOptions, ["order"], ["asc"]), [filteredOptions]);

  return (
    <>
      <div ref={ref} className={classnames(`smart-typing-container ${size}`, { open: filteredOptions.length })}>
        <input
          className={classnames("smart-typing", { open: filteredOptions.length && debounceSearch && !disabled })}
          onChange={filterOptions}
          type="text"
          name={integration_name}
          placeholder="Start typing"
          value={searchValue}
          autoComplete="off"
          disabled={disabled}
        />

        {filteredOptions.length && touched[integration_name] ? (
          <div className={classnames(`smart-typing-options ${size}`)}>
            {sortedOptions.map((opt: ISmartQtnOption) => (
              <div onClick={() => handleSelect(opt)} key={opt.id} className={classnames(`option-item ${size}`)}>
                {opt.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {showErrorComponent ? (
        <div className="table-error">{touched[integration_name] && errors[integration_name]}</div>
      ) : null}
    </>
  );
};

export default SmartTypingQuestion;
