import { CONSUMER_LOAN_TYPES_OPTIONS } from "../../../../../shared/constants";
import { IProduct, IProductTerm } from "../../../interfaces";

type PaymentCalculationData = Omit<Partial<IProduct>, "term"> & {
  option_type?: CONSUMER_LOAN_TYPES_OPTIONS;
  amount?: number;
  term?: number | IProductTerm[];
};

export class PaymentCalculation {
  payment = null;
  constructor(data: any) {
    this.payment = data;
  }
  static from = (_product: PaymentCalculationData) => {
    if (!_product) {
      return new PaymentCalculation(null);
    } else {
      const term = Number(_product?.loan_settings?.default_term) || _product.term,
        amount = _product?.amount || Number(Math.round((_product.amount_max || 0) / 2)),
        ratePerMonth = Number(_product?.rate_max) / 12,
        negotiationFees = _product.negotiation_max,
        type = Number(_product.option_type) || CONSUMER_LOAN_TYPES_OPTIONS.DISBURSEMENT;
      return new PaymentCalculation({
        totalFees: 0,
        monthlyPayment: 0,
        totalBalance: 0,
        totalInterest: 0,
        negotiationFees,
        term,
        amount,
        ratePerMonth,
        type,
      });
    }
  };

  map = (fn: Function) => {
    return new PaymentCalculation(fn(this.payment));
  };
  unwrap = () => {
    return this.payment;
  };
}
