import { QtnActionTypes } from "./constants";
import { createAsyncAction, createAction } from "typesafe-actions";
import { IQtnResponse, ILoanQuoteUserAnswers, IQuoteRate, ILoanQuotePatchRequest } from "../interfaces";

export const getQtn = createAsyncAction(
  QtnActionTypes.FETCH_QTN_REQUEST,
  QtnActionTypes.FETCH_QTN_SUCCESS,
  QtnActionTypes.FETCH_QTN_FAILURE,
)<{ id: number | string; sessionId: string }, any, string>();

export const setQtnSection = createAsyncAction(
  QtnActionTypes.SET_QTN_SECTION_REQUEST,
  QtnActionTypes.SET_QTN_SECTION_SUCCESS,
  QtnActionTypes.SET_QTN_SECTION_FAILURE,
)<undefined, any, string>();

export const fetchLoanQuote = createAsyncAction(
  QtnActionTypes.FETCH_LOAN_QUOTE_REQUEST,
  QtnActionTypes.FETCH_LOAN_QUOTE_SUCCESS,
  QtnActionTypes.FETCH_LOAN_QUOTE_FAILURE,
)<{ sessionId: string; callback?: () => void }, IQtnResponse | null, string>();

export const createLoanQuote = createAsyncAction(
  QtnActionTypes.CREATE_LOAN_QUOTE_REQUEST,
  QtnActionTypes.CREATE_LOAN_QUOTE_SUCCESS,
  QtnActionTypes.CREATE_LOAN_QUOTE_FAILURE,
)<{ data: ILoanQuoteUserAnswers; id: number | string; callback?: () => void }, IQtnResponse, string>();

export const updateLoanQuote = createAsyncAction(
  QtnActionTypes.UPDATE_LOAN_QUOTE_REQUEST,
  QtnActionTypes.UPDATE_LOAN_QUOTE_SUCCESS,
  QtnActionTypes.UPDATE_LOAN_QUOTE_FAILURE,
)<{ data: ILoanQuoteUserAnswers; id: number | string; callback?: () => void }, any, string>();

export const patchLoanQuote = createAsyncAction(
  QtnActionTypes.PATCH_LOAN_QUOTE_REQUEST,
  QtnActionTypes.PATCH_LOAN_QUOTE_SUCCESS,
  QtnActionTypes.PATCH_LOAN_QUOTE_FAILURE,
)<ILoanQuotePatchRequest & { callback: () => void; sendEmail?: boolean }, IQtnResponse, string>();

export const updateDraftLoanQuote = createAsyncAction(
  QtnActionTypes.UPDATE_DRAFT_LOAN_QUOTE_REQUEST,
  QtnActionTypes.UPDATE_DRAFT_LOAN_QUOTE_SUCCESS,
  QtnActionTypes.UPDATE_DRAFT_LOAN_QUOTE_FAILURE,
)<{ data: ILoanQuoteUserAnswers; id: number; isWelcomeEmail?: boolean; callback?: () => void }, any, string>();

export const calculateQuote = createAsyncAction(
  QtnActionTypes.CALCULATE_QUOTE_REQUEST,
  QtnActionTypes.CALCULATE_QUOTE_SUCCESS,
  QtnActionTypes.CALCULATE_QUOTE_FAILURE,
)<{ id: number | string; type?: string; advisorId?: number; callback?: () => void }, any, string>();

export const updateQuoteStatus = createAsyncAction(
  QtnActionTypes.UPDATE_QUOTE_STATUS_REQUEST,
  QtnActionTypes.UPDATE_QUOTE_STATUS_SUCCESS,
  QtnActionTypes.UPDATE_QUOTE_STATUS_FAILURE,
)<{ id: number | string; status: number; callback?: () => void }, any, string>();

export const setQuoteRate = createAction(QtnActionTypes.SET_QUOTE_RATE)<IQuoteRate | null>();
export const updSectionProgress = createAction(QtnActionTypes.UPDATE_SECTION_PROGRESS)<any>();
export const updFollowup = createAction(QtnActionTypes.UPDATE_FOLLOW_UP)<any>();
export const updFollowupWithSection = createAction(QtnActionTypes.UPDATE_FOLLOW_UP_WITH_SECTION)<any>();
export const updateTableQuestionsState = createAction(QtnActionTypes.UPDATE_TABLE_QESTIONS_STATE)<any>();
export const saveLastFormikState = createAction(QtnActionTypes.SAVE_LAST_FROMIK_STATE)<any>();
export const setInitialQtnValues = createAction(QtnActionTypes.SET_QTN_INITIAL_VALUES)<any>();
export const setOrganizationQtnValues = createAction(QtnActionTypes.SET_QTN_ORGANIZATION)<number>();

export const clearQuote = createAsyncAction(
  QtnActionTypes.CLEARE_QUOTE_AFTER_REJECT_REQUEST,
  QtnActionTypes.CLEARE_QUOTE_AFTER_REJECT_SUCCESS,
  QtnActionTypes.CLEARE_QUOTE_AFTER_REJECT_FAILURE,
)<{ data: any; callback?: () => void }, any, string>();

export const fetchLoanQuoteById = createAsyncAction(
  QtnActionTypes.FETCH_LOAN_QUOTE_BY_ID_REQUEST,
  QtnActionTypes.FETCH_LOAN_QUOTE_BY_ID_SUCCESS,
  QtnActionTypes.FETCH_LOAN_QUOTE_BY_ID_FAILURE,
)<{ id: number | string; calculate?: boolean; callback?: () => void }, IQtnResponse, string>();

export const removeUploadedFile = createAction(QtnActionTypes.REMOVE_UPLOADED_FILE)<any>();

export const updateLoanBeforeUnmountQuote = createAsyncAction(
  QtnActionTypes.UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_REQUEST,
  QtnActionTypes.UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_SUCCESS,
  QtnActionTypes.UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_FAILURE,
)<{ data: null; id: number | string; callback?: () => void }, any, string>();
