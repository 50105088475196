export enum CreateSocketActionTypes {
  CREATE_SOCKET_CONNECTION_REQUEST = "@@APP/CREATE_SOCKET_CONNECTION_REQUEST",
  CREATE_SOCKET_CONNECTION_SUCCESS = "@@APP/CREATE_SOCKET_CONNECTION_SUCCESS",
  CREATE_SOCKET_CONNECTION_FAILURE = "@@APP/CREATE_SOCKET_CONNECTION_FAILURE",
}

export enum CloseSocketActionTypes {
  CLOSE_SOCKET_CONNECTION_REQUEST = "@@APP/CLOSE_SOCKET_CONNECTION_REQUEST",
  CLOSE_SOCKET_CONNECTION_SUCCESS = "@@APP/CLOSE_SOCKET_CONNECTION_SUCCESS",
  CLOSE_SOCKET_CONNECTION_FAILURE = "@@APP/CLOSE_SOCKET_CONNECTION_FAILURE",
}

export enum UploadMobileImageActionTypes {
  UPLOAD_MOBILE_IMAGE_REQUEST = "@@APP/UPLOAD_MOBILe_IMAGE_REQUEST",
  UPLOAD_MOBILE_IMAGE_SUCCESS = "@@APP/UPLOAD_MOBILe_IMAGE_SUCCESS",
  UPLOAD_MOBILE_IMAGE_FAILURE = "@@APP/UPLOAD_MOBILe_IMAGE_FAILURE",
}

export enum AttachMobileImageActionTypes {
  ATTACH_MOBILE_IMAGE_REQUEST = "@@APP/ATTACH_MOBILE_IMAGE_REQUEST",
  ATTACH_MOBILE_IMAGE_SUCCESS = "@@APP/ATTACH_MOBILE_IMAGE_SUCCESS",
  ATTACH_MOBILE_IMAGE_FAILURE = "@@APP/ATTACH_MOBILE_IMAGE_FAILURE",
}
