import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { getOrganizationById } from "../../containers/Products/store/selectors";
import { getLoanQuoteOrganizationId } from "../../containers/Qtn/store/selectors";
import { IProductOrganization } from "../../containers/Products/interfaces";

export default function useOrganization() {
  const organizationSelector = useSelector(getOrganizationById, isEqual);
  const organizationId = useSelector(getLoanQuoteOrganizationId, isEqual);
  const [quoteOrganization, setQuoteOrganization] = useState<IProductOrganization | undefined>(undefined);
  useEffect(() => {
    if (organizationId) {
      const organization = organizationSelector(organizationId);
      setQuoteOrganization(organization);
    } else {
      setQuoteOrganization(undefined);
    }
    return () => {};
  }, [organizationId, organizationSelector]);

  return quoteOrganization;
}
