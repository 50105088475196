import React from "react";
import Button from "../../../../shared/components/styled/Button";
import { useSelector, useDispatch } from "react-redux";
import { getSectionProgress } from "../../../../containers/Qtn/store/selectors";
import { verifyUserEmail } from "../../../../containers/Auth/store/actions";
import ModalComponent from "../ModalComponent/ModalComponent";

interface IVerifyModalModalProps {
  show: boolean;
  setModalStatus: (value: boolean) => void;
  product_id: number;
}

const VerifyModal: React.FunctionComponent<IVerifyModalModalProps> = ({ show, setModalStatus, product_id }) => {
  const sectionProgress = useSelector(getSectionProgress());
  const dispatch = useDispatch();

  const sendVerificationEmail = () => {
    if (sectionProgress && sectionProgress.email) {
      dispatch(verifyUserEmail.request({ email: sectionProgress.email, product_id }));
      setModalStatus(false);
    }
  };

  return (
    <ModalComponent
      show={show}
      title=" Looks like you already have some answers from your previous try"
      msg="Please verify your email to continue."
      imgSrc="/icons/information.svg"
      truncateTitleWidth
    >
      <Button
        className="modal-actions"
        width="100%"
        variant="outlined-primary-new-green"
        aria-label="Verify"
        type="button"
        onClick={sendVerificationEmail}
      >
        Verify
      </Button>
    </ModalComponent>
  );
};

export default VerifyModal;
