import React from "react";

import "./styles.scss";

interface IProductCardDescriptionProps {
  description: string;
}

const ProductCardDescription: React.FunctionComponent<IProductCardDescriptionProps> = ({ description }) => {
  const properties = description.split("\n").filter((description) => !!description);

  return (
    <div className="product-card-content">
      {properties.map((property, key) => (
        <div key={key} className="product-card-content__property">
          <div className="product-card-content__property-check">
            <img src={"/icons/check-green.svg"} alt="check" />
          </div>
          <div className="product-card-content__property-text" dangerouslySetInnerHTML={{ __html: property }} />
        </div>
      ))}
    </div>
  );
};

export default ProductCardDescription;
