import React, { useCallback, useMemo } from "react";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, RouteChildrenProps, RouteComponentProps } from "react-router";
import { push } from "connected-react-router";
import Button from "../../../../shared/components/styled/Button";
import { getQuote } from "../../../Qtn/store/selectors";
import { ROUTES } from "../../../../shared/constants";
import { QUOTE_TYPES } from "../../../Qtn/constants";
import { updateQuoteStatus } from "../../../Qtn/store/actions";
import { checkUserLoggedIn, removeUserFromLocalStorage } from "../../../../shared/utils";

import "./styles.scss";
import ProposalRateItem from "./ProposalRateItem";

interface IProposalRateProps {
  match: {
    params: {
      quoteId: number | string;
    };
  };
  title: string;
  subtitle: string;
}

const ProposalRateConsumerLoan: React.FunctionComponent<
  IProposalRateProps & RouteChildrenProps & RouteComponentProps
> = ({
  match: {
    params: { quoteId },
  },
  title,
  subtitle,
}) => {
  const quote = useSelector(getQuote());

  const dispatch = useDispatch();
  const navigateTo = useCallback((page) => dispatch(push(`${ROUTES.PROPOSAL}/${quoteId}${page}`)), [dispatch, quoteId]);

  const userAlreadyLoggedIn = useMemo(() => checkUserLoggedIn(), []);

  const onProceedWithThisLoanRequest = useCallback(() => {
    return userAlreadyLoggedIn
      ? dispatch(
          updateQuoteStatus.request({
            status: QUOTE_TYPES.FIRST_VERIFICATION,
            id: quoteId,
            callback: () => {
              removeUserFromLocalStorage();
              navigateTo.call(null, ROUTES.THANKS_PAGE);
            },
          }),
        )
      : navigateTo.call(null, ROUTES.PRE_APPROVE);
  }, [dispatch, navigateTo, quoteId, userAlreadyLoggedIn]);

  const onIwillThinkAboutItClick = useCallback(() => {
    dispatch(push(`${ROUTES.PROPOSAL}/${quoteId}${ROUTES.SERVE_PAGE}`));
  }, [dispatch, quoteId]);

  return (
    <>
      {quote && (
        <div className="proposal-rate">
          <div className="proposal-rate_title-block">
            <div className="proposal-rate_title-block_title">{title}</div>
            <div className="proposal-rate_title-block_subtitle">{subtitle}</div>
          </div>
          <div className="proposal-rate_rate-items-wrapper">
            <div className="rate">
              <ProposalRateItem
                title="Monthly Payment"
                value={
                  <>
                    $
                    <NumberFormat
                      value={Math.round(quote.monthly_payment).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                }
              />
              <ProposalRateItem
                title="Total Fees"
                value={
                  <>
                    $
                    <NumberFormat
                      value={Math.round(quote.total_fees).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                }
              />
            </div>
            <div className="rate">
              <ProposalRateItem
                title="Total Interest"
                value={
                  <>
                    $
                    <NumberFormat
                      value={Math.round(quote.total_interest).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                }
              />
              <ProposalRateItem
                title="Total Amount"
                value={
                  <>
                    $
                    <NumberFormat
                      value={Math.round(quote.total_amount_repaid).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </>
                }
              />
            </div>
          </div>
          <div className="proposal-rate_rate-actions">
            <Button
              aria-label="Proceed With This Option"
              type="button"
              variant="primary-new-green"
              width="100%"
              onClick={onProceedWithThisLoanRequest}
              className="form-submit"
            >
              Proceed With This Loan Request
            </Button>
            <Button
              aria-label="I will think about it"
              type="button"
              variant="outlined-primary-new-green"
              width="100%"
              onClick={onIwillThinkAboutItClick}
            >
              I Will Think About It
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(ProposalRateConsumerLoan);
