import React, { FC, ReactNode, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isArray } from "lodash";
import { Field, FieldProps, FieldArray } from "formik";
import { IQtnQuestion, IQtnFormProps } from "../../../../containers/Qtn/interfaces";
import { updSectionProgress } from "../../../../containers/Qtn/store/actions";
import { getSectionProgress } from "../../../../containers/Qtn/store/selectors";
import { QUESTION_TYPES } from "../../../../containers/Qtn/constants";
import Tooltip from "../Tooltip/Tooltip";
import { checkUserLoggedIn } from "../../../utils";

import "./styles.scss";
import classnames from "classnames";
import { deviceDetect } from "../../../../utils/resizeListener/store/selectors";
import moment from "moment";
import { getAge } from "../../../../containers/Qtn/components/QtnSection/utils";

interface IQuestionProps extends IQtnFormProps {
  children: ReactNode;
  key: number | string;
  question: IQtnQuestion;
  clearValue: (questions: number[], value: string) => void;
  updatedFollowupIds: <T>(value: number, options: T[]) => void;
  setSectionErrors: (data: string[]) => void;
  sectionErrors: string[];
  lastValidationState: any;
}

interface IQuestionFieldHelpBlockProps {
  description: string;
}

const QuestionFieldHelpBlock: FC<IQuestionFieldHelpBlockProps> = ({ description }) => (
  <Tooltip className="question-tooltip" scrollElementId="section-body" title={description}>
    <span className="question-info-icon" />
  </Tooltip>
);

const QuestionField: React.FunctionComponent<IQuestionProps> = ({
  question,
  children,
  clearValue,
  updatedFollowupIds,
  setSectionErrors,
  sectionErrors,
  lastValidationState,
  ...rest
}) => {
  const { title, is_required, integration_name } = question;
  const sectionProgress = useSelector(getSectionProgress());
  const dispatch = useDispatch();
  const { isMobile } = useSelector(deviceDetect());

  const updateSectionProgress = (e: any) => {
    const { name, value } = e.target;
    const updatedProgress = { ...sectionProgress };

    if (name === "dob" && value && moment(value).isValid) {
      updatedProgress["age"] = String(getAge(value));
    }

    updatedProgress[name] = value;
    dispatch(updSectionProgress(updatedProgress));
  };

  const updateSectionError = (field: string) => {
    setSectionErrors([...sectionErrors].filter((err) => err !== field));
  };

  const getError = (touched: any, errors: any) => {
    if (touched[integration_name] && errors[integration_name]) {
      return (
        <div className="required">
          {isArray(errors[integration_name])
            ? errors[integration_name][0]?.value
            : typeof errors[integration_name] === "object"
            ? "This field is required"
            : errors[integration_name]}
        </div>
      );
    }

    if (sectionErrors.includes(integration_name)) {
      return (
        <div className="required">
          {lastValidationState && lastValidationState[integration_name]
            ? typeof lastValidationState[integration_name] === "object"
              ? "Invalid value format"
              : lastValidationState[integration_name]
            : "This field is required"}
        </div>
      );
    }

    return null;
  };

  const userAlreadyLoggedIn = useMemo(() => checkUserLoggedIn(), []);

  return question.question_type.type === QUESTION_TYPES.TABLE ? (
    <FieldArray
      name={question.integration_name}
      render={() => {
        return (
          <div id={`${question.id}`} className="question-container">
            <label className="question-label">
              {is_required ? <div className="required">*&nbsp;</div> : <div className="required">{""}</div>}
              <span dangerouslySetInnerHTML={{ __html: title as string }} />
              {question.description && <QuestionFieldHelpBlock description={question.description} />}
            </label>

            {React.cloneElement(children as React.ReactElement<any>, {
              ...question,
              clearValue,
              updateSectionError,
              updatedFollowupIds,
              sectionErrors,
              onBlur: updateSectionProgress,
              field: { value: rest.values, onChange: rest.handleChange },
              form: { ...rest },
            })}
            {getError(rest.touched, rest.errors)}
          </div>
        );
      }}
    />
  ) : (
    <Field>
      {(field: FieldProps) => {
        const {
          form: { touched, errors },
        } = field;
        const isDisabledField =
          (question.integration_name === "email" && userAlreadyLoggedIn) || question.integration_name === "employers";

        return (
          <div id={`${question.id}`} className="question-container">
            {question.question_type.type === QUESTION_TYPES.TABLE ? null : (
              <label className="question-label">
                {is_required ? (
                  <div className={classnames("required", { mobile: isMobile })}>*&nbsp;</div>
                ) : (
                  <div className={classnames("required", { mobile: isMobile })}>{""}</div>
                )}
                <span dangerouslySetInnerHTML={{ __html: title as string }} />
                {question.description && <QuestionFieldHelpBlock description={question.description} />}
              </label>
            )}
            {React.cloneElement(children as React.ReactElement<any>, {
              ...field,
              ...question,
              clearValue,
              updateSectionError,
              updatedFollowupIds,
              sectionErrors,
              onBlur: updateSectionProgress,
              disabled: isDisabledField,
            })}
            {getError(touched, errors)}
          </div>
        );
      }}
    </Field>
  );
};

export default QuestionField;
