import React, { FC, useCallback } from "react";

import "./styles.scss";
import Button from "../../styled/Button";
import ProductSelector from "../ProductSelector/ProductSelector";
import { IProduct } from "../../../../containers/Products/interfaces";

interface IMobileSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
  products?: IProduct[];
  selectedProduct?: IProduct;
  setSelectedProduct?: any;
  showProductSelector?: boolean;
}

const MobileSidebar: FC<IMobileSidebarProps> = (props) => {
  const { isOpen, onClose, onLogin, showProductSelector, products, selectedProduct, setSelectedProduct } = props;
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleLogin = useCallback(() => {
    onLogin();
  }, [onLogin]);

  return isOpen ? (
    <div className="mobile-sidebar">
      <>
        <div className="mobile-sidebar_overlay" onClick={handleClose} />
        <div className="mobile-sidebar_content-wrapper">
          <div className="mobile-sidebar_content-wrapper_content">
            <div className="mobile-sidebar_content-wrapper_content_header">
              <img className="logo" src="/images/logo_white.svg" alt="Logo" onClick={handleClose} />
              <img className="close" src="/icons/close_white.svg" alt="Close" onClick={handleClose} />
            </div>
            <div className="mobile-sidebar_content-wrapper_content_product-selector">
              {showProductSelector ? (
                <ProductSelector
                  products={products}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                />
              ) : null}
            </div>
          </div>
          <div className="mobile-sidebar_content-wrapper_footer">
            <Button
              aria-label="Log in"
              type="button"
              onClick={handleLogin}
              width={80}
              className="mobile-sidebar_content-wrapper_footer_login-button"
            >
              <img className="login" src="/icons/login_white.svg" alt="Login" /> <span>Log In</span>
            </Button>
          </div>
        </div>
      </>
    </div>
  ) : null;
};

export default MobileSidebar;
