import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { removeSnackbar } from "../../store/actions";
import { INotification } from "../../interface";
import { getNotification } from "../../store/selectors";
import NotistackContent from "../NotistackContent/NotistackContent";

export type SnackbarProps = {
  enqueueSnackbar: (message: string, variant: string) => void;
};

const NotistackContainer: React.FunctionComponent<SnackbarProps & WithSnackbarProps> = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const snackbars = useSelector(getNotification());

  useEffect(() => {
    snackbars.forEach((snackbar: INotification) => {
      if (!snackbar.key) return;

      enqueueSnackbar(<NotistackContent {...snackbar} />, { variant: snackbar.variant });
      dispatch(removeSnackbar(snackbar.key));
    });
  }, [snackbars, dispatch, enqueueSnackbar]);

  return <Fragment />;
};

export default withSnackbar(NotistackContainer);
