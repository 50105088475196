import React, { useMemo } from "react";
import { orderBy } from "lodash";
import { ISelectQuestionProps } from "../../../interfaces";
// tslint:disable-next-line: max-line-length
import { calcLoanAmount } from "../../../../Products/components/ProductConsumerTypeLoanOptions/utils/calculateFunctions";
import classnames from "classnames";
import "./styles.scss";
import SingleChoiceQuestionLoanPreviewItem from "./SingleChoiceQuestionLoanPreviewItem";

const SingleChoiceQuestion: React.FunctionComponent<ISelectQuestionProps> = ({
  updateSectionError,
  integration_name,
  id,
  options,
  onBlur,
  field: { value, onChange },
  form: { setFieldError, setFieldValue },
  questionChildrenIds,
  clearValue,
  updatedFollowupIds,
  updateSectionErrors,
  scrollToFollowUpQuestion,
  optionEffect,
  previewCalculation,
  quote,
  size,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, optionType?: number) => {
    updatedFollowupIds(Number(e.target.value), options);
    clearValue(questionChildrenIds[id], integration_name);
    onChange(e);
    setFieldError(integration_name, "");
    updateSectionError(integration_name);
    optionEffect && optionType && optionEffect(optionType);

    if (updateSectionErrors) {
      updateSectionErrors(Number(e.target.value), integration_name);
    }

    onBlur(e);
    if (scrollToFollowUpQuestion) {
      scrollToFollowUpQuestion(Number(e.target.value), options);
    }
  };

  const iReceive = useMemo(() => {
    if (previewCalculation) {
      const opt: any = options.find((opt): boolean | undefined => {
        if (value && value[integration_name]) {
          return opt.id === Number(value[integration_name]);
        }
        return false;
      });
      const type = opt && opt.optionType;
      return calcLoanAmount(value.amount, quote?.total_fees, type);
    }
  }, [integration_name, options, previewCalculation, quote, value]);

  return (
    <div id={integration_name} className={size}>
      <React.Fragment>
        {orderBy(options, ["order"], ["asc"]).map((opt: any) => {
          if (opt.default && !value[integration_name]) {
            setFieldValue(integration_name, opt.id);
          }
          return (
            <React.Fragment key={opt.id}>
              <label
                htmlFor={`opt-${id}-${opt.id}`}
                className={classnames("option", { selected: value[integration_name] == opt.id })} // eslint-disable-line
              >
                <img
                  className="opt-img"
                  src={
                    value[integration_name] == opt.id // eslint-disable-line
                      ? "/icons/opt_single_checked_green_new.svg"
                      : "/icons/opt_single_checked_empty_gray.svg"
                  }
                  alt="Option"
                />
                {opt.title}
              </label>
              <input
                hidden
                id={`opt-${id}-${opt.id}`}
                name={integration_name}
                type="radio"
                value={opt.id}
                onChange={(e) => {
                  handleChange(e, opt.optionType);
                }}
                onBlur={onBlur}
              />
            </React.Fragment>
          );
        })}
        {previewCalculation ? (
          <div className="loan-preview">
            <SingleChoiceQuestionLoanPreviewItem title="I Receive" value={iReceive || 0} />
            <SingleChoiceQuestionLoanPreviewItem title="Monthly Payment" value={quote?.monthly_payment || 0} />
            <SingleChoiceQuestionLoanPreviewItem title="Total Interest" value={quote?.total_interest || 0} />
            <SingleChoiceQuestionLoanPreviewItem title="Total Fees" value={quote?.total_fees || 0} />
          </div>
        ) : null}
      </React.Fragment>
    </div>
  );
};

export default SingleChoiceQuestion;
