import { Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { withRouter, RouteChildrenProps, RouteComponentProps } from "react-router";

import configElement from "../../../../config";
import { SingleChoiceCollapse } from "../../../../shared/components/common/SingleChoiceCollapse/";
import { NumberField } from "../../../../shared/components/common/NumberField";
import Button from "../../../../shared/components/styled/Button";
import { ILoanQuotePatchRequest } from "../../../Qtn/interfaces";
import Content from "../../../../shared/components/common/Content/Content";
import Header from "../../../../shared/components/common/Header/Header";
import Loader from "../../../../shared/components/common/Loader/Loader";
import { actions as qtnActions } from "../../../Qtn/store";
import { initialValues, SavePageFormValues } from "./form.helpers";
import classNames from "./styles.module.scss";
import { ROUTES } from "../../../../shared/constants";
import { QUOTE_TYPES } from "../../../Qtn/constants";
import { checkUserLoggedIn } from "../../../../shared/utils";
import ModalComponent from "../../../../shared/components/common/ModalComponent/ModalComponent";

interface IServePageProps {
  match: {
    params: {
      quoteId: number | string;
    };
  };
}

const ServePage: React.FunctionComponent<IServePageProps & RouteChildrenProps & RouteComponentProps> = (props) => {
  const {
    match: {
      params: { quoteId },
    },
  } = props;

  const dispatch = useDispatch();
  const [showModal, setModalStatus] = useState(false);

  const [isReady, setIsReady] = useState(false);

  const navigateTo = useCallback((page) => dispatch(push(`${ROUTES.PROPOSAL}/${quoteId}${page}`)), [dispatch, quoteId]);
  const userAlreadyLoggedIn = useMemo(() => checkUserLoggedIn(), []);

  const handleFormSubmit = (values: SavePageFormValues) => {
    if (quoteId) {
      if (isReady) {
        const loanQuoteRequestFields: ILoanQuotePatchRequest = {
          id: Number(quoteId),
          desired_rate: values.desiredRate || null,
          desired_instalment: values.desiredInstalment || null,
          status: QUOTE_TYPES.NEED_ADMIN_REVIEW,
        };

        dispatch(
          qtnActions.patchLoanQuote.request({
            ...loanQuoteRequestFields,
            callback: () => (userAlreadyLoggedIn ? navigateTo.call(null, ROUTES.THANKS_PAGE) : setModalStatus(true)),
            sendEmail: !userAlreadyLoggedIn,
          }),
        );
      } else {
        const loanQuoteRequestFields: ILoanQuotePatchRequest = {
          id: Number(quoteId),
          status: QUOTE_TYPES.PRE_APPROVE_NOT_READY,
        };

        dispatch(
          qtnActions.patchLoanQuote.request({
            ...loanQuoteRequestFields,
            callback: () =>
              userAlreadyLoggedIn ? navigateTo.call(null, ROUTES.THANKS_PAGE) : dispatch(push(ROUTES.MAIN)),
            sendEmail: false,
          }),
        );
      }
    }
  };

  return (
    <div className="proposal-modal-wrapper">
      <Header logo login />
      <main className="proposal-modal-success">
        <div className="proposal-body">
          <Content styleName="proposal-content-success">
            <Loader />
            <div className={classNames.servePage}>
              <div className={classNames.title}>
                Please help us
                <br /> serve you better
              </div>
              <section className={classNames.message}>
                <div>
                  Like any big decision we know that this is no small one and you may need to think about it.
                  <br />
                  If there is anything that we can do to help we are here for you.
                </div>
                <div>However, please let us know what is keeping you back from making a decision now:</div>
              </section>
              <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
                {(formikProps) => {
                  return (
                    <>
                      <div className={classNames.questions}>
                        <SingleChoiceCollapse
                          label="I am not ready to purchase and just wanted to see if I would be approved"
                          isSelected={!isReady}
                          handleSelect={() => setIsReady(false)}
                        />

                        <SingleChoiceCollapse
                          label="I am ready to purchase but the rate was higher than expected"
                          isSelected={isReady}
                          handleSelect={() => setIsReady(true)}
                        >
                          <div className={classNames.fieldsWrap}>
                            <div className={classNames.field}>
                              <NumberField
                                parentClassName={classNames.customNumberField}
                                name="desiredRate"
                                value={formikProps.values.desiredRate}
                                onChange={formikProps.handleChange}
                                label="What rate do you think you should pay?"
                              />
                            </div>
                            <div className={classNames.field}>
                              <NumberField
                                parentClassName={classNames.customNumberField}
                                name="desiredInstalment"
                                value={formikProps.values.desiredInstalment}
                                onChange={formikProps.handleChange}
                                label="What instalment you would like to pay?"
                              />
                            </div>
                          </div>
                        </SingleChoiceCollapse>
                      </div>
                      <div className={classNames.actions}>
                        <Button
                          aria-label="Submit"
                          type="button"
                          onClick={() => {
                            formikProps.handleSubmit();
                          }}
                          width="100%"
                          variant="primary-new-green"
                          className={classNames.formSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </Content>
          <div className="next_steps_modal">
            <ModalComponent
              show={showModal}
              imgSrc="/icons/checked_green.svg"
              title="Your account has been created"
              msg="An Email verification has been sent to your inbox with instructions on how to set your password"
            >
              <Button
                aria-label="Go to the login page"
                type="button"
                variant="primary-new-green"
                width="100%"
                onClick={() => window.open(configElement.maritimeUrl, "_self")}
                className="form-submit"
              >
                Go to The Login Page
              </Button>
            </ModalComponent>
          </div>
        </div>
      </main>
    </div>
  );
};
export default withRouter(ServePage);
