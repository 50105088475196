import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../../../shared/components/styled/Button";
import { deviceDetect } from "../../../../../utils/resizeListener/store/selectors";
import { getProductQtn } from "../../../store/selectors";
import { IProductQtn } from "../../../interfaces";
import { checkUserQuote } from "../../../../Auth/store/actions";
import { getAuthState } from "../../../../Auth/store/selectors";
import useDebounce from "../../../../../shared/hooks/useDebounceHook";
import SaveAndContinueQtnModal from "../../QtnModals/SaveAndContinueQtnModal/SaveAndContinueQtnModal";

import "./styles.scss";
import classnames from "classnames";

interface QtnActionsProps {
  activeSectionIdx: number;
  handleChangeQuestion: any;
  questionnareError: { [key: string]: any };

  next?: boolean;
  sectionProgress?: { [key: string]: any };

  submitAndGoBack: () => void;
  validateAndSubmit: (value: boolean) => void;
  saveDraftQuote: () => void;
  cleareFormError: (field: string) => void;
  updateFormikState: (values: any) => void;
  mainFormikBag?: any;
  updateSectionProgress?: (values: any) => void;
}

const QtnActions: React.FunctionComponent<QtnActionsProps> = ({
  submitAndGoBack,
  activeSectionIdx,
  validateAndSubmit,
  sectionProgress,
  saveDraftQuote,
  handleChangeQuestion,
  questionnareError,
  cleareFormError,
  mainFormikBag,
  updateFormikState,
  updateSectionProgress,
}) => {
  const dispatch = useDispatch();

  const { error } = useSelector(getAuthState());
  const { isMobile } = useSelector(deviceDetect());
  const qtn: IProductQtn = useSelector(getProductQtn());

  const [lastSection, setSectionStatus] = useState(false);
  const [isSaveModalOpened, setIsSaveModalOpened] = useState(false);
  const [authStateHasErrors, setAuthstateErros] = useState(false);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500, 5);

  useEffect(() => {
    error && setAuthstateErros(!!Object.keys(error).length);
  }, [error]);

  useEffect(() => {
    if (debounceSearch) {
      dispatch(checkUserQuote.request({ email: debounceSearch }));
    }
  }, [debounceSearch, dispatch]);

  useEffect(() => {
    qtn && activeSectionIdx !== undefined && setSectionStatus(activeSectionIdx === qtn.sections.length - 1);
  }, [activeSectionIdx, qtn]);

  const saveAndContinueLater = () => {
    if (sectionProgress && sectionProgress.email && !questionnareError.email) {
      const isValidEmail = Yup.string().email().isValidSync(sectionProgress.email);
      if (isValidEmail) {
        saveDraftQuote();
        return;
      }
    }

    setIsSaveModalOpened(true);
  };

  const handleSubmit = () => {
    saveDraftQuote();
    handleCloseSaveModal();
  };

  const handleCloseSaveModal = useCallback(() => {
    if (isSaveModalOpened) {
      setIsSaveModalOpened(false);
    }
  }, [isSaveModalOpened]);

  return (
    <>
      <div className="actions">
        {isMobile ? null : (
          <div className="actions_left">
            {activeSectionIdx ? (
              <Button
                aria-label="Previous"
                type="button"
                disabled={authStateHasErrors}
                width={isMobile ? 120 : 150}
                variant={authStateHasErrors ? "disabled" : "outlined-primary-new-green"}
                onClick={submitAndGoBack}
              >
                <div className="actions_left_prev-action-wrapper">
                  {<img className="icon prev-action" src="/icons/arrow_back_green_new.svg" alt="back" />} Previous
                </div>
              </Button>
            ) : null}
          </div>
        )}
        <div className="actions_right">
          {authStateHasErrors ? null : (
            <Button
              aria-label="Save & Continue Later"
              type="button"
              width={170}
              onClick={saveAndContinueLater}
              variant="secondary-new-green"
            >
              Save & Continue later
            </Button>
          )}
          <Button
            aria-label={!lastSection ? "Next" : "Finish"}
            type="button"
            disabled={authStateHasErrors}
            onClick={() => validateAndSubmit(lastSection)}
            width={isMobile ? 120 : 140}
            variant={authStateHasErrors ? "disabled" : "primary-new-green"}
            className="actions_right_next_finish_wrapper"
          >
            <div className={classnames(!lastSection ? "actions_right_next_wrapper" : "actions_right_finish_wrapper")}>
              {!lastSection ? "Next" : "Finish"}
              {!lastSection && <img className="icon next-action" src="/icons/arrow_back_white.svg" alt="back" />}
            </div>
          </Button>
        </div>
      </div>
      <SaveAndContinueQtnModal
        isSaveModalOpened={isSaveModalOpened}
        handleCloseSaveModal={handleCloseSaveModal}
        sectionProgress={sectionProgress}
        handleSubmit={handleSubmit}
        questionnareError={questionnareError}
        handleChangeQuestion={handleChangeQuestion}
        cleareFormError={cleareFormError}
        setSearch={setSearch}
        updateFormikState={updateFormikState}
        mainFormikBag={mainFormikBag}
        updateSectionProgress={updateSectionProgress}
      />
    </>
  );
};

export default QtnActions;
