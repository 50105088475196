import NumberFormat from "react-number-format";
import React, { FC } from "react";

interface ISingleChoiceQuestionLoanPreviewItemProps {
  title: string;
  value: number;
}

const SingleChoiceQuestionLoanPreviewItem: FC<ISingleChoiceQuestionLoanPreviewItemProps> = (props) => {
  const { value, title } = props;
  return (
    <div className="loan-preview_item">
      <div className="loan-preview_item_title">{title}</div>
      <div className="loan-preview_item_value">
        <NumberFormat
          value={value}
          displayType="text"
          thousandSeparator={true}
          prefix={`$${String.fromCharCode(160)}`}
        />
      </div>
    </div>
  );
};

export default SingleChoiceQuestionLoanPreviewItem;
