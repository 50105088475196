import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";

import { IProduct } from "../../../../containers/Products/interfaces";
import { useOutsideClick } from "../../../hooks";

import "./styles.scss";

interface IProductSelectorProps {
  products?: IProduct[];
  selectedProduct?: IProduct;
  setSelectedProduct?: any;
}

const ProductSelector: React.FunctionComponent<IProductSelectorProps> = ({
  products,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [isOpenedSelector, setIsOpenedSelector] = useState(false);
  const wrapperRef = useRef(null);
  const { isOutside } = useOutsideClick(wrapperRef);

  useEffect(() => {
    isOutside && setIsOpenedSelector(false);
  }, [isOutside]);

  return (
    <>
      {products && selectedProduct && (
        <div className="product-selector" ref={wrapperRef}>
          <div
            className="product-selector__value"
            onClick={() => {
              setIsOpenedSelector((prevState) => !prevState);
            }}
          >
            <div className="product-selector__value-title">Product</div>
            <div className="product-selector__value-name">{selectedProduct.title}</div>
            <div className="chevron-wrapper">
              <img className={isOpenedSelector ? "opened" : ""} src={"/icons/chevron_new_green.svg"} alt="chevron" />
            </div>
          </div>
          {isOpenedSelector && (
            <div className="product-selector__options">
              {products.map((product) => (
                <div
                  key={product.id}
                  onClick={() => {
                    setSelectedProduct(product);
                    setIsOpenedSelector(false);
                  }}
                  className="product-selector__option"
                >
                  <div
                    className={classnames("product-selector__option-title", {
                      selected: selectedProduct?.id === product.id,
                    })}
                  >
                    {product.title}
                  </div>
                  {selectedProduct?.id === product.id && (
                    <div className="product-selector__option-image">
                      <img src={"/icons/checked_new_green.svg"} alt="checked" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductSelector;
