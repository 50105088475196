import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  iconButton: {
    position: "absolute",
    top: "12px",
    right: "17px",
    cursor: "pointer",
  },
}));
