import { CONSUMER_LOAN_TYPES_OPTIONS } from "../../../../../shared/constants";
const pmt = require("formula-pmt");

export const calculateMonthlyPayment = ({
  amount,
  term,
  ratePerMonth,
  totalFees,
  type,
  ...rest
}: {
  amount: number;
  term: number;
  ratePerMonth: number;
  totalFees: number;
  type: CONSUMER_LOAN_TYPES_OPTIONS;
  rest?: any;
}) => {
  const calculatios: Record<number, number> = {
    [CONSUMER_LOAN_TYPES_OPTIONS.CREDIT_BALANCE]: pmt(ratePerMonth / 100, term, amount + totalFees),
    [CONSUMER_LOAN_TYPES_OPTIONS.DISBURSEMENT]: pmt(ratePerMonth / 100, term, amount),
  };

  const result: number = calculatios[type];
  return {
    ...rest,
    amount,
    term,
    ratePerMonth,
    totalFees,
    type,
    monthlyPayment: isNaN(result) ? 0 : Number(-result.toFixed(2)),
  };
};

export const calculateTotalInterest = ({
  totalBalance,
  amount,
  totalFees,
  ...rest
}: {
  totalBalance: number;
  amount: number;
  totalFees: number;
  rest?: any;
}) => {
  return { ...rest, totalBalance, amount, totalFees, totalInterest: +(totalBalance - amount).toFixed(2) };
};

export const calculateTotalFees = ({
  amount,
  negotiationFees,
  ...rest
}: {
  amount: number;
  negotiationFees: number;
  rest?: any;
}) => ({ ...rest, amount, negotiationFees, totalFees: +(amount * (negotiationFees / 100)).toFixed(2) });

export const calculateTotalAmount = ({
  monthlyPayment,
  term,
  totalFees,
  type = CONSUMER_LOAN_TYPES_OPTIONS.CREDIT_BALANCE,
  ...rest
}: {
  monthlyPayment: number;
  term: number;
  totalFees: number;
  type?: number;
  rest?: any;
}) => {
  const calculatios: Record<number, number> = {
    [CONSUMER_LOAN_TYPES_OPTIONS.CREDIT_BALANCE]: +(monthlyPayment * term).toFixed(2),
    [CONSUMER_LOAN_TYPES_OPTIONS.DISBURSEMENT]: +(monthlyPayment * term).toFixed(2),
  };
  return { ...rest, monthlyPayment, term, totalFees, type, totalBalance: calculatios[type] };
};

export const calcLoanAmount = function (amount?: number, totalFees?: number, type?: number): number {
  if (amount && totalFees && type) {
    const calculatios: Record<number, number> = {
      [CONSUMER_LOAN_TYPES_OPTIONS.CREDIT_BALANCE]: amount,
      [CONSUMER_LOAN_TYPES_OPTIONS.DISBURSEMENT]: amount - totalFees,
    };
    return calculatios[type] || 0;
  }
  return 0;
};
