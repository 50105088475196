import { AuthActionTypes } from "./constants";
import { IVerifiedUser } from "../interfaces";
import { createAsyncAction, createAction } from "typesafe-actions";

export const checkUserEmail = createAsyncAction(
  AuthActionTypes.CHECK_USER_EMAIL_REQUEST,
  AuthActionTypes.CHECK_USER_EMAIL_SUCCESS,
  AuthActionTypes.CHECK_USER_EMAIL_FAILURE,
)<any, undefined, { [key: string]: string }>();

export const checkUserQuote = createAsyncAction(
  AuthActionTypes.CHECK_USER_QUOTE_REQUEST,
  AuthActionTypes.CHECK_USER_QUOTE_SUCCESS,
  AuthActionTypes.CHECK_USER_QUOTE_FAILURE,
)<{ [key: string]: string | number }, IVerifiedUser, { [key: string]: string }>();

export const verifyUserEmail = createAsyncAction(
  AuthActionTypes.VERIFY_USER_EMAIL_REQUEST,
  AuthActionTypes.VERIFY_USER_EMAIL_SUCCESS,
  AuthActionTypes.VERIFY_USER_EMAIL_FAILURE,
)<any, undefined, { [key: string]: string }>();

export const resetUserState = createAction(AuthActionTypes.RESET_USER_STATE)();
