import { Form, Formik, FormikProps, FormikValues } from "formik";
import { checkUserQuote } from "../../../../Auth/store/actions";
import { validateEmail } from "../../../../../shared/utils/validators";
import Button from "../../../../../shared/components/styled/Button";
import React, { FC, useCallback, useMemo } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import ModalComponent from "../../../../../shared/components/common/ModalComponent/ModalComponent";
import configElement from "../../../../../config";

import "./index.scss";
import SaveAndContinueModalInput from "./SaveAndContinueModalInput";

//TODO: use input component from Qtn and create separate functions

const validationSchema = Yup.object<IFormValues>().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid email format").required("This field is required"),
  telephone_number: Yup.string().required("This field is required"),
});

interface IFormValues {
  first_name?: string;
  last_name?: string;
  email?: string;
  telephone_number?: string;
}

interface ISaveAndContinueQtnModalProps {
  isSaveModalOpened: boolean;
  handleCloseSaveModal: () => void;
  sectionProgress: { [key: string]: any } | undefined;
  handleSubmit: (values: IFormValues) => void;
  questionnareError: { [key: string]: any };
  handleChangeQuestion: any;
  cleareFormError: (field: string) => void;
  setSearch: (value: string) => void;
  updateFormikState: (values: any) => void;
  mainFormikBag?: any;
  updateSectionProgress?: (values: any) => void;
}

const SaveAndContinueQtnModal: FC<ISaveAndContinueQtnModalProps> = (props) => {
  const {
    isSaveModalOpened,
    handleCloseSaveModal,
    sectionProgress,
    handleSubmit,
    questionnareError,
    handleChangeQuestion,
    cleareFormError,
    setSearch,
    mainFormikBag,
    updateSectionProgress,
  } = props;
  const dispatch = useDispatch();

  const initialValues: IFormValues = useMemo(() => {
    return {
      first_name: sectionProgress?.first_name || "",
      last_name: sectionProgress?.last_name || "",
      email: sectionProgress?.email || "",
      telephone_number: sectionProgress?.telephone_number || "",
    };
  }, [sectionProgress]);

  const onLoginBtnClick = useCallback(() => {
    window.open(configElement.maritimeUrl, "_self");
  }, []);

  return (
    <div className="save-and-continue-wrapper">
      <ModalComponent
        title="Please enter your details to access your answers later."
        truncateTitleWidth
        show={isSaveModalOpened}
        handleCloseSaveModal={handleCloseSaveModal}
      >
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props: FormikProps<FormikValues>) => {
              const { touched, errors, handleChange, values, setFieldError, setFieldTouched } = props;

              if (questionnareError.email && questionnareError.email !== "This field is required" && !errors.email) {
                setFieldError("email", `User with this email already exists`);
                setFieldTouched("email", true, false);
              }

              const handleChangeField = (e: any) => {
                if (e.target.name === "email") {
                  dispatch(checkUserQuote.failure({}));
                  validateEmail(e.target.value) && setSearch(e.target.value);
                }

                handleChangeQuestion(e);
                handleChange(e);
                mainFormikBag && mainFormikBag.setFieldValue(e.target.name, e.target.value);
                updateSectionProgress && updateSectionProgress({ ...sectionProgress, [e.target.name]: e.target.value });
                cleareFormError("email");
              };

              return (
                <Form className="save-and-continue-form">
                  <SaveAndContinueModalInput
                    value={values.first_name}
                    handleChangeField={handleChangeField}
                    id="first_name"
                    label={
                      <span>
                        First Name <span className="required">*&nbsp;</span>
                      </span>
                    }
                    error={touched.first_name && errors.first_name}
                  />

                  <SaveAndContinueModalInput
                    value={values.last_name}
                    handleChangeField={handleChangeField}
                    id="last_name"
                    label={
                      <span>
                        Last Name<span className="required">*&nbsp;</span>
                      </span>
                    }
                    error={touched.last_name && errors.last_name}
                  />
                  <SaveAndContinueModalInput
                    value={values.telephone_number}
                    handleChangeField={handleChangeField}
                    id="telephone_number"
                    type="number"
                    placeholder="+1"
                    label={
                      <span>
                        Mobile Phone<span className="required">*&nbsp;</span>
                      </span>
                    }
                    error={touched.telephone_number && errors.telephone_number}
                  />

                  <SaveAndContinueModalInput
                    value={values.email}
                    handleChangeField={handleChangeField}
                    id="email"
                    label={
                      <span>
                        Email<span className="required">*&nbsp;</span>
                      </span>
                    }
                    error={touched.email && errors.email}
                  />

                  <Button
                    className="form-submit"
                    aria-label="Continue"
                    type="submit"
                    width="100%"
                    variant="primary-new-green"
                  >
                    Continue
                  </Button>
                </Form>
              );
            }}
          </Formik>
          <div className="modal-footer">
            <div className="modal-footer_msg">Already have an access?</div>
            <Button
              aria-label="Log in"
              type="button"
              width={80}
              onClick={onLoginBtnClick}
              variant="secondary-new-green"
            >
              Log In
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default SaveAndContinueQtnModal;
