import React, { useEffect, useState, useCallback, ReactNode, useMemo } from "react";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductQtn,
  getSelectedSection,
  getLoanQuoteResponse,
  getSectionProgress,
  getFollowUp,
  getFollowUpWithSection,
} from "../../store/selectors";
import { IProductQtn, IQtnSection, IQtnResponse } from "../../interfaces";
import classnames from "classnames";
import { setQtnSection, calculateQuote } from "../../store/actions";
import { push } from "connected-react-router";
import { cookie } from "../../../../shared/utils";
import { PRODUCT_TYPES, ROUTES } from "../../../../shared/constants";
import PieChart from "../../../../shared/components/common/PieChart/PieChard";
import { QtnSectionError } from "../QtnSectionError";
import { calculateProgress } from "../QtnSection/utils";
import { generatePath, useParams } from "react-router";

interface QtnSectionListProps {
  children?: ReactNode;
  canCalculate: boolean;
  advisorId?: number | null;
  setCalculateStatus: (value: boolean) => void;
  sectionErrors: string[];
  mobileMode?: boolean;
}

const QtnSectionList: React.FC<QtnSectionListProps> = ({
  children,
  canCalculate,
  advisorId,
  setCalculateStatus,
  sectionErrors,
  mobileMode,
}) => {
  const qtn: IProductQtn = useSelector(getProductQtn());
  const selectedSection: IQtnSection = useSelector(getSelectedSection());
  const qtnResponse: IQtnResponse = useSelector(getLoanQuoteResponse());
  const sectionProgress = useSelector(getSectionProgress());
  const followupIds = useSelector(getFollowUp());
  const followupIdsWithSection = useSelector(getFollowUpWithSection());
  const [init, setInit] = useState<boolean>(true);
  const [hideSelected, setHideSelected] = useState<boolean>(false);
  const { consumerLink } = useParams<{ consumerLink: string }>();

  const productType = useMemo(
    () =>
      qtn?.product?.product_type?.title
        ? qtn.product.product_type.title
        : consumerLink
        ? PRODUCT_TYPES.CONSUMER_LOAN
        : PRODUCT_TYPES.GENERAL_LOAN,
    [qtn, consumerLink],
  );

  const dispatch = useDispatch();

  const selectSection = (section: IQtnSection) => {
    if (selectedSection.id === section.id) {
      mobileMode ? setHideSelected((prev) => !prev) : dispatch(setQtnSection.success(section));
    } else {
      setHideSelected(false);
      dispatch(setQtnSection.success(section));
    }
  };

  const getCompletedSectionsIds = useCallback(() => {
    const sections: number[] = [];
    qtnResponse.quote_sections.forEach((s) => {
      s.display_sections.forEach((dSection) => {
        if (dSection.section_completed) {
          sections.push(dSection.display_section_id);
        }
      });
    });
    return sections;
  }, [qtnResponse]);

  const clear = useCallback(() => {
    consumerLink
      ? dispatch(push(`${generatePath(ROUTES.CONSUMER_LOAN_PROPOSAL, { consumerLink })}/${qtnResponse.id}`))
      : dispatch(push(`${ROUTES.PROPOSAL}/${qtnResponse.id}`));
    cookie.deleteCookie("sessionId");
  }, [dispatch, qtnResponse, consumerLink]);

  useEffect(() => {
    if (qtnResponse && qtn) {
      const completedSections = [...new Set(getCompletedSectionsIds())];

      const nextSection = qtn.sections.find((section) => !completedSections.includes(section.id));

      if (nextSection && init) {
        dispatch(setQtnSection.success(nextSection));
        setInit(false);
        return;
      }

      if (!nextSection) {
        let autoRejectOptions: number[] = [];
        let hasAutoRejectOpt = false;
        qtn.sections.forEach((section) => {
          section.questions.forEach((question) => {
            const opts = question.options.filter((opt) => opt.auto_reject).map((opt) => opt.id);
            if (opts.length) {
              autoRejectOptions = autoRejectOptions.concat(opts);
            }
          });
        });
        qtnResponse.quote_sections.forEach((section) => {
          section.responses.forEach((res) => {
            if (res.option_id && autoRejectOptions.includes(res.option_id)) hasAutoRejectOpt = true;
          });
        });
        if (hasAutoRejectOpt) {
          dispatch(
            calculateQuote.failure("At this stage we cannot make you an offer based on the information you provided"),
          );
        }
      }

      if (canCalculate) {
        dispatch(
          calculateQuote.request({
            id: qtnResponse.id,
            type: productType,
            ...(!!advisorId && { advisorId }),
            callback: clear,
          }),
        );
        setCalculateStatus(false);
      }
    }
  }, [
    qtnResponse,
    qtn,
    dispatch,
    getCompletedSectionsIds,
    clear,
    init,
    canCalculate,
    setCalculateStatus,
    advisorId,
    productType,
    followupIds,
    followupIdsWithSection,
  ]);

  const getIconForSelectedSection = useCallback(
    (id: number) => {
      const sectionFolowupIds = (followupIdsWithSection && followupIdsWithSection[String(id)]) || [];
      return (
        <PieChart
          sectionId={id}
          progress={calculateProgress(id, qtn, sectionProgress, sectionFolowupIds)}
          sectionErrors={sectionErrors}
        />
      );
    },
    [followupIdsWithSection, qtn, sectionProgress, sectionErrors],
  );

  const getSectionIcon = useCallback(
    (id: number, title: string) => {
      if (qtnResponse) {
        let activeSection = null;
        const section = qtnResponse.quote_sections.find((section) => {
          const displySection = section.display_sections.find((dSection) => dSection.display_section_id === id);
          activeSection = displySection;
          return displySection;
        });

        if (section && activeSection) {
          //@ts-ignore
          return activeSection.section_completed ? (
            <img className="icon" src="/icons/completed_green.svg" alt={title} />
          ) : (
            getIconForSelectedSection(id)
          );
        } else {
          return getIconForSelectedSection(id);
        }
      } else {
        return getIconForSelectedSection(id);
      }
    },
    [getIconForSelectedSection, qtnResponse],
  );

  return (
    qtn && (
      <div className="section-list">
        {qtn.sections.map((section: IQtnSection, idx: number) => {
          const { title, id } = section;
          return (
            <React.Fragment key={id}>
              <div
                onClick={() => selectSection(section)}
                className={classnames("section", { selected: !hideSelected && selectedSection.id === id })}
              >
                <div className="title">
                  <div className="section-label">
                    <div className="section-label-info">
                      {getSectionIcon(id, title)}
                      <span className="section-number">{idx + 1}.</span>

                      {title}
                    </div>
                    {mobileMode ? (
                      <img
                        className={classnames("hide-section-img", {
                          opened: !hideSelected && selectedSection.id === id,
                        })}
                        src="/icons/arrow_back_white.svg"
                        width="20px"
                        height="20px"
                        alt="back"
                      />
                    ) : null}
                  </div>
                  <QtnSectionError sectionId={id} sectionErrors={sectionErrors} />
                </div>
              </div>
              {selectedSection.id === id && (
                <div style={{ display: hideSelected && mobileMode ? "none" : "block" }}>{children}</div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    )
  );
};

export default QtnSectionList;
