export enum QtnActionTypes {
  FETCH_QTN_REQUEST = "@@APP/FETCH_QTN_REQUEST",
  FETCH_QTN_SUCCESS = "@@APP/FETCH_QTN_SUCCESS",
  FETCH_QTN_FAILURE = "@@APP/FETCH_QTN_FAILURE",

  SET_QTN_SECTION_REQUEST = "@@APP/SET_QTN_SECTION_REQUEST",
  SET_QTN_SECTION_SUCCESS = "@@APP/SET_QTN_SECTION_SUCCESS",
  SET_QTN_SECTION_FAILURE = "@@APP/SET_QTN_SECTION_FAILURE",
  SET_QTN_INITIAL_VALUES = "@@APP/SET_QTN_INITIAL_VALUES",
  SET_QTN_ORGANIZATION = "@@APP/SET_QTN_ORGANIZATION",

  FETCH_LOAN_QUOTE_REQUEST = "@@APP/FETCH_LOAN_QUOTE_REQUEST",
  FETCH_LOAN_QUOTE_SUCCESS = "@@APP/FETCH_LOAN_QUOTE_SUCCESS",
  FETCH_LOAN_QUOTE_FAILURE = "@@APP/FETCH_LOAN_QUOTE_FAILURE",

  CREATE_LOAN_QUOTE_REQUEST = "@@APP/CREATE_LOAN_QUOTE_REQUEST",
  CREATE_LOAN_QUOTE_SUCCESS = "@@APP/CREATE_LOAN_QUOTE_SUCCESS",
  CREATE_LOAN_QUOTE_FAILURE = "@@APP/CREATE_LOAN_QUOTE_FAILURE",

  UPDATE_LOAN_QUOTE_REQUEST = "@@APP/UPDATE_LOAN_QUOTE_REQUEST",
  UPDATE_LOAN_QUOTE_SUCCESS = "@@APP/UPDATE_LOAN_QUOTE_SUCCESS",
  UPDATE_LOAN_QUOTE_FAILURE = "@@APP/UPDATE_LOAN_QUOTE_FAILURE",

  PATCH_LOAN_QUOTE_REQUEST = "@@APP/PATCH_LOAN_QUOTE_REQUEST",
  PATCH_LOAN_QUOTE_SUCCESS = "@@APP/PATCH_LOAN_QUOTE_SUCCESS",
  PATCH_LOAN_QUOTE_FAILURE = "@@APP/PATCH_LOAN_QUOTE_FAILURE",

  UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_REQUEST = "@@APP/UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_REQUEST",
  UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_FAILURE = "@@APP/UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_FAILURE",
  UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_SUCCESS = "@@APP/UPDATE_LOAN_QUOTE_BEFORE_UNMOUNT_SUCCESS",

  UPDATE_DRAFT_LOAN_QUOTE_REQUEST = "@@APP/UPDATE_DRAFT_LOAN_QUOTE_REQUEST",
  UPDATE_DRAFT_LOAN_QUOTE_SUCCESS = "@@APP/UPDATE_DRAFT_LOAN_QUOTE_SUCCESS",
  UPDATE_DRAFT_LOAN_QUOTE_FAILURE = "@@APP/UPDATE_DRAFT_LOAN_QUOTE_FAILURE",

  CALCULATE_QUOTE_REQUEST = "@@APP/CALCULATE_QUOTE_REQUEST",
  CALCULATE_QUOTE_SUCCESS = "@@APP/CALCULATE_QUOTE_SUCCESS",
  CALCULATE_QUOTE_FAILURE = "@@APP/CALCULATE_QUOTE_FAILURE",

  SET_QUOTE_RATE = "@@APP/SET_QUOTE_RATE",

  CLEARE_QUOTE_AFTER_REJECT_REQUEST = "@@APP/CLEARE_QUOTE_AFTER_REJECT_REQUEST",
  CLEARE_QUOTE_AFTER_REJECT_SUCCESS = "@@APP/CLEARE_QUOTE_AFTER_REJECT_SUCCESS",
  CLEARE_QUOTE_AFTER_REJECT_FAILURE = "@@APP/CLEARE_QUOTE_AFTER_REJECT_FAILURE",

  UPDATE_QUOTE_STATUS_REQUEST = "@@APP/UPDATE_QUOTE_STATUS_REQUEST",
  UPDATE_QUOTE_STATUS_SUCCESS = "@@APP/UPDATE_QUOTE_STATUS_SUCCESS",
  UPDATE_QUOTE_STATUS_FAILURE = "@@APP/UPDATE_QUOTE_STATUS_FAILURE",

  UPDATE_SECTION_PROGRESS = "@@APP/UPDATE_SECTION_PROGRESS",
  UPDATE_FOLLOW_UP = "@@APP/UPDATE_FOLLOW_UP",
  UPDATE_FOLLOW_UP_WITH_SECTION = "@@APP/UPDATE_FOLLOW_UP_WITH_SECTION",
  UPDATE_TABLE_QESTIONS_STATE = "@@APP/UPDATE_TABLE_QESTIONS_STATE",
  SAVE_LAST_FROMIK_STATE = "@@APP/SAVE_LAST_FROMIK_STATE",

  FETCH_LOAN_QUOTE_BY_ID_REQUEST = "@@APP/FETCH_LOAN_QUOTE_BY_ID_REQUEST",
  FETCH_LOAN_QUOTE_BY_ID_SUCCESS = "@@APP/FETCH_LOAN_QUOTE_BY_ID_SUCCESS",
  FETCH_LOAN_QUOTE_BY_ID_FAILURE = "@@APP/FETCH_LOAN_QUOTE_BY_ID_FAILURE",

  REMOVE_UPLOADED_FILE = "@@APP/REMOVE_UPLOADED_FILE",
}
