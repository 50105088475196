import { createStore, applyMiddleware, compose, Middleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import freeze from "redux-freeze";
import createSagaMiddleware from "redux-saga";
import rootSagas from "./saga";
import rootReducer from "./reducer";
import { routerMiddleware } from "connected-react-router";
import history from "../shared/history";

const sagaMiddleware = createSagaMiddleware();
let middleware: Array<Middleware>;
let composer: Function;

const common = [sagaMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  middleware = [freeze, ...common];
  composer = composeWithDevTools({ trace: true, traceLimit: 25 });
} else {
  middleware = [...common];
  composer = compose;
}
export default function configureStore() {
  const store = createStore(rootReducer(history), undefined, composer(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSagas);

  return { store };
}
