import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";

import ProductCardDescription from "../ProductCardDescription/ProductCardDescription";
import Button from "../../../../../shared/components/styled/Button";
import VerifyEmail from "../VerifyEmail/VerifyEmail";
import { IProduct } from "../../../interfaces";
import { DEVICE_WIDTH } from "../../../../../shared/constants";

import "./styles.scss";

interface IProductCardProps {
  product: IProduct;
  isSingleProduct: boolean;
  productClickHandler: any;
}

const ProductCard: React.FunctionComponent<IProductCardProps> = ({ product, isSingleProduct, productClickHandler }) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateWindowDimensions = useCallback(() => {
    setIsMobile(window.innerWidth <= DEVICE_WIDTH.SMALL_TABLET);
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [updateWindowDimensions]);

  return (
    <div key={product.id} className={classnames("product-card", { single: isSingleProduct })} onClick={() => {}}>
      <div
        className={classnames("product-card-header", { opened: isOpened })}
        onClick={() => {
          setIsOpened((prevState) => !prevState);
        }}
      >
        <div className="product-card-header__image">
          <img src={product.image_url} alt="product" />
        </div>
        <div className="product-card-header__title">
          <div className="title">{product.title}</div>
        </div>
        <div className="product-card-header__icon">
          <img className={classnames({ opened: isOpened })} src={"/icons/chevron_new_green.svg"} alt="chevron" />
        </div>
      </div>
      {!isOpened && isMobile ? null : (
        <>
          <ProductCardDescription description={product.description} />
          <div className="product-card-footer">
            <Button
              className="product-card-footer__main-button"
              aria-label="Get Pre-Approved"
              width="100%"
              variant="primary-new-green"
              onClick={() => productClickHandler(product)}
            >
              Get Pre-Approved
            </Button>
            <VerifyEmail product={product} />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductCard;
