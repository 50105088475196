import { createSelector, defaultMemoize } from "reselect";
import { IProductState } from "../interfaces";

const selectProducts = (state: any): IProductState => state.products;

export const getAllProducts = (filter?: string) =>
  createSelector(selectProducts, (state) =>
    filter
      ? state.products.filter((product) => (product.integration_name ? product.integration_name === filter : false))
      : state.products,
  );
export const getSelectedProduct = () => createSelector(selectProducts, (state) => state.selectedProduct);
export const getProductSelectProduct = () => createSelector(selectProducts, (state) => state.productSelectProduct);
export const getSelectedProductType = () =>
  createSelector(selectProducts, (state) => {
    return state.selectedProduct?.type_id || state.selectedProduct?.product_type?.id;
  });
export const getAdvisor = () => createSelector(selectProducts, (state) => state.advisor_id);
export const getOrganizationsOptions = () =>
  createSelector(selectProducts, (state) => {
    return {
      organizations: state.organizations,
      employers: state.organizations,
    };
  });

export const getOrganizationById = createSelector([selectProducts], ({ organizations }) =>
  defaultMemoize((orgId: number) => {
    return organizations.length ? organizations.find((org) => org.id === orgId) : undefined;
  }),
);

export const getUserOrganization = () => createSelector(selectProducts, (state) => state.userOrganization);
