import axios from "axios";
import { API } from "../constants";

export const validateEmail = (email: string) => {
  /* eslint-disable */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const FILE_MAX_SIZE = 52428800;
export const validateFileSize = (size: number) => size > FILE_MAX_SIZE;

export const removeAllWithoutlLast = (string: string, token: string) => {
  const parts = string.split(token);
  return parts.slice(0, -1).join("") + token + parts.slice(-1);
};

export const validateFileType = (file: string) => {
  /* eslint-disable */
  const re = /^([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png|.heic|.pdf|.doc|.docx|.page|.pages)$/;
  return re.test(String(file).toLowerCase());
};

export const сreateValideFileName = (fileName: string) => {
  /* eslint-disable */
  const re = /[`.~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi;
  const validFileName =
    fileName === undefined ? Math.random().toString(36).substr(2, 9) : fileName.replace(/\s/g, "").replace(re, "");
  return validFileName;
};

export const removeSeparator = (value: any) => (value ? value.replace(/(\d+),(?=\d{3}(\D|$))/g, "$1") : value);
export const removeLeadingZero = (value: string) => parseInt(value, 10).toString();

export const formikAsyncValidator = async (
  debounceSearch: { [key: string]: string | number | null },
  resolve: any,
  reject: any,
) => {
  try {
    const URL = API.CHECK_USER_QUOTE(debounceSearch);
    const { data } = await axios.get(URL);
    return resolve(data && data.user_exist && data.quotes.find((q: any) => q.status === 15));
  } catch (error) {
    return reject(!!error);
  }
};
