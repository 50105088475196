import { CONSUMER_LOAN_TYPES_OPTIONS } from "../../../shared/constants";

export const FORM = {
  RATE_OPTIONS: {
    SCHEME: {
      price: 0,
      amount: 10000,
      downpayment: 0,
      term: 1,
    },
  },
  CONSUMER_OPTIONS: {
    SCHEME: {
      amount: 0,
      term: 0,
      monthly_payment: 0,
      total_interest: 0,
      total_fees: 0,
      total_amount: 0,
      option_type: CONSUMER_LOAN_TYPES_OPTIONS.DISBURSEMENT,
      organization: null,
    },
  },
};

export const CREDIT = {
  AMOUNT: "amount",
  EI: "expected_installment",
};
