import Modal from "../../../../../shared/components/common/Modal/Modal";
import Button from "../../../../../shared/components/styled/Button";
import configElement from "../../../../../config";
import React, { FC } from "react";

interface IContinueWorkingWithMarketingPageModal {
  showModal: boolean;
  onClose: () => void;
}

const ContinueWorkingWithMarketingPageModal: FC<IContinueWorkingWithMarketingPageModal> = (props) => {
  const { showModal, onClose } = props;
  return (
    <Modal
      show={showModal}
      img={true}
      title="Sorry!"
      msg={`We can see that the user with this email already exists. Please go to the login page to <br/> log into your account to continue your work.`}
    >
      <Button
        type="button"
        variant="outlined-primary"
        width="340"
        aria-label="Go to the login page"
        onClick={() => window.open(configElement.maritimeUrl, "_self")}
      >
        Go to the login page
      </Button>
      <br />
      <Button aria-label="Continue working with marketing page" type="button" width="340" onClick={onClose}>
        Continue working with marketing page
      </Button>
    </Modal>
  );
};

export default ContinueWorkingWithMarketingPageModal;
